import { Edge, MarkerType, Node } from "reactflow";
import { Tables } from "../../../types/database.ts";
import { getRandomTailwindColor } from "../../../utils/color.ts";

const momentColorMap = new Map<string, string>();

export const generateNodes = (moments: Tables<"blueprint_moments">[]): Node[] => {
  return moments.map((moment, index) => {
    let borderColor = "#000000";

    if (moment.parent_moment_id) {
      if (momentColorMap.has(moment.parent_moment_id)) {
        borderColor = momentColorMap.get(moment.parent_moment_id)!;
      } else {
        borderColor = getRandomTailwindColor(moment.parent_moment_id);
        momentColorMap.set(moment.parent_moment_id, borderColor);
      }
    } else {
      if (!momentColorMap.has(moment.id)) {
        momentColorMap.set(moment.id, getRandomTailwindColor(moment.id));
      }
      borderColor = momentColorMap.get(moment.id)!;
    }

    return {
      id: moment.id,
      type: "custom",
      data: {
        label: moment.moment_name,
        moment_type: moment.moment_type,
        moment: moment,
        borderColor: borderColor,
      },
      position: { x: 100, y: index * 100 },
      style: { border: "none" },
    };
  });
};

export const generateEdges = (
  transitions: Tables<"blueprint_moment_transitions">[],
  moments: Tables<"blueprint_moments">[],
): Edge[] => {
  if (transitions.length === 0 && moments.length >= 2) {
    // Add a fake transition between the first two moments
    transitions = [
      {
        blueprint_story_id: moments[0].blueprint_story_id,
        id: "fake-transition",
        current_moment_id: moments[0].id,
        next_moment_id: moments[1].id,
        condition: "",
        created_at: null,
        updated_at: null,
      },
    ];
  }

  return transitions.map((transition) => {
    const sourceMoment = moments.find((moment) => moment.id === transition.current_moment_id);
    const targetMoment = moments.find((moment) => moment.id === transition.next_moment_id);

    let strokeColor = "#000000"; // Default black stroke

    if (
      sourceMoment &&
      targetMoment &&
      targetMoment.parent_moment_id &&
      targetMoment.parent_moment_id === sourceMoment.parent_moment_id
    ) {
      strokeColor = momentColorMap.get(sourceMoment.parent_moment_id) || strokeColor;
    } else if (sourceMoment && targetMoment && targetMoment.parent_moment_id === sourceMoment.id) {
      strokeColor = momentColorMap.get(sourceMoment.id) || strokeColor;
    }

    return {
      id: transition.id,
      type: "smoothstep",
      source: transition.current_moment_id,
      target: transition.next_moment_id,
      animated: false,
      label:
        transition.condition.length > 50
          ? transition.condition.substring(0, 50) + "..."
          : transition.condition,
      style: { stroke: strokeColor },
      labelStyle: { fontSize: "12px" },
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 10,
        height: 10,
        color: strokeColor,
      },
    };
  });
};
