import { BlueprintImageModelSettings } from "@/components/admin/generateImagesModal/hooks/useImageGenModelsDatabase.tsx";

export type ParsedDefaultValue = {
  value: string | number | boolean;
  min?: number;
  max?: number;
  step?: number;
  unit?: string;
};
export const getDefaultValueForType = (
  setting: BlueprintImageModelSettings,
): ParsedDefaultValue => {
  try {
    // Handle string default_value (from DB)
    if (typeof setting.default_value === "string") {
      try {
        const parsed = JSON.parse(setting.default_value);
        // Validate parsed value has the expected shape
        if (typeof parsed === "object" && parsed !== null && "value" in parsed) {
          return {
            value: parsed.value,
            min: typeof parsed.min === "number" ? parsed.min : undefined,
            max: typeof parsed.max === "number" ? parsed.max : undefined,
            step: typeof parsed.step === "number" ? parsed.step : undefined,
            unit: typeof parsed.unit === "string" ? parsed.unit : undefined,
          };
        }
      } catch {
        // JSON parsing failed, fall through to defaults
      }
    }

    // If the default_value is already an object
    if (typeof setting.default_value === "object" && setting.default_value !== null) {
      const objValue = setting.default_value as Record<string, unknown>;
      if ("value" in objValue) {
        return {
          value: objValue.value as string | number | boolean,
          min: typeof objValue.min === "number" ? objValue.min : undefined,
          max: typeof objValue.max === "number" ? objValue.max : undefined,
          step: typeof objValue.step === "number" ? objValue.step : undefined,
          unit: typeof objValue.unit === "string" ? objValue.unit : undefined,
        };
      }
    }

    // Provide type-specific defaults
    switch (setting.setting_type) {
      case "number":
        return {
          value: 0,
          min: 0,
          max: 100,
          step: 1,
        };
      case "select":
        return {
          value: "",
        };
      case "switch":
        return {
          value: false,
        };
      default:
        return {
          value: "",
        };
    }
  } catch {
    // Final fallback defaults
    switch (setting.setting_type) {
      case "number":
        return {
          value: 0,
          min: 0,
          max: 100,
          step: 1,
        };
      case "select":
        return {
          value: "",
        };
      case "switch":
        return {
          value: false,
        };
      default:
        return {
          value: "",
        };
    }
  }
};
