import React from "react";
import { LoadingSpinner } from "./LoadingSpinner.tsx";
import { capitalize } from "lodash";

interface AnimatedStatusProps {
  status: string | null;
}

const AnimatedStatus: React.FC<AnimatedStatusProps> = ({ status }) => {
  if (!status) return null;

  return (
    <div className="flex items-center gap-2 text-sm text-gray-500">
      <LoadingSpinner />
      <span className="mt-2">{status ? capitalize(status) : status}</span>
    </div>
  );
};

export default AnimatedStatus;
