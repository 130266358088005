import React, { useEffect, useMemo } from "react";
import DynamicForm from "../DynamicForm";
import { ArrowPathIcon } from "@heroicons/react/16/solid";
import { SpokableButton } from "../SpokableButton.tsx";
import { LogLine } from "@/types/logline_prompts_generated_types.ts";
import { LoadingSpinner } from "@/components/admin/LoadingSpinner.tsx";
import { OnePager } from "@/types/one_pager_prompts_generated_types.ts";

export interface GenerateOnePagerProps {
  onePager: OnePager | null;
  status: string;
  setOnePager: React.Dispatch<React.SetStateAction<OnePager | null>>;
  generateOnePager: (payload: any) => void;
  logline: LogLine | null;
  audience: string;
  title: string;
}

const GenerateOnePager: React.FC<GenerateOnePagerProps> = ({
  onePager,
  status,
  setOnePager,
  generateOnePager,
  logline,
  audience,
  title,
}) => {
  const memoizedOnePager = useMemo(() => onePager, [onePager]);

  useEffect(() => {
    if (logline && onePager === null && status === "") {
      generateOnePager({ logline: logline?.log_line || "", audience: audience });
    }
  }, [onePager, logline, generateOnePager, setOnePager]);

  const handleRegenerate = () => {
    if (logline) {
      setOnePager(null);
    }
  };

  return (
    <div>
      <div>
        <div className="flex items-center">
          <h1 className="text-2xl font-bold mb-2">{title}</h1>
          <SpokableButton
            isIconButton={true}
            color="light"
            className="ml-2"
            onClick={handleRegenerate}
          >
            <ArrowPathIcon />
          </SpokableButton>
        </div>
        <span className="text-sm font-sans"> {status}</span>
      </div>
      {status !== "" && <LoadingSpinner />}
      {memoizedOnePager && (
        <div className="mt-4 max-h-[calc(90vh-300px)] overflow-y-auto justify-items-start">
          <DynamicForm
            data={memoizedOnePager}
            setData={setOnePager}
            filterFields={["aesthetic_style", "image_generation_prompt"]}
            minNumberOfRows={1}
          />
        </div>
      )}
    </div>
  );
};

export default GenerateOnePager;
