import { useEffect, useState } from "react";
import { supabase } from "@/vendor/supabaseClient";
import { Tables } from "@/types/database";
import { getDefaultValueForType } from "@/components/admin/generateImagesModal/ImageModelCRUD/imageModelUtil.ts";
import { DEFAULT_IMAGE_GEN_KEY, IMAGE_MODEL_CATEGORY_IMAGE_GEN } from "@/constants/constant.ts";

export type BlueprintImageModels = Tables<"blueprint_image_models">;
export type BlueprintImageModelSettings = Tables<"blueprint_image_model_settings">;
export type BlueprintImageModelSettingOptions = Tables<"blueprint_image_model_setting_options">;

export function useImageGenModelsDatabase(imageModelId: string | null) {
  const [models, setModels] = useState<BlueprintImageModels[]>([]);
  const [settings, setSettings] = useState<BlueprintImageModelSettings[]>([]);
  const [options, setOptions] = useState<BlueprintImageModelSettingOptions[]>([]);
  const [selectedModel, setSelectedModel] = useState<BlueprintImageModels | null>(null);
  const [settingValues, setSettingValues] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        const [modelsData, settingsData, optionsData] = await Promise.all([
          supabase
            .from("blueprint_image_models")
            .select("*")
            .eq("category", IMAGE_MODEL_CATEGORY_IMAGE_GEN),
          supabase.from("blueprint_image_model_settings").select("*"),
          supabase.from("blueprint_image_model_setting_options").select("*"),
        ]);

        if (modelsData.error) throw new Error(modelsData.error.message);
        if (settingsData.error) throw new Error(settingsData.error.message);
        if (optionsData.error) throw new Error(optionsData.error.message);

        const modelsList = modelsData.data || [];
        setModels(modelsList);
        setSettings(settingsData.data || []);
        setOptions(optionsData.data || []);

        // Set initial selected model
        if (modelsList.length > 0) {
          let initialModel = modelsList.find((m) => m.id === imageModelId);
          if (!initialModel)
            initialModel = modelsList.find((m) => m.model_id === DEFAULT_IMAGE_GEN_KEY);
          if (!initialModel) initialModel = modelsList[0];
          setSelectedModel(initialModel);
        }
      } catch (error) {
        setError(error instanceof Error ? error.message : "An error occurred");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [imageModelId]);

  // Initialize settings when model changes
  useEffect(() => {
    if (selectedModel) {
      const modelSettings = getSettingsForModel(selectedModel.id);
      const defaultValues = modelSettings.reduce(
        (acc, setting) => ({
          ...acc,
          [setting.setting_key]: getDefaultValueForType(setting).value,
        }),
        {},
      );
      setSettingValues(defaultValues);
    }
  }, [selectedModel]);

  const getSettingsForModel = (modelId: string) =>
    settings.filter((s) => s.image_model_id === modelId);

  const getOptionsForSetting = (settingId: string) =>
    options.filter((o) => o.setting_id === settingId);

  const updateSetting = (key: string, value: any) => {
    setSettingValues((prev) => ({ ...prev, [key]: value }));
  };

  const getCurrentValues = () => settingValues;

  return {
    models,
    loading,
    error,
    selectedModel,
    setSelectedModel,
    getSettingsForModel,
    getOptionsForSetting,
    updateSetting,
    getCurrentValues,
  };
}
