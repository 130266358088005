import React from "react";
import { Card, CardHeader, CardText, CardVideo } from "../Card";
import { DTOVideoResult } from "@/types/fastApiMediaGenerationTypes.ts";

interface VariationGalleryProps {
  videoResults: DTOVideoResult[];
}

export const EmotionVideoGallery: React.FC<VariationGalleryProps> = ({ videoResults }) => {
  return (
    <ul className={`grid grid-cols-7 gap-4`}>
      {videoResults.map((video, index) => (
        <React.Fragment key={index}>
          <li key={`${index}`}>
            <Card className="aspect-square">
              <CardHeader className="h-full">
                <div className="relative w-full h-full ">
                  <CardVideo src={video.video_url} className="absolute inset-0 object-cover" />
                </div>
                <CardText>{video.key}</CardText>
              </CardHeader>
            </Card>
          </li>
        </React.Fragment>
      ))}
    </ul>
  );
};
