import { DEV_FAST_API_WS_PROMPT_MANAGEMENT } from "@/vendor/config";
import { createWebSocketHook } from "@/hooks/useBaseWebSocket.ts";

export enum PromptManagementMessageType {
  RUN_EVAL = "RUN_EVAL",
  EVAL_ERROR = "EVAL_ERROR",
}

const wsUrl =
  import.meta.env.VITE_FAST_API_WS_PROMPT_MANAGEMENT || DEV_FAST_API_WS_PROMPT_MANAGEMENT;
const usePromptManagementWebSocket = createWebSocketHook(wsUrl);

export interface PromptManagementWebSocketHook {
  send: <T>(messageType: PromptManagementMessageType, messageObject: T) => void;
  onReceive: (messageType: PromptManagementMessageType, handler: (message: any) => void) => void;
  isConnected: boolean;
}

export default usePromptManagementWebSocket as () => PromptManagementWebSocketHook;
