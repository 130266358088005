import { useCallback, useEffect, useRef, useState } from "react";
import { supabase } from "@/vendor/supabaseClient";

export interface WebSocketMessage<T = any> {
  messageType: string;
  messageObject: T;
  error?: string;
}

interface WebSocketHook {
  send: <T>(messageType: string, messageObject: T) => void;
  onReceive: (messageType: string, handler: (message: any) => void) => void;
  isConnected: boolean;
}

export const createWebSocketHook = (wsUrl: string) => {
  return (): WebSocketHook => {
    const ws = useRef<WebSocket | null>(null);
    const messageHandlers = useRef<Map<string, ((message: any) => void)[]>>(new Map());
    const reconnectTimeout = useRef<NodeJS.Timeout>();
    const reconnectAttempts = useRef<number>(0);
    const MAX_RECONNECT_ATTEMPTS = 5;
    const INITIAL_RECONNECT_DELAY = 1000;

    const [isConnected, setIsConnected] = useState<boolean>(false);

    const getReconnectDelay = useCallback(() => {
      return Math.min(INITIAL_RECONNECT_DELAY * Math.pow(2, reconnectAttempts.current), 10000);
    }, []);

    const cleanup = useCallback(() => {
      if (ws.current) {
        ws.current.onclose = null;
        ws.current.onerror = null;
        ws.current.onmessage = null;
        ws.current.onopen = null;
        ws.current.close();
        ws.current = null;
      }
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
    }, []);

    const connect = useCallback(async () => {
      try {
        cleanup();

        const { data } = await supabase.auth.getSession();
        if (!data?.session) {
          console.error("No session available");
          return;
        }

        const jwtToken = data.session.access_token;
        const URL = `${wsUrl}?token=${encodeURIComponent(jwtToken)}`;

        ws.current = new WebSocket(URL);

        ws.current.onopen = () => {
          console.log("WebSocket connected");
          setIsConnected(true);
          reconnectAttempts.current = 0;
        };

        ws.current.onclose = (event) => {
          console.log(`WebSocket disconnected with code: ${event.code}`);
          setIsConnected(false);

          if (event.code !== 1000 && reconnectAttempts.current < MAX_RECONNECT_ATTEMPTS) {
            reconnectAttempts.current++;
            const delay = getReconnectDelay();
            console.log(
              `Attempting reconnect in ${delay}ms (attempt ${reconnectAttempts.current})`,
            );
            reconnectTimeout.current = setTimeout(connect, delay);
          }
        };

        ws.current.onmessage = (event) => {
          try {
            const rawData = event.data;
            const parsedData = typeof rawData === "string" ? JSON.parse(rawData) : rawData;

            if (!parsedData || typeof parsedData !== "object") {
              throw new Error("Invalid message format");
            }

            const message = parsedData as WebSocketMessage;
            if (message.error) {
              console.error("Received error from server:", message.error);
            }

            const handlers = messageHandlers.current.get(message.messageType);
            if (handlers) {
              handlers.forEach((handler) => handler(message));
            }
          } catch (error) {
            console.error("Error parsing WebSocket message:", error);
          }
        };

        ws.current.onerror = (error) => {
          console.error("WebSocket error:", error);
        };
      } catch (error) {
        console.error("Error connecting to WebSocket:", error);
        if (reconnectAttempts.current < MAX_RECONNECT_ATTEMPTS) {
          const delay = getReconnectDelay();
          reconnectTimeout.current = setTimeout(connect, delay);
        }
      }
    }, [cleanup, getReconnectDelay, wsUrl]);

    const send = useCallback(
      <T>(messageType: string, messageObject: T) => {
        if (!ws.current) {
          console.log("No WebSocket connection available - attempting to connect");
          connect();
          return;
        }

        if (ws.current.readyState !== WebSocket.OPEN) {
          console.log(`WebSocket is in state: ${ws.current.readyState} - attempting to reconnect`);

          // If the socket is closing or closed, try to reconnect
          if (
            ws.current.readyState === WebSocket.CLOSING ||
            ws.current.readyState === WebSocket.CLOSED
          ) {
            setIsConnected(false);
            connect();
          }

          // If connecting, we could optionally queue the message
          // For now, we'll just log and return
          if (ws.current.readyState === WebSocket.CONNECTING) {
            console.log("WebSocket is still connecting - message not sent");
          }
          return;
        }

        const message: WebSocketMessage<T> = {
          messageType,
          messageObject,
        };

        try {
          ws.current.send(JSON.stringify(message));
        } catch (error) {
          console.error("Error sending message:", error);
          // If send fails, attempt to reconnect
          setIsConnected(false);
          connect();
        }
      },
      [connect],
    );

    const onReceive = useCallback((messageType: string, handler: (message: any) => void) => {
      if (!messageHandlers.current.has(messageType)) {
        messageHandlers.current.set(messageType, []);
      }
      const handlers = messageHandlers.current.get(messageType);
      if (handlers) {
        handlers.push(handler);
      }

      return () => {
        const handlers = messageHandlers.current.get(messageType);
        if (handlers) {
          const index = handlers.indexOf(handler);
          if (index > -1) {
            handlers.splice(index, 1);
          }
        }
      };
    }, []);

    useEffect(() => {
      connect();
      return cleanup;
    }, [connect, cleanup]);

    return {
      send,
      onReceive,
      isConnected,
    };
  };
};
