import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/catalyst/table.tsx";
import { LOCALE_ENGLISH, TAB } from "../../constants/constant.ts";
import { formatDateString } from "../../utils/date.ts";
import { Card, CardContent, CardH1 } from "../../components/admin/Card.tsx";
import { capitalize } from "../../utils/stringUtil.ts";
import { Tables } from "../../types/database.ts";
import DeleteMomentButton from "../../components/admin/CRUD/DeleteMomentButton.tsx";
import MomentDetail from "./MomentDetail";
import Breadcrumb from "../../components/admin/Breadcrumb.tsx";
import { supabase } from "../../vendor/supabaseClient.ts";

interface MomentListProps {
  story: Tables<"blueprint_stories">;
  moments: Tables<"blueprint_moments">[];
  onMomentUpdateOrDeleted?: () => void;
  filterBySceneId?: string | null;
}

const MomentList: React.FC<MomentListProps> = ({
  story,
  moments,
  onMomentUpdateOrDeleted,
  filterBySceneId,
}) => {
  const [selectedMoment, setSelectedMoment] = useState<Tables<"ordered_moments_view"> | null>(null);
  const [filteredPerSceneMoments, setFilteredPerSceneMoments] = useState<
    Tables<"blueprint_moments">[] | null
  >(null);
  const [orderedFilteredMoments, setOrderedFilteredMoments] = useState<
    Tables<"ordered_moments_view">[] | null
  >(null);

  useEffect(() => {
    filterMomentsByScene();
  }, [moments, filterBySceneId]);

  useEffect(() => {
    retrieveOrderedMoments();
  }, [filteredPerSceneMoments]);

  async function retrieveOrderedMoments() {
    if (!filteredPerSceneMoments) return;

    const { data, error } = await supabase
      .from("ordered_moments_view")
      .select("*")
      .in(
        "id",
        filteredPerSceneMoments.map((moment) => moment.id),
      );

    if (error) {
      console.error("Error fetching moments:", error);
      setOrderedFilteredMoments(null);
    } else {
      setOrderedFilteredMoments(data);
    }
  }

  const filterMomentsByScene = () => {
    const filteredMoments = filterBySceneId
      ? moments.filter((moment) => moment.scene_id === filterBySceneId)
      : moments;
    setFilteredPerSceneMoments(filteredMoments);
  };

  const handleMomentClick = (moment: Tables<"ordered_moments_view">) => {
    setSelectedMoment(moment);
  };

  const handleBackToList = () => {
    setSelectedMoment(null);
    if (onMomentUpdateOrDeleted) {
      onMomentUpdateOrDeleted();
    }
  };

  const breadcrumbItems = [
    { label: "Moments", onClick: handleBackToList },
    ...(selectedMoment
      ? [
          {
            label: selectedMoment.moment_name,
            onClick: () => {},
          },
        ]
      : []),
  ];

  if (!story) {
    return <div>"No story"</div>;
  }

  if (selectedMoment) {
    return (
      <Card isFullWidth={true}>
        <CardContent>
          <Breadcrumb items={breadcrumbItems} />
          <MomentDetail momentId={selectedMoment.id} onMomentUpdate={() => handleBackToList()} />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card isFullWidth={true}>
      <CardContent>
        <Breadcrumb items={breadcrumbItems} />
        <CardH1 label="Moments" id="moments" />
        <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
          <TableHead>
            <TableRow>
              <TableHeader>Name</TableHeader>
              <TableHeader>Updated at</TableHeader>
              <TableHeader>Type</TableHeader>
              <TableHeader></TableHeader>
              <TableHeader></TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderedFilteredMoments &&
              orderedFilteredMoments.map((moment) => (
                <TableRow
                  key={moment.id}
                  onClick={() => handleMomentClick(moment)}
                  className="cursor-pointer hover:bg-zinc-950/5"
                >
                  <TableCell>
                    {moment.parent_moment_id != null
                      ? TAB + moment.moment_name
                      : moment.moment_name}
                  </TableCell>
                  <TableCell>
                    {moment.updated_at ? formatDateString(moment.updated_at, LOCALE_ENGLISH) : ""}
                  </TableCell>
                  <TableCell>{capitalize(moment.moment_type)}</TableCell>
                  <TableCell onClick={(e) => e.stopPropagation()}>
                    <DeleteMomentButton
                      momentId={moment.id}
                      moments={moments}
                      onMomentDeleted={onMomentUpdateOrDeleted}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default MomentList;
