import React, { useState } from "react";
import { LoadingSpinner } from "../LoadingSpinner";
import AutoResizeTextArea from "../AutoResizeTextArea";
import { SyllabusInput, SyllabusOutput } from "@/types/learning_concept_prompts_generated_types.ts";
import { SpokableButton } from "@/components/admin/SpokableButton.tsx";
import SyllabusEditor from "@/components/admin/syllabusEditor/SyllabusEditor.tsx";

export interface GenerateLogLineProps {
  learningConcept: string;
  status: string;
  setSyllabus: React.Dispatch<React.SetStateAction<SyllabusOutput | null>>;
  generateSyllabus: (payload: any) => void;
  syllabus: SyllabusOutput | null;
  title: string;
}

const GenerateLogLine: React.FC<GenerateLogLineProps> = ({
  learningConcept,
  status,
  setSyllabus,
  syllabus,
  generateSyllabus,

  title,
}) => {
  const [finalLearningConcept, setFinalLearningConcept] = useState<string>(learningConcept);
  const handleGenerateLearningConcept = () => {
    const syllabusInput: SyllabusInput = {
      learning_concept: learningConcept,
    };
    generateSyllabus(syllabusInput);
  };

  return (
    <div>
      <div>
        <h1 className="text-2xl font-bold mb-2">{title}</h1>
        <span className="text-sm font-sans"> {status}</span>
      </div>

      <div className="space-y-4 mb-8">
        <AutoResizeTextArea
          value={finalLearningConcept || ""}
          onChange={(value) => setFinalLearningConcept(value)}
          minNumberOfRows={1}
        />

        <div>
          <SpokableButton onClick={handleGenerateLearningConcept} className="w-32">
            Generate
          </SpokableButton>
          {status !== "" && <LoadingSpinner />}
        </div>
      </div>
      {syllabus && <SyllabusEditor syllabus={syllabus} setSyllabus={setSyllabus} />}
    </div>
  );
};

export default GenerateLogLine;
