import React, { useEffect, useState } from "react";
import { CatalogueLayout } from "../components/catalogue/CatalogueLayout.tsx";
import { Carousel } from "../components/catalogue/Carousel.tsx";
import Row from "../components/catalogue/Row.tsx";
import StoryModal from "../components/catalogue/StoryModal.tsx";
import Footer from "../components/catalogue/Footer.tsx";
import { useFetchStoriesWithRatingAndList } from "../hooks/database/useStory.ts";
import { Tables } from "../types/database.ts";

const Catalogue: React.FC = () => {
  const { stories, isLoading } = useFetchStoriesWithRatingAndList();
  const [userStartedStories, setUserStartedStories] = useState<
    Tables<"blueprint_stories_with_ratings_view">[]
  >([]);

  useEffect(() => {
    if (stories && stories.length > 0) {
      const startedStories = stories.filter((story) => story.user_started);
      setUserStartedStories(startedStories);
    }
  }, [stories]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <CatalogueLayout>
      <Carousel story={stories[0]} />
      <section className="md:space-y-16 text-white mt-16">
        {userStartedStories && (
          <Row title="Continue" stories={userStartedStories} showProgress={true} />
        )}
        <Row title="Trending Now" stories={stories} />
      </section>
      <StoryModal />
      <Footer />
    </CatalogueLayout>
  );
};

export default Catalogue;
