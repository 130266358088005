import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  currentCharacterEmotionState,
  isGameLostState,
  isMomentCompleteState,
  isMomentStartedState,
  momentElementsAtom,
  objectiveHasJustBeenCompletedState,
} from "../states/momentState.tsx";
import { GameEngineMessageType } from "../constants/GameEngineMessageType.ts";
import { useWebSocket } from "@/gameEngineWebSocket/WebSocketProvider.tsx";
import AudioRecorder from "../components/AudioRecorder.tsx";
import { audioDataQueueState, isThinkingState } from "../states/audioDataState.ts";
import AnimatedText from "../components/AnimatedText.tsx";
import { useFetchMomentPrompt } from "../hooks/useFetchMomentPrompt.ts";
import ErrorModal from "../components/ErrorModal.tsx";
import { formatDateString } from "../utils/date.ts";
import { useNavigateWithQueryParams } from "../hooks/useNavigateWithQueryParams.ts";
import { MOMENT_TYPE_WON_GAME } from "../constants/constant.ts";
import { formatRGBAColor } from "../utils/color.ts";
import { useDebouncedValue } from "../hooks/useDebounceValue.ts";
import useBackgroundMusicManager from "../hooks/useBackgroundMusicManager.ts";
import { capitalize } from "../utils/stringUtil.ts";
import GameVisualEngine, {
  POSITION_BACKGROUND,
  POSITION_CHARACTER_SPEAKING,
} from "../components/GameVisualEngine.tsx";

const GameScreen: React.FC = () => {
  const webSocketWrapper = useWebSocket();
  const [isMomentComplete, setIsMomentComplete] = useRecoilState(isMomentCompleteState);
  const [isGameLost] = useRecoilState(isGameLostState);
  const [isMomentStarted, setIsMomentStarted] = useRecoilState(isMomentStartedState);
  const [characterEmotion] = useRecoilState(currentCharacterEmotionState);
  const [objectiveHasJustBeenCompleted] = useRecoilState(objectiveHasJustBeenCompletedState);
  const momentsPromptElements = useRecoilValue(momentElementsAtom);
  const [, setIsThinking] = useRecoilState(isThinkingState);
  const [audioDataQueue] = useRecoilState(audioDataQueueState);
  const navigate = useNavigateWithQueryParams();
  useFetchMomentPrompt();
  const { loadBackgroundMusic } = useBackgroundMusicManager();
  const debouncedQueueLength = useDebouncedValue(audioDataQueue.length, 500, 1);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  useEffect(() => {
    setActiveImageIndex(debouncedQueueLength === 0 ? 0 : 1);
  }, [debouncedQueueLength]);

  const startMoment = useCallback(() => {
    if (momentsPromptElements.length > 0 && webSocketWrapper) {
      setIsMomentStarted(true);
      setIsMomentComplete(false);
      setIsThinking(true);
      webSocketWrapper.sendMessage(GameEngineMessageType.USER_INPUT, momentsPromptElements[0]);
      loadBackgroundMusic({
        storyId: momentsPromptElements[0]?.blueprint_story_id,
        fileName: momentsPromptElements[0]?.sound_intro,
        volume: momentsPromptElements[0]?.sound_intro_volume,
        secondFileName: momentsPromptElements[0]?.sound_outro,
      });
    }
  }, [momentsPromptElements, webSocketWrapper]);

  const onRecordingComplete = useCallback(
    (audioBlob: Blob) => {
      if (webSocketWrapper && momentsPromptElements.length > 0) {
        setIsThinking(true);
        webSocketWrapper.sendMessage(
          GameEngineMessageType.USER_INPUT,
          momentsPromptElements[0],
          audioBlob,
        );
      }
    },
    [momentsPromptElements, webSocketWrapper],
  );

  useEffect(() => {
    console.log("game screen momentsPromptElements", momentsPromptElements);
    if (
      isMomentComplete &&
      momentsPromptElements.length > 0 &&
      momentsPromptElements[0].parent_moment_id != null
    ) {
      startMoment();
    }
  }, [momentsPromptElements]);

  const renderStartScreen = () => (
    <>
      {momentsPromptElements.length > 0 && (
        <div
          className="pl-12 pr-12 pt-40 text-4xl flex flex-col h-screen"
          style={{
            backgroundColor: momentsPromptElements[0].background_rgba_color
              ? formatRGBAColor(momentsPromptElements[0].background_rgba_color)
              : "black",
          }}
        >
          {momentsPromptElements[0].scene_date && momentsPromptElements[0].language && (
            <AnimatedText
              text={formatDateString(
                momentsPromptElements[0].scene_date,
                momentsPromptElements[0].language,
              )}
              rgbaColor={momentsPromptElements[0].text_rgba_color}
            />
          )}
          <h1
            className="fade-in font-sans font-bold"
            style={{
              color: momentsPromptElements[0].text_rgba_color
                ? formatRGBAColor(momentsPromptElements[0].text_rgba_color)
                : "black",
            }}
          >
            {capitalize(momentsPromptElements[0].moment_name)}{" "}
            <button
              style={{
                color: momentsPromptElements[0].control_rgba_color
                  ? formatRGBAColor(momentsPromptElements[0].control_rgba_color)
                  : "black",
              }}
              className="opacity-100 hover:opacity-50 transition-opacity duration-500"
              onClick={() => startMoment()}
            >
              Start
            </button>
          </h1>
        </div>
      )}
    </>
  );

  const renderInGameScreen = () => (
    <>
      {!isMomentComplete && (
        <div
          className="relative w-full h-screen flex flex-col"
          style={{
            backgroundColor: momentsPromptElements[0]?.background_rgba_color
              ? formatRGBAColor(momentsPromptElements[0].background_rgba_color)
              : "black",
          }}
        >
          {/* Game Visual Container */}
          <div className="flex-grow relative">
            <div className="absolute inset-0">
              <GameVisualEngine
                storyId={momentsPromptElements[0]?.blueprint_story_id ?? ""}
                backgroundImages={momentsPromptElements[0]?.moment_image_details}
                characterImages={momentsPromptElements[0]?.character_image_details}
                currentPosition={
                  activeImageIndex === 0 ? POSITION_BACKGROUND : POSITION_CHARACTER_SPEAKING
                }
                characterEmotion={characterEmotion.emotionKey}
                characterEmotionStrength={characterEmotion.emotionStrength}
                isEvent={objectiveHasJustBeenCompleted}
              />
            </div>
          </div>

          {/* Audio Recorder Container */}
          <div className="w-full bg-black bg-opacity-90 fixed bottom-0 h-1/5 left-0 right-0 z-50 flex justify-center ">
            <AudioRecorder onRecordingComplete={onRecordingComplete} />
          </div>
        </div>
      )}
    </>
  );

  const renderEndOfMomentScreen = () => (
    <div
      className="pl-12 pr-12 pt-40 text-4xl flex flex-col h-screen"
      style={{
        backgroundColor: momentsPromptElements[0].background_rgba_color
          ? formatRGBAColor(momentsPromptElements[0].background_rgba_color)
          : "black",
      }}
    >
      <AnimatedText text="MOMENT COMPLETE" rgbaColor={momentsPromptElements[0].text_rgba_color} />
      <h1 className="fade-in">
        <button
          style={{
            color: momentsPromptElements[0].control_rgba_color
              ? formatRGBAColor(momentsPromptElements[0].control_rgba_color)
              : "black",
          }}
          className=" opacity-100 hover:opacity-50 transition-opacity duration-500"
          onClick={() => setIsMomentStarted(false)}
        >
          Start next moment
        </button>
      </h1>
    </div>
  );

  const renderLostScreen = () => (
    <div
      className="pl-12 pr-12 pt-40 text-4xl flex flex-col h-screen"
      style={{
        backgroundColor: momentsPromptElements[0].background_rgba_color
          ? formatRGBAColor(momentsPromptElements[0].background_rgba_color)
          : "black",
      }}
    >
      <AnimatedText text="YOU LOOSE" rgbaColor={momentsPromptElements[0].text_rgba_color} />
      <h1 className="fade-in ">
        <button
          style={{
            color: momentsPromptElements[0].control_rgba_color
              ? formatRGBAColor(momentsPromptElements[0].control_rgba_color)
              : "black",
          }}
          className="opacity-100 hover:opacity-50 transition-opacity duration-500"
          onClick={() => navigate("/launch")}
        >
          Restart
        </button>
      </h1>
    </div>
  );

  const renderGameWonScreen = () => (
    <>
      <AnimatedText text="YOU WON" rgbaColor={momentsPromptElements[0].text_rgba_color} />
      <h1 className="fade-in">
        <button
          style={{
            color: momentsPromptElements[0].control_rgba_color
              ? formatRGBAColor(momentsPromptElements[0].control_rgba_color)
              : "black",
          }}
          className="text-white opacity-100 hover:opacity-50 transition-opacity duration-500"
          onClick={() => navigate("/launch")}
        >
          Restart
        </button>
      </h1>
    </>
  );

  return (
    <div className=" text-4xl flex flex-col h-screen text-black gap-3">
      {/*<div className="absolute z-10">*/}
      {/*  <p className="text-base">{"isMomentStarted: " + isMomentStarted.toString()}</p>*/}
      {/*  <p className="text-base">{"isMomentComplete: " + isMomentComplete.toString()}</p>*/}
      {/*  <p className="text-base">{"audioDataQueue.length: " + audioDataQueue.length.toString()}</p>*/}
      {/*  <p className="text-base">*/}
      {/*    {"parent_moment_id: " + momentsPromptElements[0]?.parent_moment_id?.toString()}*/}
      {/*  </p>*/}
      {/*  <p className="text-base">*/}
      {/*    {"moment_name: " + momentsPromptElements[0]?.moment_name?.toString()}*/}
      {/*  </p>*/}
      {/*</div>*/}
      <ErrorModal />
      {(() => {
        const isStartScreen = !isMomentStarted;
        const isGameLostScreen = isGameLost && isMomentStarted;
        const isGameWonScreen =
          momentsPromptElements[0]?.moment_type === MOMENT_TYPE_WON_GAME && isMomentStarted;
        const isEndOfMomentScreen =
          isMomentComplete &&
          momentsPromptElements[0].parent_moment_id == null &&
          audioDataQueue.length == 0;

        if (isStartScreen) {
          return renderStartScreen();
        } else if (isGameLostScreen) {
          return renderLostScreen();
        } else if (isEndOfMomentScreen) {
          return renderEndOfMomentScreen();
        } else if (isGameWonScreen) {
          return renderGameWonScreen();
        } else {
          return renderInGameScreen();
        }
      })()}
    </div>
  );
};

export default GameScreen;
