import { useEffect, useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { PencilIcon } from "@heroicons/react/16/solid";
import { SpokableButton } from "./SpokableButton.tsx";
import DatabaseSelectInput from "./DatabaseSelectInput.tsx";
import { Tables, TablesInsert } from "../../types/database.ts";
import { supabase } from "../../vendor/supabaseClient.ts";
import { Input } from "../catalyst/input.tsx";
import { CHARACTER_VISUALS_LIST } from "../../constants/constant.ts";
import { Card } from "./Card.tsx";
import CustomEmotionVideoRecorderCard from "./drivingVideos/CustomEmotionVideoRecorderCard.tsx";

interface EmotionButtonProps {
  storyId: string;
  onSettingsDone: () => void;
  baseImageFile: File;
}

export default function CustomEmotionButton({
  storyId,
  onSettingsDone,
  baseImageFile,
}: EmotionButtonProps) {
  const [isOpen, setIsOpen] = useState(false);

  const [drivingVideoCollectionId, setDrivingVideoCollectionId] = useState<string | null>(null);
  const [selectedDrivingVideos, setSelectedDrivingVideos] = useState<
    Tables<"blueprint_driving_videos">[] | null
  >(null);
  const [selectedDrivingCollection, setSelectedDrivingCollection] =
    useState<Tables<"blueprint_driving_video_collections"> | null>(null);

  const closePopup = () => {
    setIsOpen(false);
    clean();
    onSettingsDone();
  };

  useEffect(() => {
    fetchDrivingVideosCollection();
    fetchExistingDrivingVideos();
  }, [drivingVideoCollectionId]);

  function clean() {
    setSelectedDrivingVideos(null);
    setSelectedDrivingCollection(null);
    setDrivingVideoCollectionId(null);
  }

  async function fetchDrivingVideosCollection() {
    if (!drivingVideoCollectionId) return;
    const { data, error } = await supabase
      .from("blueprint_driving_video_collections")
      .select("*")
      .eq("id", drivingVideoCollectionId)
      .limit(1)
      .single();

    if (error) {
      console.log("Error creating collection: " + error.message);
    } else {
      setSelectedDrivingCollection(data);
    }
  }

  async function fetchExistingDrivingVideos() {
    if (!drivingVideoCollectionId) return;
    const { data, error } = await supabase
      .from("blueprint_driving_videos")
      .select("*")
      .eq("blueprint_driving_video_collection_id", drivingVideoCollectionId);

    if (error) {
      console.log("Error creating collection: " + error.message);
    } else {
      setSelectedDrivingVideos(data);
    }
  }

  const createDrivingVideosCollection = async () => {
    const newCollection: TablesInsert<"blueprint_driving_video_collections"> = {
      collection_name: "New collection",
      blueprint_story_id: storyId,
    };

    const { data, error } = await supabase
      .from("blueprint_driving_video_collections")
      .insert(newCollection)
      .select()
      .single();

    if (error) {
      console.log("Error creating collection: " + error.message);
    } else {
      setDrivingVideoCollectionId(data.id);
    }
  };

  const updateCollection = async () => {
    if (!selectedDrivingCollection) return;

    const { id, ...collectionWithoutId } = selectedDrivingCollection;

    const { error } = await supabase
      .from("blueprint_driving_video_collections")
      .update(collectionWithoutId)
      .eq("id", id);

    if (error) {
      console.log("Error creating collection: " + error.message);
    }
    clean();
  };

  async function deleteDrivingVideoCollection() {
    if (!selectedDrivingCollection) return;
    if (window.confirm("Are you sure you want to delete this collection?")) {
      const { error: deleteError } = await supabase
        .from("blueprint_driving_video_collections")
        .delete()
        .eq("id", selectedDrivingCollection.id);

      setDrivingVideoCollectionId(null);
      setSelectedDrivingCollection(null);
      setSelectedDrivingVideos(null);

      if (deleteError) {
        throw new Error("Error deleting moment: " + deleteError.message);
      }
    }
  }

  return (
    <>
      <SpokableButton
        color="light"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <PencilIcon className="mr-2" /> Manage reference videos
      </SpokableButton>

      <Dialog
        open={isOpen}
        onClose={() => {
          closePopup();
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <DialogPanel className="w-2/3 h-[90vh] transform overflow-hidden rounded-md bg-white shadow-xl transition-all flex flex-col">
            <DialogTitle className="font-serif p-6 text-4xl font-bold mb-4">
              Emotion Settings
            </DialogTitle>
            <div className="flex-1 px-6 overflow-auto">
              {!selectedDrivingCollection && !selectedDrivingVideos && (
                <div className="flex items-center space-x-4 w-1/2">
                  <div className="flex-1">
                    <DatabaseSelectInput
                      table="blueprint_driving_video_collections"
                      keyColumn="id"
                      labelColumn="collection_name"
                      storyId={storyId}
                      value={drivingVideoCollectionId}
                      onChange={(value) => {
                        setDrivingVideoCollectionId(value);
                      }}
                      placeholder="Select a custom emotions collection"
                    />
                  </div>
                  <SpokableButton onClick={createDrivingVideosCollection}>New</SpokableButton>
                </div>
              )}
              {selectedDrivingCollection && selectedDrivingVideos && (
                <div>
                  <div className="w-1/2 mt-4">
                    <div className="flex items-center gap-4">
                      <span className="text-sm">Name</span>
                      <Input
                        type="text"
                        id="collection name"
                        value={selectedDrivingCollection.collection_name || ""}
                        onChange={(e) =>
                          setSelectedDrivingCollection({
                            ...selectedDrivingCollection,
                            collection_name: e.target.value,
                          })
                        }
                      />
                      <SpokableButton onClick={updateCollection}>Save</SpokableButton>

                      <SpokableButton color="light" onClick={() => deleteDrivingVideoCollection()}>
                        Delete
                      </SpokableButton>
                    </div>
                  </div>
                  <ul className="grid grid-cols-4 gap-4 mb-4">
                    {CHARACTER_VISUALS_LIST.map((emotion) => (
                      <li key={emotion.key}>
                        <Card className="aspect-square mb-6">
                          <div className="relative w-full h-full">
                            <CustomEmotionVideoRecorderCard
                              collection={selectedDrivingCollection}
                              onVideoCreated={() => fetchExistingDrivingVideos()}
                              emotionKey={emotion.key}
                              storyId={storyId}
                              baseImageFile={baseImageFile}
                              aVideoAlreadyExist={selectedDrivingVideos.some(
                                (video) => video.video_key === emotion.key,
                              )}
                            />
                          </div>
                        </Card>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="p-6 bg-gray-50 border-t">
              <div className="flex justify-between items-center">
                <SpokableButton onClick={() => closePopup()} color="light">
                  Close
                </SpokableButton>
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
}
