import { useState } from "react";
import { PlusCircle } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  SettingForm,
  SettingFormValues,
} from "@/components/admin/generateImagesModal/ImageModelCRUD/ImageModelSettingsForm";
import { ModelCard } from "@/components/admin/generateImagesModal/ImageModelCRUD/ImageModelCard";
import {
  ModelForm,
  ModelFormValues,
} from "@/components/admin/generateImagesModal/ImageModelCRUD/ImageModelForm";
import {
  BlueprintImageModels,
  BlueprintImageModelSettings,
} from "@/components/admin/generateImagesModal/hooks/useImageGenModelsDatabase.tsx";
import { useImageModelDatabase } from "@/components/admin/generateImagesModal/ImageModelCRUD/useImageModelDatabase.ts";
import { SpokableButton } from "@/components/admin/SpokableButton.tsx";
import { Toaster } from "@/components/ui/toaster.tsx";

export function ImageModelCRUD() {
  const [isModelDialogOpen, setIsModelDialogOpen] = useState(false);
  const [isSettingDialogOpen, setIsSettingDialogOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useState<BlueprintImageModels | null>(null);
  const [selectedSetting, setSelectedSetting] = useState<BlueprintImageModelSettings | null>(null);
  const [selectedModelIdForSetting, setSelectedModelIdForSetting] = useState<string | null>(null);

  const {
    models,
    settings,
    isLoading,
    createOrUpdateModel,
    createOrUpdateSetting,
    deleteModel,
    deleteSetting,
  } = useImageModelDatabase();

  const handleModelSubmit = async (formData: ModelFormValues) => {
    const success = await createOrUpdateModel(formData, selectedModel);
    if (success) {
      setIsModelDialogOpen(false);
      setSelectedModel(null);
    }
  };

  const handleSettingSubmit = async (formData: SettingFormValues) => {
    const success = await createOrUpdateSetting(
      formData,
      selectedSetting,
      selectedModelIdForSetting,
    );
    if (success) {
      setIsSettingDialogOpen(false);
      setSelectedSetting(null);
      setSelectedModelIdForSetting(null);
    }
  };

  const handleEditModel = (model: BlueprintImageModels) => {
    setSelectedModel(model);
    setIsModelDialogOpen(true);
  };

  const handleAddSetting = (modelId: string) => {
    setSelectedSetting(null);
    setSelectedModelIdForSetting(modelId);
    setIsSettingDialogOpen(true);
  };

  const handleEditSetting = (setting: BlueprintImageModelSettings) => {
    setSelectedSetting(setting);
    setSelectedModelIdForSetting(setting.image_model_id);
    setIsSettingDialogOpen(true);
  };

  return (
    <div className="min-h-[600px] flex flex-col">
      <div className="p-6 flex justify-between items-center">
        <h1 className="text-4xl font-bold">Blueprint Models</h1>
        <SpokableButton onClick={() => setIsModelDialogOpen(true)}>
          <PlusCircle className="mr-2 h-4 w-4" />
          Add Model
        </SpokableButton>
      </div>

      <div className="flex-1 px-6 overflow-hidden min-h-0">
        {isLoading ? (
          <div className="text-center p-12">
            <p className="text-muted-foreground">Loading...</p>
          </div>
        ) : (
          <div className="h-full overflow-y-auto pr-2">
            <div className="space-y-6 pb-6 max-h-[600px] overflow-y-auto">
              {models.map((model) => (
                <ModelCard
                  key={model.id}
                  model={model}
                  settings={settings.filter((s) => s.image_model_id === model.id)}
                  onEdit={handleEditModel}
                  onDelete={deleteModel}
                  onAddSetting={handleAddSetting}
                  onEditSetting={handleEditSetting}
                  onDeleteSetting={deleteSetting}
                />
              ))}
              {!isLoading && models.length === 0 && (
                <div className="text-center p-12 border rounded-lg border-dashed">
                  <p className="text-muted-foreground">
                    No models yet. Click the button above to add your first model.
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <Dialog open={isModelDialogOpen} onOpenChange={setIsModelDialogOpen}>
        <DialogContent className="max-w-2xl">
          <DialogHeader>
            <DialogTitle>{selectedModel ? "Edit Model" : "Add New Model"}</DialogTitle>
            <DialogDescription>
              Configure the blueprint model settings and parameters.
            </DialogDescription>
          </DialogHeader>
          <ModelForm
            onSubmit={handleModelSubmit}
            initialData={selectedModel || undefined}
            onCancel={() => {
              setIsModelDialogOpen(false);
              setSelectedModel(null);
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={isSettingDialogOpen} onOpenChange={setIsSettingDialogOpen}>
        <DialogContent className="max-w-2xl">
          <DialogHeader>
            <DialogTitle>{selectedSetting ? "Edit Setting" : "Add New Setting"}</DialogTitle>
            <DialogDescription>Configure a new setting for this model.</DialogDescription>
          </DialogHeader>
          <SettingForm
            onSubmit={handleSettingSubmit}
            initialData={selectedSetting || undefined}
            onCancel={() => {
              setIsSettingDialogOpen(false);
              setSelectedSetting(null);
              setSelectedModelIdForSetting(null);
            }}
          />
        </DialogContent>
      </Dialog>
      <Toaster />
    </div>
  );
}
