import React from "react";
import { useDeleteMoment, useUpdateMoment } from "../../../hooks/database/useMoment.ts";
import { Tables } from "../../../types/database.ts";
import { SpokableButton } from "../SpokableButton.tsx";

interface DeleteMomentComponentProps {
  momentId: string | undefined | null;
  moments: Tables<"blueprint_moments">[] | undefined;
  onMomentDeleted?: () => void;
}

const DeleteMomentButton: React.FC<DeleteMomentComponentProps> = ({
  momentId,
  moments,
  onMomentDeleted,
}) => {
  const { updateMoment } = useUpdateMoment();
  const { deleteMoment } = useDeleteMoment(updateMoment);

  const handleDelete = () => {
    if (!momentId) return;
    if (!moments) return;
    deleteMoment(momentId, moments, onMomentDeleted);
  };

  return (
    <SpokableButton color={"light"} onClick={handleDelete}>
      Delete
    </SpokableButton>
  );
};

export default DeleteMomentButton;
