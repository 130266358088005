import React, { useEffect, useState } from "react";
import { CHARACTER_VISUALS_LIST, IMAGE_TYPE_EMOTION_VIDEO } from "@/constants/constant.ts";
import { SpokableButton } from "../SpokableButton.tsx";
import { SparklesIcon } from "@heroicons/react/16/solid";
import FormField from "../FormField.tsx";
import { Checkbox, CheckboxField } from "../../catalyst/checkbox.tsx";
import { Label } from "../../catalyst/fieldset.tsx";
import AnimatedStatus from "../AnimatedStatus.tsx";
import DatabaseSelectInput from "../DatabaseSelectInput.tsx";
import Collapsible from "../Collapsible.tsx";
import CustomEmotionButton from "../EmotionVideoButton.tsx";
import { supabase } from "@/vendor/supabaseClient.ts";
import { Tables } from "@/types/database.ts";
import { downloadFile, getCustomEmotionDrivingVideos, useUploadVideos } from "./videoUtils.ts";
import { EmotionVideoGallery } from "./EmotionVideoGallery.tsx";
import useMediaGenerationWebsocket, {
  MediaMessageType,
} from "@/components/admin/generateImagesModal/hooks/useMediaGenerationWebsocket.ts";
import {
  DTOCharacterEmotionVideosCall,
  DTOCustomEmotionVideo,
  DTOImageVideoResult,
  DTOVideoResult,
} from "@/types/fastApiMediaGenerationTypes.ts";
import { WebSocketMessage } from "@/hooks/useBaseWebSocket.ts";
import { fileToBase64 } from "@/utils/imageUtil.ts";

export interface BluePrintImageToBeSaved {
  image_url: string;
}

interface GenerateEmotionVideosProps {
  storyId: string;
  baseImageUrlWithStory: string | null;
  onVideosCompleted: (imageToBeSaved: BluePrintImageToBeSaved) => void;
}

export const GenerateCharacterEmotionVideos: React.FC<GenerateEmotionVideosProps> = ({
  baseImageUrlWithStory,
  storyId,
  onVideosCompleted,
}) => {
  const [bluePrintImageToBeSaved, setBluePrintImageToBeSaved] =
    useState<BluePrintImageToBeSaved | null>(null);
  const [status, setStatus] = useState("");
  const [emotionVideos, setEmotionVideos] = useState<DTOVideoResult[]>([]);
  const [baseImageFile, setBaseImageFile] = useState<File | null>(null);
  const [checkedEmotions, setCheckedEmotions] = useState<string[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isEmotionControlsOpened, setIsEmotionControlsOpened] = useState(false);
  const [drivingVideoCollectionId, setDrivingVideoCollectionId] = useState<string | null>(null);
  const [refetchCounter, setRefetchCounter] = useState(0);
  const [selectedDrivingCollection, setSelectedDrivingCollection] =
    useState<Tables<"blueprint_driving_video_collections"> | null>(null);
  const { uploadVideos } = useUploadVideos(storyId);
  const ws = useMediaGenerationWebsocket();

  useEffect(() => {
    ws.onReceive(MediaMessageType.GENERATE_CHARACTER_EMOTION_VIDEO, (message) => {
      const imageResult: DTOImageVideoResult = message.messageObject;
      if (imageResult.output) {
        const videos = [...emotionVideos, imageResult.output];
        setEmotionVideos([...emotionVideos, imageResult.output]);
        if (imageResult.status === "succeeded" && imageResult.are_all_videos_completed) {
          handleUpload(videos);
          setStatus("");
        }
      } else {
        setStatus(Math.round((emotionVideos.length / checkedEmotions.length) * 100) + "%");
      }
    });

    ws.onReceive(MediaMessageType.IMAGE_ERROR, (message: WebSocketMessage) => {
      const error = message.messageObject;
      setStatus(error);
    });
  }, [ws]);

  const handleEmotionSelectionChange = (emotionKey: string, isChecked: boolean) => {
    setCheckedEmotions((prevChecked) => {
      if (isChecked) {
        return [...prevChecked, emotionKey];
      } else {
        return prevChecked.filter((emotion) => emotion !== emotionKey);
      }
    });
  };

  useEffect(() => {
    fetchDrivingVideosCollection();
    toggleAll();
  }, [drivingVideoCollectionId]);

  useEffect(() => {
    downloadImage();
  }, [baseImageUrlWithStory]);

  async function downloadImage() {
    if (!baseImageUrlWithStory) return;

    const file = await downloadFile(baseImageUrlWithStory);
    if (!file) return;
    setBaseImageFile(file);
    setStatus("");
  }

  async function fetchDrivingVideosCollection() {
    if (!drivingVideoCollectionId) return;
    const { data, error } = await supabase
      .from("blueprint_driving_video_collections")
      .select("*")
      .eq("id", drivingVideoCollectionId)
      .limit(1)
      .single();

    if (error) {
      console.log("Error creating collection: " + error.message);
    } else {
      setSelectedDrivingCollection(data);
    }
  }

  const handleGenerateEmotionVideos = async () => {
    if (!baseImageFile) return;

    setStatus("Starting...");
    const customEmotionVideos: DTOCustomEmotionVideo[] = [];
    await Promise.all(
      checkedEmotions.map(async (emotion) => {
        const emotionVideos = await getCustomEmotionDrivingVideos(
          selectedDrivingCollection,
          [emotion],
          storyId,
        );
        if (emotionVideos && emotionVideos.length > 0) {
          customEmotionVideos.push({
            emotion_key: emotion,
            custom_emotion_video_base_64: await fileToBase64(emotionVideos[0]),
          });
        }
      }),
    );

    const baseParams: DTOCharacterEmotionVideosCall = {
      character_image_base_64: await fileToBase64(baseImageFile),
      emotions_to_compute: checkedEmotions,
      custom_emotion_videos: customEmotionVideos,
    };

    ws.send(MediaMessageType.GENERATE_CHARACTER_EMOTION_VIDEO, baseParams);
  };

  const handleUpload = async (videos: DTOVideoResult[]) => {
    const result = await uploadVideos(baseImageUrlWithStory, videos, IMAGE_TYPE_EMOTION_VIDEO);
    if (result) {
      setBluePrintImageToBeSaved(result);
      setStatus("");
    }
  };

  const toggleAll = () => {
    if (isAllSelected) {
      setCheckedEmotions([]);
    } else {
      setCheckedEmotions(CHARACTER_VISUALS_LIST.map((emotion) => emotion.key));
    }
    setIsAllSelected(!isAllSelected);
  };

  return (
    <div className="flex-grow overflow-y-auto p-6 pt-0">
      <Collapsible
        title="Custom emotion videos"
        defaultStateIsOpen={false}
        forceClose={isEmotionControlsOpened}
      >
        <div className="space-y-4 mb-4">
          <div className="flex items-center">
            <span onClick={toggleAll} className=" cursor-pointer">
              Select emotions you want to render:
            </span>
          </div>
          <ul className="grid grid-cols-2 gap-4 mb-4">
            {CHARACTER_VISUALS_LIST.map((emotion) => (
              <li key={emotion.key}>
                <CheckboxField>
                  <Checkbox
                    id={emotion.key}
                    checked={checkedEmotions.includes(emotion.key)}
                    onChange={(checked) =>
                      handleEmotionSelectionChange(emotion.key, checked as boolean)
                    }
                  />
                  <Label htmlFor={emotion.key} className="ml-2">
                    {emotion.value}
                  </Label>
                </CheckboxField>
              </li>
            ))}
          </ul>
        </div>
        <FormField className="mt-12" label={"Using those reference videos:"}>
          <div className="w-1/2">
            <DatabaseSelectInput
              table="blueprint_driving_video_collections"
              keyColumn="id"
              labelColumn="collection_name"
              storyId={storyId}
              value={drivingVideoCollectionId}
              onChange={(value) => {
                setDrivingVideoCollectionId(value);
              }}
              refetchTrigger={refetchCounter}
              addNullValueOption={true}
              placeholder="Select a custom emotions collection"
            />
          </div>
          <div className="mt-4">
            {baseImageFile && (
              <CustomEmotionButton
                storyId={storyId}
                baseImageFile={baseImageFile}
                onSettingsDone={() => setRefetchCounter((prev) => prev + 1)}
              />
            )}
          </div>
        </FormField>
      </Collapsible>
      <FormField>
        <SpokableButton
          onClick={() => {
            setIsEmotionControlsOpened(true);
            handleGenerateEmotionVideos();
          }}
          className="mr-4"
        >
          <SparklesIcon className="mr-2 mb-4" />
          Generate videos
        </SpokableButton>
      </FormField>
      <EmotionVideoGallery videoResults={emotionVideos} />
      <div className="mt-4">
        <AnimatedStatus status={status} />
      </div>
      {bluePrintImageToBeSaved && emotionVideos.length != 0 && (
        <SpokableButton
          className="align-bottom mt-12"
          onClick={() => onVideosCompleted(bluePrintImageToBeSaved)}
          disabled={emotionVideos.length === 0}
        >
          Add videos above
        </SpokableButton>
      )}
    </div>
  );
};
