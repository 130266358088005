import React, { useEffect, useMemo } from "react";
import { ArrowPathIcon } from "@heroicons/react/16/solid";
import { SpokableButton } from "../SpokableButton.tsx";
import { LogLine } from "@/types/logline_prompts_generated_types.ts";
import { LoadingSpinner } from "@/components/admin/LoadingSpinner.tsx";
import { OnePager } from "@/types/one_pager_prompts_generated_types.ts";
import { Tables } from "@/types/database.ts";
import GenerateBaseImage from "@/components/admin/generateImagesModal/GenerateBaseImage.tsx";
import { sanitizeForURL } from "@/utils/stringUtil.ts";
import { uploadToSupabaseFileFromUrl } from "@/utils/mediaUtil.ts";

export interface GenerateOnePagerProps {
  onePager: OnePager | null;
  status: string;
  setOnePager: React.Dispatch<React.SetStateAction<OnePager | null>>;
  generateOnePager: (payload: any) => void;
  logline: LogLine | null;
  audience: string;
  title: string;
  story: Tables<"blueprint_stories">;
  setStory: React.Dispatch<React.SetStateAction<Tables<"blueprint_stories"> | null>>;
  onSave: () => void;
}

const GenerateStyle: React.FC<GenerateOnePagerProps> = ({
  onePager,
  status,
  setOnePager,
  generateOnePager,
  logline,
  audience,
  title,
  story,
  setStory,
  onSave,
}) => {
  const memoizedOnePager = useMemo(() => onePager, [onePager]);

  useEffect(() => {
    if (logline && onePager === null && status === "") {
      generateOnePager({ logline: logline?.log_line || "", audience: audience });
    }
  }, [onePager, logline, generateOnePager, setOnePager]);

  const handleRegenerate = () => {
    if (logline) {
      setOnePager(null);
    }
  };

  const uploadGeneratedImage = async (selectedImageReplicateUrl: string) => {
    const fileNameInStorage = `${sanitizeForURL("poster")}-${Date.now()}.jpg`;
    await uploadToSupabaseFileFromUrl(story.id, selectedImageReplicateUrl, fileNameInStorage);
    setStory({ ...story, background_image: fileNameInStorage });
    onSave();
  };

  return (
    <div>
      <div>
        <div className="flex items-center">
          <h1 className="text-2xl font-bold mb-2">{title}</h1>
          <SpokableButton
            isIconButton={true}
            color="light"
            className="ml-2"
            onClick={handleRegenerate}
          >
            <ArrowPathIcon className="h-4 w-4" />
          </SpokableButton>
        </div>
        <span className="text-sm font-sans"> {status}</span>
      </div>
      {status !== "" && <LoadingSpinner />}
      {memoizedOnePager && (
        <div className="mt-4 max-h-[calc(90vh-300px)] overflow-y-auto justify-items-start">
          <GenerateBaseImage
            storyId={story.id}
            initialPrompt={memoizedOnePager.image_generation_prompt}
            initialPromptModifier={memoizedOnePager.aesthetic_style}
            onImageSelected={(imageUrl, imageModelId, prompt, style) => {
              setStory((prevStory) => {
                if (!prevStory) return null;
                return {
                  ...prevStory,
                  image_prompt: prompt,
                  blueprint_image_model_id: imageModelId,
                  image_prompt_style: style,
                };
              });
              uploadGeneratedImage(imageUrl);
            }}
            imageModelId={story.blueprint_image_model_id}
          />
          {/*<SpokableButton onClick={() => uploadGeneratedImage()}>Save</SpokableButton>*/}
        </div>
      )}
    </div>
  );
};

export default GenerateStyle;
