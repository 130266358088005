import React, { useState } from "react";
import { Input } from "@/components/ui/input.tsx";
import { cn } from "@/lib/utils.ts";
import { PencilIcon } from "@heroicons/react/16/solid";

interface EditableTitleProps {
  initialTitle?: string;
  onSave?: (title: string) => void;
  className?: string;
  titleClassName?: string;
  inputClassName?: string;
  containerClassName?: string;
}

const EditableTitle: React.FC<EditableTitleProps> = ({
  initialTitle = "Title",
  onSave = (title: string) => console.log("New title:", title),
  className,
  titleClassName,
  inputClassName,
  containerClassName,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(initialTitle);
  const [tempTitle, setTempTitle] = useState<string>(initialTitle);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === "Enter") {
      setTitle(tempTitle);
      onSave(tempTitle);
      setIsEditing(false);
    } else if (e.key === "Escape") {
      setTempTitle(title);
      setIsEditing(false);
    }
  };

  const handleBlur = (): void => {
    setTempTitle(title);
    setIsEditing(false);
  };

  return (
    <div className={cn("flex items-center gap-2 w-full max-w-md", containerClassName)}>
      {isEditing ? (
        <Input
          value={tempTitle}
          onChange={(e) => setTempTitle(e.target.value)}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          autoFocus
          className={cn("font-semibold", inputClassName)}
        />
      ) : (
        <div className={cn("flex items-center gap-2 w-full", className)}>
          <h2 className={cn("text-xl font-semibold", titleClassName)}>{title}</h2>
          <button
            onClick={() => setIsEditing(true)}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
            type="button"
            aria-label="Edit title"
          >
            <PencilIcon className="w-6 h-6 text-gray-500" />
          </button>
        </div>
      )}
    </div>
  );
};

export default EditableTitle;
