import { useCallback, useEffect, useState } from "react";
import { supabase } from "../../vendor/supabaseClient.ts";
import { Tables } from "../../types/database.ts";
import { getSupabaseImageUrl } from "../../utils/mediaUtil.ts";
import _ from "lodash";

export const useFetchMoments = () => {
  const [moments, setMoments] = useState<Tables<"blueprint_moments">[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchMoments = async (storyId: string | undefined) => {
    if (!storyId) return;
    setIsLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from("blueprint_moments")
        .select("*")
        .eq("blueprint_story_id", storyId)
        .order("created_at", { ascending: true });

      if (error) {
        throw new Error("Error fetching moments: " + error.message);
      }

      setMoments(data || []);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchMoments:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchMoments, moments, error, isLoading };
};

export const useFetchStory = () => {
  const [story, setStory] = useState<Tables<"blueprint_stories"> | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchStory = async (storyId: string | undefined) => {
    if (!storyId) return;
    setIsLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from("blueprint_stories")
        .select("*")
        .eq("id", storyId)
        .returns<Tables<"blueprint_stories">[]>()
        .single();

      if (error) {
        throw new Error("Error fetching story: " + error.message);
      }
      setStory(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchStory:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchStory, story, error, isLoading };
};

export const useFetchTransitions = () => {
  const [transitions, setTransitions] = useState<Tables<"blueprint_moment_transitions">[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTransitions = async (storyId: string | undefined) => {
    if (!storyId) return;
    setIsLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from("blueprint_moment_transitions")
        .select("*")
        .eq("blueprint_story_id", storyId);

      if (error) {
        throw new Error("Error fetching transitions: " + error.message);
      }
      setTransitions(data || []);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchTransitions:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchTransitions, transitions, error, isLoading };
};

export const useUpdateMoment = () => {
  const [moment, setMoment] = useState<Tables<"blueprint_moments"> | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const updateMoment = async (
    momentToUpdate: Tables<"blueprint_moments">,
    onMomentUpdate?: (updatedMoment: Tables<"blueprint_moments">) => void,
  ) => {
    setIsLoading(true);
    setError(null);

    const { id, ...momentWithoutId } = momentToUpdate;

    const { data, error: updateError } = await supabase
      .from("blueprint_moments")
      .update(momentWithoutId)
      .eq("id", id)
      .select()
      .limit(1)
      .single();

    if (updateError) {
      setError("Error updating moment: " + updateError.message);
    } else if (data) {
      setMoment(data);
      if (onMomentUpdate) onMomentUpdate(data);
    }

    setIsLoading(false);
  };

  return { updateMoment, error, isLoading, moment };
};

export const useDeleteMoment = (
  updateMoment: (moment: Tables<"blueprint_moments">) => Promise<void>,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const deleteMoment = async (
    momentId: string,
    moments: Tables<"blueprint_moments">[],
    onMomentDeleted?: () => void,
  ) => {
    if (!window.confirm("Are you sure you want to delete this moment?")) {
      console.log("Moment deletion cancelled by user.");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const { error: deleteError } = await supabase
        .from("blueprint_moments")
        .delete()
        .eq("id", momentId);

      if (deleteError) {
        throw new Error("Error deleting moment: " + deleteError.message);
      }

      if (moments.length > 0) {
        const newStartingMoment = { ...moments[0], is_starting_moment: true };
        await updateMoment(newStartingMoment);
      }

      if (onMomentDeleted) onMomentDeleted();
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in deleteMoment:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { deleteMoment, isLoading, error };
};

export const useFetchMoment = () => {
  const [moment, setMoment] = useState<Tables<"blueprint_moments"> | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchMoment = async (momentId: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from("blueprint_moments")
        .select("*")
        .eq("id", momentId)
        .single();

      if (error) throw new Error("Error fetching moment: " + error.message);
      setMoment(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchMoment:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchMoment, moment, error, isLoading };
};

export const useFetchCharacter = () => {
  const [character, setCharacter] = useState<Tables<"blueprint_characters"> | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCharacter = async (characterId: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from("blueprint_characters")
        .select("*")
        .eq("id", characterId)
        .single();

      if (error) throw new Error("Error fetching character: " + error.message);
      setCharacter(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchCharacter:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchCharacter, character, error, isLoading };
};

export const useFetchMomentImages = (storyId: string) => {
  const [error, setError] = useState<string | null>(null);

  const fetchMomentImages = useCallback(
    async (momentId: string) => {
      setError(null);
      try {
        const { data, error } = await supabase
          .from("blueprint_moment_medias")
          .select("*")
          .eq("blueprint_moment_id", momentId);

        if (error) throw new Error("Error fetching moment images: " + error.message);

        const urls = data
          .map((item) => (item.media_url ? getSupabaseImageUrl(storyId, item.media_url) || "" : ""))
          .filter((url) => url !== "");

        return urls.length > 0
          ? urls
          : [`https://api.dicebear.com/9.x/shapes/svg?seed=${momentId}`];
      } catch (err) {
        setError(err instanceof Error ? err.message : String(err));
        console.error("Error in fetchMomentImages:", err);
        return ["https://api.dicebear.com/9.x/shapes/svg"];
      }
    },
    [storyId],
  );

  return { fetchMomentImages, error };
};

export const useFetchCharacterImageUrl = (
  storyId: string,
  characterId: string,
  fetchAllImages: boolean = false,
) => {
  const [imageUrl, setImageUrl] = useState<string>(
    `https://api.dicebear.com/8.x/lorelei-neutral/svg?seed=${characterId}`,
  );
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const { data, error } = await supabase
          .from("blueprint_character_medias")
          .select("media_url")
          .eq("blueprint_character_id", characterId)
          .order("created_at", { ascending: false });

        if (error) throw new Error("Error fetching image URL: " + error.message);

        if (data && data.length > 0) {
          const urls = data
            .map((item) =>
              item.media_url ? getSupabaseImageUrl(storyId, item.media_url) || "" : "",
            )
            .filter((url) => url !== "");

          if (urls.length > 0) {
            setImageUrl(urls[0]); // Set the most recent image as the primary imageUrl
            setImageUrls(urls);
          }
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : String(err));
        console.error("Error in fetchImage:", err);
      }
    };
    fetchImage();
  }, [storyId, characterId]);

  return fetchAllImages ? { imageUrl, imageUrls, error } : { imageUrl, error };
};

export const useCharacterImageFetcher = () => {
  const [error, setError] = useState<string | null>(null);

  const fetchCharacterImages = useCallback(async (characterId: string) => {
    try {
      const { data, error } = await supabase
        .from("blueprint_character_medias")
        .select("*")
        .eq("blueprint_character_id", characterId)
        .order("created_at", { ascending: false });

      if (error) throw new Error("Error fetching image URLs: " + error.message);

      return data;
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchCharacterImages:", err);
      return [];
    }
  }, []);

  return { fetchCharacterImages, error };
};

export const useMomentImageFetcher = () => {
  const [error, setError] = useState<string | null>(null);

  const fetchMomentImages = useCallback(async (momentId: string) => {
    try {
      const { data, error } = await supabase
        .from("blueprint_moment_medias")
        .select("*")
        .eq("blueprint_moment_id", momentId)
        .order("created_at", { ascending: false });

      if (error) throw new Error("Error fetching image URLs: " + error.message);

      return data;
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchMomentImages:", err);
      return [];
    }
  }, []);

  return { fetchMomentImages, error };
};

export const useFetchContextBlocks = () => {
  const [contextBlocks, setContextBlocks] = useState<Tables<"blueprint_context_blocks">[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchContextBlocks = async (storyId: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from("blueprint_context_blocks")
        .select("*")
        .eq("blueprint_story_id", storyId)
        .order("created_at", { ascending: true });

      if (error) throw new Error("Error fetching context blocks: " + error.message);
      setContextBlocks(data || []);
      console.log("context", data);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchContextBlocks:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchContextBlocks, contextBlocks, error, isLoading };
};

export const useFetchContextBlocksLinks = () => {
  const [contextBlocksLinks, setContextBlocksLinks] = useState<
    Tables<"blueprint_moment_context_links">[]
  >([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchContextBlocksLinks = async (momentId: string | undefined) => {
    if (!momentId) return;
    setIsLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from("blueprint_moment_context_links")
        .select("*")
        .eq("moment_id", momentId)
        .order("created_at", { ascending: true });

      if (error) throw new Error("Error fetching context block links: " + error.message);
      setContextBlocksLinks(data || []);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error in fetchContextBlocksLinks:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchContextBlocksLinks, contextBlocksLinks, error, isLoading };
};

type MomentData = {
  moment: Tables<"blueprint_moments"> | null;
  backgroundImages: Tables<"blueprint_moment_medias">[];
  characterImages: Tables<"blueprint_character_medias">[];
  contextBlocks: Tables<"blueprint_context_blocks">[];
  contextBlockLinks: Tables<"blueprint_moment_context_links">[];
};

export const useFetchAllMomentData = () => {
  const [momentData, setMomentData] = useState<MomentData>({
    moment: null,
    backgroundImages: [],
    characterImages: [],
    contextBlocks: [],
    contextBlockLinks: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchAllMomentData = async (momentId: string) => {
    setIsLoading(true);
    setError(null);

    try {
      // Fetch moment data first
      const { data: momentData, error: momentError } = await supabase
        .from("blueprint_moments")
        .select("*")
        .eq("id", momentId)
        .single();

      if (momentError) throw new Error(momentError.message);
      if (!momentData) throw new Error("Moment not found");

      // Now fetch all related data in parallel
      const [
        backgroundImagesResponse,
        characterImagesResponse,
        contextBlocksResponse,
        contextBlockLinksResponse,
      ] = await Promise.all([
        supabase.from("blueprint_moment_medias").select("*").eq("blueprint_moment_id", momentId),
        supabase
          .from("blueprint_character_medias")
          .select("*")
          .eq("blueprint_character_id", momentData.blueprint_character_id),
        supabase
          .from("blueprint_context_blocks")
          .select("*")
          .eq("blueprint_story_id", momentData.blueprint_story_id),
        supabase.from("blueprint_moment_context_links").select("*").eq("moment_id", momentId),
      ]);

      // Check for errors in the responses
      if (backgroundImagesResponse.error) throw new Error(backgroundImagesResponse.error.message);
      if (characterImagesResponse.error) throw new Error(characterImagesResponse.error.message);
      if (contextBlocksResponse.error) throw new Error(contextBlocksResponse.error.message);
      if (contextBlockLinksResponse.error) throw new Error(contextBlockLinksResponse.error.message);

      setMomentData({
        moment: momentData,
        backgroundImages: backgroundImagesResponse.data || [],
        characterImages: characterImagesResponse.data || [],
        contextBlocks: contextBlocksResponse.data || [],
        contextBlockLinks: contextBlockLinksResponse.data || [],
      });
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchAllMomentData, momentData, isLoading, error };
};

export const useFetchUniqueImageUrls = (storyId: string) => {
  const [uniqueImageUrls, setUniqueImageUrls] = useState<(string | null)[]>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchMomentsAndImages = async () => {
      if (!storyId) return;

      // Fetch moments
      const { data: moments, error: momentsError } = await supabase
        .from("blueprint_moments")
        .select("*")
        .eq("blueprint_story_id", storyId)
        .order("created_at", { ascending: true });

      if (momentsError) {
        setError("Error fetching moments: " + momentsError.message);
        setIsLoading(false);
        return;
      }

      if (moments.length === 0) {
        setError("No moments found");
        setIsLoading(false);
        return;
      }

      let uniqueUrls: (string | null)[] = [];
      let attempts = 0;

      while (uniqueUrls.length === 0 && attempts < moments.length) {
        // Select a random moment
        const randomMoment = _.sample(moments);
        if (!randomMoment) continue;
        // Fetch moment and character images concurrently
        const [momentImagesResponse, characterImagesResponse] = await Promise.all([
          supabase
            .from("blueprint_moment_medias")
            .select("*")
            .eq("blueprint_moment_id", randomMoment.id),
          supabase
            .from("blueprint_character_medias")
            .select("*")
            .eq("blueprint_character_id", randomMoment.blueprint_character_id),
        ]);

        if (momentImagesResponse.error) {
          setError("Error fetching moment images: " + momentImagesResponse.error.message);
          setIsLoading(false);
          return;
        }

        if (characterImagesResponse.error) {
          setError("Error fetching character images: " + characterImagesResponse.error.message);
          setIsLoading(false);
          return;
        }

        // Combine and deduplicate image URLs
        const momentUrls = momentImagesResponse.data.map((image) =>
          getSupabaseImageUrl(storyId, image.media_url),
        );
        const characterUrls = characterImagesResponse.data.map((image) =>
          getSupabaseImageUrl(storyId, image.media_url),
        );
        const allImageUrls = [...momentUrls, ...characterUrls];
        uniqueUrls = _.uniq(allImageUrls);

        attempts++;
      }

      if (uniqueUrls.length === 0) {
        setError("No unique image URLs found");
      }

      // Set unique image URLs
      setUniqueImageUrls(uniqueUrls);
      setIsLoading(false);
    };

    fetchMomentsAndImages();
  }, [storyId]);

  return { uniqueImageUrls, error, isLoading };
};
