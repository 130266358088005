import { useEffect, useState } from "react";
import { supabase } from "@/vendor/supabaseClient";
import { BlueprintImageModels } from "@/components/admin/generateImagesModal/hooks/useImageGenModelsDatabase";
import { IMAGE_MODEL_CATEGORY_INPAINT, IMAGE_MODEL_UPSCALING } from "@/constants/constant";

interface UseImageModificationModelsDatabaseReturn {
  inpaintingModels: BlueprintImageModels[];
  selectedInpaintingModel: BlueprintImageModels | null;
  setSelectedInpaintingModel: (model: BlueprintImageModels) => void;
  upscalingModels: BlueprintImageModels[];
  selectedUpscalingModel: BlueprintImageModels | null;
  setSelectedUpscalingModel: (model: BlueprintImageModels) => void;
}

export const useImageModificationModelsDatabase = (): UseImageModificationModelsDatabaseReturn => {
  const [inpaintingModels, setInpaintingModels] = useState<BlueprintImageModels[]>([]);
  const [selectedInpaintingModel, setSelectedInpaintingModel] =
    useState<BlueprintImageModels | null>(null);
  const [upscalingModels, setUpscalingModels] = useState<BlueprintImageModels[]>([]);
  const [selectedUpscalingModel, setSelectedUpscalingModel] = useState<BlueprintImageModels | null>(
    null,
  );

  useEffect(() => {
    fetchInpaintingModels();
    fetchUpscalingModels();
  }, []);

  const fetchInpaintingModels = async () => {
    const { data, error } = await supabase
      .from("blueprint_image_models")
      .select("*")
      .eq("category", IMAGE_MODEL_CATEGORY_INPAINT);

    if (error) {
      console.error(error);
      return;
    }

    setInpaintingModels(data);
    if (data?.length > 0) {
      setSelectedInpaintingModel(data[0]);
    }
  };

  const fetchUpscalingModels = async () => {
    const { data, error } = await supabase
      .from("blueprint_image_models")
      .select("*")
      .eq("category", IMAGE_MODEL_UPSCALING);

    if (error) {
      console.error(error);
      return;
    }

    setUpscalingModels(data);
    if (data?.length > 0) {
      setSelectedUpscalingModel(data[0]);
    }
  };

  return {
    inpaintingModels,
    selectedInpaintingModel,
    setSelectedInpaintingModel,
    upscalingModels,
    selectedUpscalingModel,
    setSelectedUpscalingModel,
  };
};
