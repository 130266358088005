import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  momentElementsAtom,
  playerMomentsAtom,
  useSelectMomentsPrompt,
} from "../states/momentState";
import { playerStoriesAtom } from "../states/storyState.tsx";
import { useNavigateWithQueryParams } from "./useNavigateWithQueryParams.ts"; // Assume these types are defined according to your data structure

export function useFetchMomentPrompt(): void {
  const navigate = useNavigateWithQueryParams();
  const stories = useRecoilValue(playerStoriesAtom);
  const moments = useRecoilValue(playerMomentsAtom);
  const setMomentElements = useSetRecoilState(momentElementsAtom);
  const selectMomentPrompt = useSelectMomentsPrompt();

  useEffect(() => {
    if (stories.length === 0) {
      navigate("/");
      return;
    }

    const fetchPromptElements = async () => {
      console.log("refreshing moment prompt");
      try {
        const promptElements = await selectMomentPrompt(stories[0].id);
        if (promptElements !== null) {
          setMomentElements(promptElements);
        }
      } catch (error) {
        console.error("Error fetching moment prompts:", error);
      }
    };

    fetchPromptElements();
  }, [stories, moments]);
}
