import { useState } from "react";
import { supabase } from "@/vendor/supabaseClient.ts";
import { GenerateCharacterEmotionVideos } from "./generateImagesModal/GenerateCharacterEmotionVideos.tsx";
import { Tables } from "@/types/database.ts";
import MediaGeneratorBaseModalButton from "@/components/admin/MediaGeneratorBaseModalButton.tsx";
import { useSetRecoilState } from "recoil";
import { popupOpenState } from "@/states/ModalState.ts";

interface ImageGeneratorUploaderButtonProps {
  story: Tables<"blueprint_stories">;
  characterId: string;
  baseFileName: string | undefined | null;
  onUploadComplete: (fileName: string) => void;
  basePrompt: string | null;
  promptModifier: string | null;
  imageReferenceFileName: string | null;
}

export default function MediaGeneratorCharacterModalButton({
  characterId,
  story,
  onUploadComplete,
  basePrompt,
  promptModifier,
  baseFileName,
  imageReferenceFileName,
}: ImageGeneratorUploaderButtonProps) {
  const [selectedImageSupabaseUrl] = useState<string | null>(null);
  const setIsOpen = useSetRecoilState(popupOpenState);

  return (
    <MediaGeneratorBaseModalButton
      story={story}
      basePrompt={basePrompt}
      promptModifier={promptModifier}
      baseFileName={baseFileName}
      imageReferenceFileName={imageReferenceFileName}
      onUploadComplete={onUploadComplete}
    >
      <GenerateCharacterEmotionVideos
        baseImageUrlWithStory={selectedImageSupabaseUrl}
        storyId={story.id}
        onVideosCompleted={async (stagedImage) => {
          const { error } = await supabase.from("blueprint_character_medias").insert({
            media_url: stagedImage.image_url,
            blueprint_character_id: characterId,
            blueprint_story_id: story.id,
          });
          if (!error) {
            onUploadComplete(stagedImage.image_url);
            setIsOpen(false);
          }
        }}
      />
    </MediaGeneratorBaseModalButton>
  );
}
