import React, { useEffect, useRef, useState } from "react";

interface CardProps {
  className?: string;
  children: React.ReactNode;
  isFullWidth?: boolean;
}

export const Card: React.FC<CardProps> = ({ className, children, isFullWidth = false }) => {
  return <div className={`mt-8 ${isFullWidth ? "" : "max-w-sm"}  ${className}`}>{children}</div>;
};

interface CardContentProps {
  children: React.ReactNode;
  className?: string;
}

export const CardContent: React.FC<CardContentProps> = ({ className, children }) => {
  return <div className={`px-6  ${className} `}>{children}</div>;
};

interface CardH1Props {
  label: string;
  className?: string;
  id: string;
}

export const CardH1: React.FC<CardH1Props> = ({ label, className, id }) => {
  return (
    <div id={id} className={`${className} mb-2 mt-8`}>
      <h1 className="text-3xl font-bold mb-4 border-b pt-3 pb-3 border-gray-200">{label}</h1>
    </div>
  );
};

interface CardHeaderProps {
  children: React.ReactNode;
  className?: string;
}

export const CardHeader: React.FC<CardHeaderProps> = ({ children, className }) => {
  return <div className={` ${className}`}>{children}</div>;
};

interface CardImageProps {
  src: string;
  alt?: string;
}

export const CardImage: React.FC<CardImageProps> = ({ src, alt }) => {
  return <img className="w-full" src={src} alt={alt || "Card Image"} />;
};

interface CardVideoProps {
  src: string;
  alt?: string;
  fallbackSrc?: string;
}

interface CardVideoProps {
  src: string;
  alt?: string;
  fallbackSrc?: string;
  className?: string; // New prop for custom styling
}

export const CardVideo: React.FC<CardVideoProps> = ({
  src,
  alt,
  fallbackSrc = "/404.jpg",
  className = "",
}) => {
  const [error, setError] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleError = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    console.error("Video failed to load:", e);
    setError(true);
  };

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    setError(false);
    video.load();
  }, [src]);

  const baseClasses = "w-full h-full object-cover";
  const combinedClasses = `${baseClasses} ${className}`.trim();

  if (error) {
    return <img src={fallbackSrc} alt={alt || "Fallback image"} className={combinedClasses} />;
  }

  return (
    <video
      className={combinedClasses}
      controls
      controlsList="nodownload noremoteplayback noplaybackrate"
      disablePictureInPicture
      muted
      autoPlay
      loop
      preload="auto"
      ref={videoRef}
      onError={handleError}
    >
      <source src={src} type="video/mp4" />
      {alt && <track kind="captions" src="" label={alt} />}
      Your browser does not support the video tag.
    </video>
  );
};

interface CardTitleProps {
  children: string;
}

export const CardTitle: React.FC<CardTitleProps> = ({ children }) => {
  return (
    <div className="px-6 py-4 font-bold text-xl overflow-hidden text-ellipsis whitespace-nowrap">
      {children}
    </div>
  );
};

export const CardText: React.FC<CardTitleProps> = ({ children }) => {
  return (
    <div className="py-1 text-center overflow-hidden text-ellipsis whitespace-nowrap">
      {children}
    </div>
  );
};

interface CardDescriptionProps {
  children: React.ReactNode;
  className?: string;
}

export const CardDescription: React.FC<CardDescriptionProps> = ({ children, className }) => {
  return (
    <p
      className={`px-6 text-gray-700 text-base overflow-hidden text-ellipsis whitespace-nowrap ${className}`}
    >
      {children}
    </p>
  );
};

interface CardFooterProps {
  children: React.ReactNode;
}

export const CardFooter: React.FC<CardFooterProps> = ({ children }) => {
  return <div className="px-6 pt-4 pb-6">{children}</div>;
};
