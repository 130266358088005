import { Tables, TablesInsert, TablesUpdate } from "../types/database.ts";
import { createAtom, useTable } from "./databaseTableState.ts";
import { useCreatePlayerMoments, useSelectStartingBlueprintMoments } from "./momentState.tsx";
import { useRecoilCallback } from "recoil";
import { supabase } from "../vendor/supabaseClient.ts";

export const playerStoriesAtom = createAtom<Tables<"player_stories">[]>("playerStoriesAtom", []);

export const blueprintStoryIdState = createAtom<string | null | undefined>(
  "blueprintStoryIdState",
  null,
);

export const errorAtom = createAtom<string | null>("errorAtom", null);

export const storyBeatsState = createAtom<Tables<"blueprint_beats">[] | null>(
  "storyBeatsState",
  null,
);

export const useCreatePlayerStories = () => {
  const { createItem } = useTable<
    Tables<"player_stories">,
    TablesInsert<"player_stories">,
    TablesUpdate<"player_stories">
  >("player_stories", playerStoriesAtom, errorAtom);
  return createItem;
};

export const useCreatePlayerStoriesAndMoments = () => {
  const createPlayerStory = useCreatePlayerStories();
  const selectStartingBlueprintMoment = useSelectStartingBlueprintMoments();
  const createMoment = useCreatePlayerMoments();
  const selectLastStory = useSelectPlayerStories();

  return useRecoilCallback(
    ({ set }) =>
      async (newPlayerStory: TablesInsert<"player_stories">, momentId?: string) => {
        try {
          const createdStories = await createPlayerStory(newPlayerStory);
          console.log("createdStories", createdStories);
          if (createdStories && createdStories.length > 0) {
            const createdStory = createdStories[0];
            let blueprintMomentId;
            // If momentId is provided, use it. Otherwise, determine it from the starting blueprint moments.
            if (momentId) {
              blueprintMomentId = momentId;
            } else {
              const startingBluePrintMoments = await selectStartingBlueprintMoment(
                createdStory.blueprint_story_id,
              );
              console.log("startingBluePrintMoments", startingBluePrintMoments);
              if (startingBluePrintMoments && startingBluePrintMoments.length > 0) {
                const startingBluePrintMoment = startingBluePrintMoments[0];
                blueprintMomentId = startingBluePrintMoment.id;
              } else {
                window.alert("Starting moment not found");
                return;
              }
            }
            const newMoment: TablesInsert<"player_moments"> = {
              blueprint_moment_id: blueprintMomentId,
              player_story_id: createdStory.id,
              user_id: newPlayerStory.user_id,
            };
            console.log("about to create moment");
            await createMoment(newMoment);
            console.log("moment created");

            // Update the atom with the new story
            const lastStory = await selectLastStory();
            if (lastStory) {
              set(playerStoriesAtom, (prevStories: Tables<"player_stories">[]) => {
                return [...prevStories, lastStory as unknown as Tables<"player_stories">];
              });
            }
          }
        } catch (error) {
          console.error("Error in creating player story and moments: ", error);
          set(errorAtom, "Error in creating player story and moments");
        }
      },
    [createPlayerStory, selectStartingBlueprintMoment, createMoment, selectLastStory],
  );
};

export const useSelectPlayerStories = () => {
  const { selectMostRecentItems } = useTable<
    Tables<"player_stories">,
    TablesInsert<"player_stories">,
    TablesUpdate<"player_stories">
  >("player_stories", playerStoriesAtom, errorAtom);
  return selectMostRecentItems;
};
export const useSelectBlueprintStory = async (storyId: string) => {
  const { data, error } = await supabase
    .from("blueprint_stories")
    .select("*")
    .eq("id", storyId)
    .returns<Tables<"blueprint_stories">[]>()
    .single();

  if (error) {
    console.log("Error fetching story: " + error.message);
  } else {
    return data;
  }
};
