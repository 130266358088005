import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import { BlueprintImageModels } from "@/components/admin/generateImagesModal/hooks/useImageGenModelsDatabase.tsx";
import { SpokableButton } from "@/components/admin/SpokableButton.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import {
  IMAGE_MODEL_CATEGORY_IMAGE_GEN,
  IMAGE_MODEL_CATEGORY_INPAINT,
  IMAGE_MODEL_UPSCALING,
} from "@/constants/constant.ts";

interface ModelFormProps {
  onSubmit: (data: ModelFormValues) => void;
  initialData?: BlueprintImageModels;
  onCancel: () => void;
}

export type ModelFormValues = {
  model_name: string;
  model_id: string;
  version: number;
  prompt_modifier: string;
  category: string;
};

export function ModelForm({ onSubmit, initialData, onCancel }: ModelFormProps) {
  const form = useForm<ModelFormValues>({
    defaultValues: {
      model_name: initialData?.model_name || "",
      model_id: initialData?.model_id || "",
      version: initialData?.version || 1,
      prompt_modifier: initialData?.prompt_modifier || "{prompt}",
      category: initialData?.category || "",
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="model_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Model Name</FormLabel>
                <FormControl>
                  <Input placeholder="Enter model name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="model_id"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Model ID</FormLabel>
                <FormControl>
                  <Input placeholder="Enter model ID" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name="prompt_modifier"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Prompt Modifier</FormLabel>
              <FormControl>
                <Textarea placeholder="Enter prompt modifier template" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="grid grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="category"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Category</FormLabel>
                <FormControl>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select category" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value={IMAGE_MODEL_CATEGORY_IMAGE_GEN}>
                        {IMAGE_MODEL_CATEGORY_IMAGE_GEN}
                      </SelectItem>
                      <SelectItem value={IMAGE_MODEL_CATEGORY_INPAINT}>
                        {IMAGE_MODEL_CATEGORY_INPAINT}
                      </SelectItem>
                      <SelectItem value={IMAGE_MODEL_UPSCALING}>{IMAGE_MODEL_UPSCALING}</SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="version"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Version</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    placeholder="Enter version number"
                    {...field}
                    onChange={(e) => field.onChange(e.target.valueAsNumber)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex justify-end gap-2 pt-4">
          <SpokableButton type="button" color="light" onClick={onCancel}>
            Cancel
          </SpokableButton>
          <SpokableButton type="submit">Save Model</SpokableButton>
        </div>
      </form>
    </Form>
  );
}
