import React, { useEffect, useMemo } from "react";
import { LoadingSpinner } from "../LoadingSpinner";
import AutoResizeTextArea from "../AutoResizeTextArea";
import { LogLine } from "@/types/logline_prompts_generated_types.ts";

export interface GenerateLogLineProps {
  logLine: LogLine | null;
  status: string;
  setLogLine: React.Dispatch<React.SetStateAction<LogLine | null>>;
  generateLogLine: (payload: any) => void;
  selectedIdea: string | null;
  title: string;
}

const GenerateLogLine: React.FC<GenerateLogLineProps> = ({
  logLine,
  status,
  setLogLine,
  generateLogLine,
  selectedIdea,
  title,
}) => {
  const memoizedLogLine = useMemo(() => logLine, [logLine]);

  useEffect(() => {
    if (logLine === null && selectedIdea && status === "") {
      generateLogLine({ idea: selectedIdea || "" });
    }
  }, [logLine, status, generateLogLine, selectedIdea]);

  return (
    <div>
      <div>
        <h1 className="text-2xl font-bold mb-2">{title}</h1>
        <span className="text-sm font-sans"> {status}</span>
      </div>
      {status !== "" && <LoadingSpinner />}
      {memoizedLogLine && (
        <AutoResizeTextArea
          className="max-w-prose"
          value={memoizedLogLine.log_line || ""}
          onChange={(value) => setLogLine({ ...memoizedLogLine, log_line: value })}
        />
      )}
    </div>
  );
};

export default GenerateLogLine;
