import React, { useCallback, useEffect, useRef, useState } from "react";
import { Input } from "@/components/ui/input";
import { SpokableButton } from "@/components/admin/SpokableButton";
import { ArrowPathIcon, PencilIcon, SparklesIcon } from "@heroicons/react/16/solid";
import { BlueprintImageModels } from "@/components/admin/generateImagesModal/hooks/useImageGenModelsDatabase";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Slider } from "@/components/ui/slider";
import { Dialog, DialogContent, DialogDescription, DialogTitle } from "@/components/ui/dialog";
import { useImageModificationModelsDatabase } from "./hooks/useImageModificationModelsDatabase";
import { convertCanvasToBase64, getImageAsBase64 } from "@/utils/imageUtil.ts";

interface ImageSelectorProps {
  src: string;
  onImageSelected?: () => void;
  onInpaint?: (
    imageBase64: string,
    maskBase64: string,
    prompt: string,
    selectedModel: BlueprintImageModels,
  ) => void;
  onUpscale: (imageBase64: string, selectedModel: BlueprintImageModels) => void;
}

const ImageSelector: React.FC<ImageSelectorProps> = ({
  src,
  onImageSelected,
  onInpaint,
  onUpscale,
}) => {
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [brushSize, setBrushSize] = useState(30);
  const [prompt, setPrompt] = useState("");
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
  const [displaySize, setDisplaySize] = useState({ width: 0, height: 0 });

  const {
    inpaintingModels,
    selectedInpaintingModel,
    setSelectedInpaintingModel,
    upscalingModels,
    selectedUpscalingModel,
    setSelectedUpscalingModel,
  } = useImageModificationModelsDatabase();

  const containerRef = useRef<HTMLDivElement>(null);
  const dialogContentRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const isDrawingRef = useRef(false);
  const offscreenCanvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setCanvasSize({ width: img.width, height: img.height });
      const offscreenCanvas = document.createElement("canvas");
      offscreenCanvas.width = img.width;
      offscreenCanvas.height = img.height;
      offscreenCanvasRef.current = offscreenCanvas;
    };
  }, [src]);

  useEffect(() => {
    const updateDisplaySize = () => {
      if (!containerRef.current || !imageRef.current) return;

      const targetContainer = isFullscreen
        ? dialogContentRef.current?.querySelector(".content-container") ?? containerRef.current
        : containerRef.current;

      if (!targetContainer) return;

      const containerWidth = targetContainer.clientWidth - (isFullscreen ? 32 : 16); // Account for padding
      const containerHeight = isFullscreen
        ? targetContainer.clientHeight - 100 // Account for controls
        : containerWidth;

      const imageAspectRatio = canvasSize.width / canvasSize.height;
      let displayWidth = containerWidth;
      let displayHeight = containerWidth / imageAspectRatio;

      if (displayHeight > containerHeight) {
        displayHeight = containerHeight;
        displayWidth = containerHeight * imageAspectRatio;
      }

      setDisplaySize({ width: displayWidth, height: displayHeight });
    };

    const resizeObserver = new ResizeObserver(updateDisplaySize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    if (dialogContentRef.current) {
      resizeObserver.observe(dialogContentRef.current);
    }

    window.addEventListener("resize", updateDisplaySize);
    updateDisplaySize();

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener("resize", updateDisplaySize);
    };
  }, [canvasSize, isFullscreen]);

  const getCanvasCoordinates = useCallback(
    (e: React.MouseEvent<HTMLCanvasElement>) => {
      const canvas = canvasRef.current;
      if (!canvas) return null;

      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      const scaleX = canvasSize.width / rect.width;
      const scaleY = canvasSize.height / rect.height;

      const scaledBrushSize = (brushSize * canvasSize.width) / displaySize.width;
      const buffer = scaledBrushSize * 4;

      const canvasX = x * scaleX;
      const canvasY = y * scaleY;

      // Allow drawing slightly outside the canvas edges
      if (
        canvasX < -buffer ||
        canvasX > canvasSize.width + buffer ||
        canvasY < -buffer ||
        canvasY > canvasSize.height + buffer
      ) {
        return null;
      }

      return {
        x: Math.max(0, Math.min(canvasSize.width, canvasX)),
        y: Math.max(0, Math.min(canvasSize.height, canvasY)),
      };
    },
    [brushSize, canvasSize, displaySize],
  );

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter" && isDrawingMode && prompt && !e.shiftKey) {
        handleInpaint();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [isDrawingMode, prompt]);

  const draw = useCallback(
    (e: React.MouseEvent<HTMLCanvasElement>) => {
      if (!isDrawingRef.current) return;

      const coords = getCanvasCoordinates(e);
      if (!coords || !offscreenCanvasRef.current) return;

      const offscreenCtx = offscreenCanvasRef.current.getContext("2d");
      if (offscreenCtx) {
        offscreenCtx.fillStyle = "white";
        const scaledBrushSize = (brushSize * canvasSize.width) / displaySize.width;

        offscreenCtx.beginPath();
        offscreenCtx.arc(coords.x, coords.y, scaledBrushSize / 2, 0, Math.PI * 2);
        offscreenCtx.fill();
      }

      const displayCtx = canvasRef.current?.getContext("2d");
      if (displayCtx) {
        displayCtx.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
        displayCtx.drawImage(
          offscreenCanvasRef.current,
          0,
          0,
          canvasRef.current!.width,
          canvasRef.current!.height,
        );
      }
    },
    [brushSize, getCanvasCoordinates, canvasSize, displaySize],
  );

  const startDrawing = useCallback(
    (e: React.MouseEvent<HTMLCanvasElement>) => {
      const coords = getCanvasCoordinates(e);
      if (!coords) return;

      isDrawingRef.current = true;
      draw(e);
    },
    [draw, getCanvasCoordinates],
  );

  const stopDrawing = useCallback(() => {
    isDrawingRef.current = false;
  }, []);

  const handleInpaint = async () => {
    if (!offscreenCanvasRef.current || !selectedInpaintingModel) return;
    const base64Mask = await convertCanvasToBase64(
      offscreenCanvasRef.current,
      canvasSize.width,
      canvasSize.height,
    );
    const base64Image = await getImageAsBase64(src);
    onInpaint?.(base64Image, base64Mask, prompt, selectedInpaintingModel);
    setIsDrawingMode(false);
    setIsFullscreen(false);
  };

  const handleUpscale = async () => {
    if (!selectedUpscalingModel) return;
    const base64Image = await getImageAsBase64(src);
    onUpscale?.(base64Image, selectedUpscalingModel);
    setIsDrawingMode(false);
    setIsFullscreen(false);
  };

  const resetDrawing = () => {
    if (offscreenCanvasRef.current) {
      const ctx = offscreenCanvasRef.current.getContext("2d");
      ctx?.clearRect(0, 0, canvasSize.width, canvasSize.height);
    }

    const ctx = canvasRef.current?.getContext("2d");
    ctx?.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
  };

  const toggleDrawingMode = (isFullScreen: boolean) => {
    setIsDrawingMode(!isDrawingMode);
    setIsFullscreen(isFullScreen);
    if (!isDrawingMode) {
      resetDrawing();
    }
  };

  const renderControls = () => (
    <div className="flex flex-col gap-2 p-2">
      <div className="flex gap-2">
        <SpokableButton onClick={onImageSelected}>Save</SpokableButton>
        {!isFullscreen && (
          <SpokableButton
            color="light"
            onClick={() => toggleDrawingMode(true)}
            className={`${isDrawingMode ? "bg-gray-200" : ""}`}
            isIconButton={true}
          >
            <PencilIcon />
          </SpokableButton>
        )}
      </div>

      {isDrawingMode && (
        <>
          <div className="flex items-center justify-end gap-4">
            <span className="text-sm whitespace-nowrap">Upscale</span>
            <div className="w-48">
              <Select
                value={selectedUpscalingModel?.id ?? ""}
                onValueChange={(value) => {
                  const model = upscalingModels.find((m) => m.id === value);
                  if (model) setSelectedUpscalingModel(model);
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select a model" />
                </SelectTrigger>
                <SelectContent>
                  {upscalingModels.map((model) => (
                    <SelectItem key={model.id} value={model.id}>
                      {model.model_name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <SpokableButton
              onClick={() => handleUpscale()}
              isIconButton={true}
              disabled={!selectedUpscalingModel}
              className="flex-shrink-0"
            >
              <SparklesIcon className="h-5 w-5" />
            </SpokableButton>
          </div>
          <div className="flex items-center gap-4">
            <SpokableButton
              color="light"
              onClick={resetDrawing}
              title="Reset mask"
              isIconButton={true}
              className="flex-shrink-0"
            >
              <ArrowPathIcon className="h-5 w-5" />
            </SpokableButton>
            <div className="w-48">
              <Select
                value={selectedInpaintingModel?.id ?? ""}
                onValueChange={(value) => {
                  const model = inpaintingModels.find((m) => m.id === value);
                  if (model) setSelectedInpaintingModel(model);
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select a model" />
                </SelectTrigger>
                <SelectContent>
                  {inpaintingModels.map((model) => (
                    <SelectItem key={model.id} value={model.id}>
                      {model.model_name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="flex items-center gap-2 min-w-[200px]">
              <span className="text-sm whitespace-nowrap">Size: {brushSize}px</span>
              <Slider
                value={[brushSize]}
                onValueChange={(values) => setBrushSize(values[0])}
                min={2}
                max={50}
                step={2}
                className="w-32"
              />
            </div>

            <div className="flex-1 flex gap-2">
              <Input
                type="text"
                placeholder="Enter inpainting prompt..."
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                className="flex-1"
              />
              <SpokableButton
                onClick={handleInpaint}
                disabled={!prompt}
                isIconButton={true}
                className="flex-shrink-0"
              >
                <SparklesIcon className="h-5 w-5" />
              </SpokableButton>
            </div>
          </div>
        </>
      )}
    </div>
  );

  const imageContent = (
    <div className={`relative w-full ${isFullscreen ? "h-full" : "pb-[100%]"}`}>
      <div
        className="absolute inset-0 flex items-center justify-center"
        style={{
          cursor: isDrawingMode ? "crosshair" : "pointer",
          touchAction: "none",
        }}
      >
        <img
          ref={imageRef}
          src={src}
          alt="Selectable image"
          className="max-w-full max-h-full object-contain"
          draggable={false}
        />
        {isDrawingMode && (
          <canvas
            ref={canvasRef}
            width={canvasSize.width}
            height={canvasSize.height}
            style={{
              position: "absolute",
              width: `${displaySize.width}px`,
              height: `${displaySize.height}px`,
            }}
            className="opacity-50"
            onMouseDown={startDrawing}
            onMouseMove={draw}
            onMouseUp={stopDrawing}
            onMouseLeave={stopDrawing}
          />
        )}
      </div>
    </div>
  );
  return (
    <>
      <div className="w-full max-w-md" ref={containerRef}>
        {renderControls()}
        {imageContent}
      </div>
      <Dialog
        open={isFullscreen}
        onOpenChange={(open) => {
          setIsFullscreen(open);
          if (!open) {
            toggleDrawingMode(false);
          }
        }}
      >
        <DialogContent
          ref={dialogContentRef}
          className="max-w-[95vw] w-[95vw] h-[95vh] max-h-[95vh] p-4"
        >
          <DialogTitle className="sr-only">Image Editor</DialogTitle>
          <DialogDescription className="sr-only">
            Image editor interface for manipulating and drawing on images
          </DialogDescription>
          <div className="flex flex-col w-full h-full content-container">
            <div className="flex-none">{renderControls()}</div>
            <div className="flex-1 min-h-0">{imageContent}</div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImageSelector;
