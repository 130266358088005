import { useState } from "react";
import { useRecoilState } from "recoil";
import { useVoicePreview } from "./useGenerateMomentsHooks.ts";
import { modalVoicePreviewState } from "../../../states/ModalState.ts";

export const useGenerateSteps = () => {
  const [isOpen, setIsOpen] = useRecoilState(modalVoicePreviewState);
  const [stepIndex, setStepIndex] = useState<number>(0);

  const {
    data: sound,
    status: soundStatus,
    callApi: voicePreview,
    setData: setSound,
  } = useVoicePreview();

  const handleClose = () => {
    setIsOpen(false);
    setStepIndex(0);
    setSound(null);
  };

  return {
    isOpen,
    setIsOpen,
    stepIndex,
    setStepIndex,
    soundOutput: sound,
    soundStatus,
    voicePreview,
    setSound,

    handleClose,
  };
};
