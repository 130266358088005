import React, { useEffect, useMemo, useRef, useState } from "react";
import { LoadingSpinner } from "../LoadingSpinner";
import DynamicForm from "../DynamicForm";
import { Divider } from "../../catalyst/divider";
import { ArrowPathIcon } from "@heroicons/react/16/solid";
import { supabase } from "../../../vendor/supabaseClient.ts";
import { Tables } from "../../../types/database.ts";
import { SpokableButton } from "../SpokableButton.tsx";
import { getEnvironment } from "../../../utils/envUtil.ts";
import { OnePager } from "@/types/one_pager_prompts_generated_types.ts";
import { CharactersSummary } from "@/types/character_prompts_generated_types.ts";

export interface GenerateCharactersProps {
  charactersSummary: CharactersSummary | null;
  status: string;
  setCharactersSummary: React.Dispatch<React.SetStateAction<CharactersSummary | null>>;
  generateCharacters: (payload: any) => void;
  onePager: OnePager | null;
  audience: string;
  title: string;
}

const GenerateCharacters: React.FC<GenerateCharactersProps> = ({
  charactersSummary,
  status,
  setCharactersSummary,
  generateCharacters,
  onePager,
  audience,
  title,
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const memoizedCharactersSummary = useMemo(() => charactersSummary, [charactersSummary]);
  const [voices, setVoices] = useState<Tables<"blueprint_voices">[] | null>(null);

  async function fetchVoices() {
    const { data, error } = await supabase
      .from("blueprint_voices")
      .select("*")
      .eq("environment", getEnvironment());
    if (error) {
      alert("Error fetching story: " + error.message);
    } else {
      setVoices(data);
    }
  }

  useEffect(() => {
    fetchVoices();
  }, []);

  useEffect(() => {
    if (onePager && charactersSummary === null && status === "" && voices) {
      generateCharacters({
        one_pager: onePager || "",
        voices: voices,
        audience: audience,
      });
    }
  }, [charactersSummary, onePager, generateCharacters, setCharactersSummary, voices]);

  const handleRegenerate = () => {
    if (onePager) {
      setCharactersSummary(null);
    }
  };

  const scrollToCharacterSheet = (index: number) => {
    if (scrollContainerRef.current) {
      const element = scrollContainerRef.current.querySelector(`#character-sheet-${index}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  return (
    <div>
      <div>
        <div className="flex items-center">
          <h1 className="text-2xl font-bold mb-2">{title}</h1>
          <SpokableButton
            isIconButton={true}
            color="light"
            className="ml-2"
            onClick={handleRegenerate}
          >
            <ArrowPathIcon />
          </SpokableButton>
        </div>
        <span className="text-sm font-sans"> {status}</span>
      </div>
      {status !== "" && <LoadingSpinner />}
      {memoizedCharactersSummary && (
        <div className="flex">
          <div className="w-48 pr-4 border-r border-gray-200">
            <ul className="space-y-1">
              {memoizedCharactersSummary.character_sheets.map((sheet, index) => (
                <li key={index}>
                  <button
                    onClick={() => scrollToCharacterSheet(index)}
                    className="w-full text-left text-sm text-zinc-600 hover:text-zinc-800 p-1 focus:text-zinc-800 focus:font-semibold"
                  >
                    {sheet.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex-grow pl-10">
            <div ref={scrollContainerRef} className="mt-1 max-h-[calc(90vh-300px)] overflow-y-auto">
              {memoizedCharactersSummary.character_sheets.map((characterSheet, index) => (
                <div id={`character-sheet-${index}`} key={index}>
                  <DynamicForm
                    data={characterSheet}
                    index={index}
                    arrayKey={"character_sheets"}
                    setData={setCharactersSummary}
                    filterIds={true}
                  />
                  <Divider className="mb-4" />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GenerateCharacters;
