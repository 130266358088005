import React, { ReactElement, useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { SparklesIcon } from "@heroicons/react/16/solid";
import { uploadToSupabaseFileFromUrl } from "../../utils/mediaUtil.ts";
import { sanitizeForURL } from "../../utils/stringUtil.ts";
import { SpokableButton } from "./SpokableButton.tsx";
import { popupOpenState } from "../../states/ModalState.ts";
import { useRecoilState } from "recoil";
import GenerateBaseImage from "./generateImagesModal/GenerateBaseImage.tsx";
import { Tables } from "@/types/database.ts";

interface BaseMediaGeneratorProps {
  children: ReactElement;
  story: Tables<"blueprint_stories">;
  basePrompt: string | null;
  promptModifier: string | null;
  baseFileName: string | undefined | null;
  imageReferenceFileName: string | null;
  onUploadComplete: (fileName: string) => void;
}

export default function MediaGeneratorBaseModalButton({
  children,
  story,
  basePrompt,
  promptModifier,
  baseFileName,
  imageReferenceFileName,
  onUploadComplete,
}: BaseMediaGeneratorProps) {
  const [isOpen, setIsOpen] = useRecoilState(popupOpenState);
  const [selectedImageReplicateUrl, setSelectedImageReplicateUrl] = useState<string | null>(null);
  const [selectedImageSupabaseUrl, setSelectedImageSupabaseUrl] = useState<string | null>(null);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [uploadStatus, setUploadStatus] = useState<string>("");

  const uploadGeneratedImage = async () => {
    if (!selectedImageReplicateUrl) return;
    setUploadStatus("Uploading Image...");
    const fileNameInStorage = `${sanitizeForURL(baseFileName || "generated")}-${Date.now()}.jpg`;
    const url = await uploadToSupabaseFileFromUrl(
      story.id,
      selectedImageReplicateUrl,
      fileNameInStorage,
    );
    setSelectedImageSupabaseUrl(url);
    onUploadComplete(fileNameInStorage);
    setStepIndex(stepIndex + 1);
    setUploadStatus("");
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  return (
    <>
      <SpokableButton
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <SparklesIcon className="mr-2" />
        Generate
      </SpokableButton>

      <Dialog open={isOpen} onClose={closePopup} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <DialogPanel className="w-5/6 max-h-[90vh] transform rounded-md bg-white shadow-xl transition-all flex flex-col">
            <DialogTitle className="font-serif p-6 text-4xl font-bold shrink-0">
              Generate Visuals
            </DialogTitle>
            <div className="flex-1 min-h-0 overflow-y-auto pl-8">
              {stepIndex === 0 && (
                <GenerateBaseImage
                  storyId={story.id}
                  initialPrompt={basePrompt}
                  initialPromptModifier={promptModifier}
                  onImageSelected={(imageUrl) => {
                    setSelectedImageReplicateUrl(imageUrl);
                  }}
                  imageReferenceFileName={imageReferenceFileName}
                  imageModelId={story.blueprint_image_model_id}
                />
              )}
              {stepIndex === 1 &&
                React.cloneElement(children, {
                  baseImageUrlWithStory: selectedImageSupabaseUrl,
                  storyId: story.id,
                })}
            </div>
            <div className="p-6 bg-gray-50 border-t mt-auto shrink-0">
              <div className="flex justify-between items-center">
                {stepIndex === 0 && (
                  <SpokableButton
                    onClick={() => uploadGeneratedImage()}
                    disabled={!selectedImageReplicateUrl}
                  >
                    Use Selected Image
                  </SpokableButton>
                )}
                {stepIndex === 1 && (
                  <SpokableButton color="light" onClick={() => setStepIndex(stepIndex - 1)}>
                    Previous
                  </SpokableButton>
                )}

                {uploadStatus && <p>{uploadStatus}</p>}
                <SpokableButton onClick={closePopup} color="light">
                  Close
                </SpokableButton>
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
}
