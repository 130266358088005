import { PlusCircle, Settings2 } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion.tsx";
import { Badge } from "@/components/ui/badge.tsx";
import {
  BlueprintImageModels,
  BlueprintImageModelSettings,
} from "@/components/admin/generateImagesModal/hooks/useImageGenModelsDatabase.tsx";
import { SpokableButton } from "@/components/admin/SpokableButton.tsx";
import { PencilIcon, TrashIcon } from "@heroicons/react/16/solid";

interface ModelCardProps {
  model: BlueprintImageModels;
  settings: BlueprintImageModelSettings[];
  onEdit: (model: BlueprintImageModels) => void;
  onDelete: (id: string) => void;
  onAddSetting: (modelId: string) => void;
  onEditSetting: (setting: BlueprintImageModelSettings) => void;
  onDeleteSetting: (settingId: string) => void;
}

export function ModelCard({
  model,
  settings,
  onEdit,
  onDelete,
  onAddSetting,
  onEditSetting,
  onDeleteSetting,
}: ModelCardProps) {
  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between items-start">
          <div>
            <CardTitle className="flex items-center gap-2">
              {model.model_name}
              <Badge variant="secondary">v{model.version}</Badge>
            </CardTitle>
            <CardDescription>{model.model_id}</CardDescription>
          </div>
          <div className="flex gap-2">
            <SpokableButton color="light" onClick={() => onEdit(model)} isIconButton={true}>
              <PencilIcon />
            </SpokableButton>
            <SpokableButton onClick={() => onDelete(model.id)} isIconButton={true}>
              <TrashIcon />
            </SpokableButton>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div>
            {model.category && (
              <Badge variant="outline" className="mb-2">
                {model.category}
              </Badge>
            )}
            <p className="text-sm text-muted-foreground">
              Prompt Modifier: {model.prompt_modifier}
            </p>
          </div>

          <Accordion type="single" collapsible>
            <AccordionItem value="settings">
              <AccordionTrigger>
                <div className="flex items-center gap-2">
                  <Settings2 className="h-4 w-4" />
                  Settings
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <div className="space-y-4 pt-4">
                  <div className="flex justify-between items-center">
                    <h3 className="text-sm font-medium">Model Settings</h3>
                    <SpokableButton color="light" onClick={() => onAddSetting(model.id)}>
                      <PlusCircle className="mr-2 h-4 w-4" />
                      Add Setting
                    </SpokableButton>
                  </div>

                  {settings.length > 0 ? (
                    settings.map((setting) => (
                      <Card key={setting.id}>
                        <CardHeader>
                          <div className="flex justify-between items-center">
                            <CardTitle className="text-base">{setting.setting_name}</CardTitle>
                            <div className="flex gap-2">
                              <SpokableButton
                                isIconButton={true}
                                color="light"
                                onClick={() => onEditSetting(setting)}
                              >
                                <PencilIcon />
                              </SpokableButton>
                              <SpokableButton
                                isIconButton={true}
                                onClick={() => onDeleteSetting(setting.id)}
                              >
                                <TrashIcon />
                              </SpokableButton>
                            </div>
                          </div>
                          <CardDescription>{setting.description}</CardDescription>
                        </CardHeader>
                        <CardContent>
                          <div className="space-y-2">
                            <div className="flex gap-2 items-center">
                              <Badge>{setting.setting_type}</Badge>
                            </div>
                            {setting.default_value && (
                              <p className="text-sm text-muted-foreground">
                                Default: {JSON.stringify(setting.default_value)}
                              </p>
                            )}
                          </div>
                        </CardContent>
                      </Card>
                    ))
                  ) : (
                    <div className="text-center p-4 border rounded-lg border-dashed">
                      <p className="text-sm text-muted-foreground">No settings configured yet.</p>
                    </div>
                  )}
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </CardContent>
    </Card>
  );
}
