import { useCallback, useEffect, useState } from "react";
import { Connection, Edge, Node } from "reactflow";
import {
  useFetchMoments,
  useFetchStory,
  useFetchTransitions,
} from "../../../hooks/database/useMoment.ts";
import { Tables, TablesInsert } from "../../../types/database.ts";
import { supabase } from "../../../vendor/supabaseClient.ts";
import { generateEdges, generateNodes } from "./useNodesAndEdges.ts";

export const useMomentGraph = (storyId: string | undefined) => {
  const { fetchStory, story, error: storyError, isLoading: storyLoading } = useFetchStory();
  const {
    fetchMoments,
    moments,
    error: momentsError,
    isLoading: momentsLoading,
  } = useFetchMoments();
  const {
    fetchTransitions,
    transitions,
    error: transitionsError,
    isLoading: transitionsLoading,
  } = useFetchTransitions();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [initialNodes, setInitialNodes] = useState<Node[]>([]);
  const [initialEdges, setInitialEdges] = useState<Edge[]>([]);

  const fetchingErrorMessage = storyError || momentsError || transitionsError;
  const isLoading = storyLoading || momentsLoading || transitionsLoading;

  useEffect(() => {
    if (storyId) {
      fetchStory(storyId);
      fetchMoments(storyId);
      fetchTransitions(storyId);
    }
  }, [storyId]);

  useEffect(() => {
    if (moments.length > 0) {
      setInitialNodes(generateNodes(moments));
    }
  }, [moments]);

  useEffect(() => {
    setInitialEdges(generateEdges(transitions, moments));
  }, [transitions, moments]);

  const updateNode = useCallback((updatedMoment: Tables<"blueprint_moments">) => {
    setInitialNodes((currentNodes) =>
      currentNodes.map((node) => {
        if (node.id === updatedMoment.id) {
          return {
            ...node,
            data: { ...node.data, label: updatedMoment.moment_name, moment: updatedMoment },
          };
        }
        return node;
      }),
    );
  }, []);

  const updateEdge = useCallback((updatedTransition: Tables<"blueprint_moment_transitions">) => {
    setInitialEdges((currentEdges) =>
      currentEdges.map((edge) => {
        if (edge.id === updatedTransition.id) {
          return { ...edge, label: updatedTransition.condition };
        }
        return edge;
      }),
    );
  }, []);

  const onConnect = useCallback(
    async (params: Edge | Connection) => {
      const { source, target } = params;
      if (!source || !target || !storyId) return;

      const transitionsData: TablesInsert<"blueprint_moment_transitions"> = {
        current_moment_id: source,
        next_moment_id: target,
        blueprint_story_id: storyId,
        condition: "",
      };

      const { error } = await supabase.from("blueprint_moment_transitions").insert(transitionsData);

      if (error) {
        setErrorMessage("Error creating transition: " + error.message);
      } else {
        setErrorMessage(null);
        fetchMoments(storyId);
        fetchTransitions(storyId);
      }
    },
    [storyId, fetchMoments, fetchTransitions],
  );

  const onEdgeDelete = useCallback(
    async (edges: Edge[]) => {
      if (edges.length < 1 || !storyId) return;
      const edge = edges[0];
      if (window.confirm("Are you sure you want to delete this transition?")) {
        const { error } = await supabase
          .from("blueprint_moment_transitions")
          .delete()
          .eq("id", edge.id);

        if (error) {
          setErrorMessage("Error deleting transition: " + error.message);
        } else {
          fetchTransitions(storyId);
        }
      }
    },
    [storyId, fetchTransitions],
  );

  const onNodeDelete = useCallback(
    async (nodes: Node[]) => {
      if (nodes.length < 1 || !storyId) return;
      const node = nodes[0];
      if (window.confirm("Are you sure you want to delete this moment? " + node.id)) {
        try {
          // Fetch incoming transitions
          const { data: incomingTransitions, error: incomingError } = await supabase
            .from("blueprint_moment_transitions")
            .select("*")
            .eq("next_moment_id", node.id)
            .eq("blueprint_story_id", storyId);

          if (incomingError) throw incomingError;

          // Fetch outgoing transitions
          const { data: outgoingTransitions, error: outgoingError } = await supabase
            .from("blueprint_moment_transitions")
            .select("*")
            .eq("current_moment_id", node.id)
            .eq("blueprint_story_id", storyId);

          if (outgoingError) throw outgoingError;

          // Create new transitions to maintain story flow
          const newTransitions = incomingTransitions.flatMap((incoming) =>
            outgoingTransitions.map((outgoing) => ({
              current_moment_id: incoming.current_moment_id,
              next_moment_id: outgoing.next_moment_id,
              condition: `(${incoming.condition}) AND (${outgoing.condition})`,
              blueprint_story_id: storyId,
            })),
          );

          // Insert new transitions
          if (newTransitions.length > 0) {
            const { error: insertError } = await supabase
              .from("blueprint_moment_transitions")
              .insert(newTransitions);

            if (insertError) throw insertError;
          }

          // Delete the moment (cascade will handle related transitions)
          const { error } = await supabase.from("blueprint_moments").delete().eq("id", node.id);

          if (error) {
            setErrorMessage("Error deleting moment: " + error.message);
          } else {
            refreshData();
          }
        } catch (error) {
          setErrorMessage("Error deleting moment: " + (error as Error).message);
        }
      } else {
        refreshData();
      }
    },
    [storyId, fetchMoments, setErrorMessage],
  );

  const refreshData = useCallback(() => {
    if (storyId) {
      fetchMoments(storyId);
      fetchTransitions(storyId);
    }
  }, [storyId, fetchMoments, fetchTransitions]);

  return {
    story,
    moments,
    transitions,
    initialNodes,
    initialEdges,
    onConnect,
    onEdgeDelete,
    onNodeDelete,
    updateNode,
    updateEdge,
    errorMessage,
    isLoading,
    fetchingErrorMessage,
    refreshData,
  };
};
