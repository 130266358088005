import React, { memo } from "react";
import { Handle, Position } from "reactflow";
import { Tables } from "../../../types/database";
import { BoltIcon } from "@heroicons/react/16/solid";
import { MOMENT_TYPE_INTERACTIVE } from "../../../constants/constant";
import { bestFontColor } from "../../../utils/color";
import { useFetchCharacterImageUrl } from "../../../hooks/database/useMoment.ts";

interface CustomNodeProps {
  data: {
    label: string;
    moment_type: string;
    moment: Tables<"blueprint_moments">;
    borderColor: string;
  };
  selected: boolean;
}

const CustomMomentFlowNode: React.FC<CustomNodeProps> = memo(({ data, selected }) => {
  const { label, moment } = data;
  const character_id = moment.blueprint_character_id;
  const backgroundColor = data.borderColor || "white";
  const { imageUrl } = useFetchCharacterImageUrl(
    data.moment.blueprint_story_id,
    data.moment.blueprint_character_id,
  );
  return (
    <div
      className={`m-0 px-4 py-2 ${selected ? "border-black" : "border-none"}`}
      style={{
        backgroundColor,
        color: bestFontColor(backgroundColor),
        borderWidth: "4px",
        borderStyle: `${selected ? "solid" : "none"}`,
        width: "500px",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div className="flex" style={{ overflow: "hidden" }}>
        <div className="rounded-full w-12 h-12 flex justify-center items-center bg-gray-100">
          {character_id && (
            <img src={imageUrl} alt="Character" className="rounded-full w-10 h-10" />
          )}
        </div>
        <div
          className="ml-2"
          style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
        >
          <div className="text-md" title={label}>
            {label}
          </div>
          {moment.moment_type === MOMENT_TYPE_INTERACTIVE && (
            <BoltIcon className="h-5 w-5 text-white" aria-hidden="true" />
          )}
        </div>
      </div>
      <Handle type="target" position={Position.Top} className="w-32 !bg-black" />
      <Handle type="source" position={Position.Bottom} className="w-32 !bg-black" />
    </div>
  );
});

CustomMomentFlowNode.displayName = "CustomMomentFlowNode";

export default CustomMomentFlowNode;
