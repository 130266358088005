import { useFieldArray, useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Trash2 } from "lucide-react";
import { SpokableButton } from "@/components/admin/SpokableButton";
import { BlueprintImageModelSettings } from "@/components/admin/generateImagesModal/hooks/useImageGenModelsDatabase.tsx";

export type SettingFormValues = {
  setting_name: string;
  setting_key: string;
  setting_type: string;
  description: string;
  is_required: boolean;
  is_disabled: boolean;
  default_value?: any;
  min_value?: number | null;
  max_value?: number | null;
  step_value?: number | null;
  unit?: string | null;
  placeholder?: string | null;
  max_length?: number | null;
  options: {
    label: string;
    value: string;
    description: string | null;
  }[];
};

interface SettingFormProps {
  onSubmit: (data: SettingFormValues) => void;
  onCancel: () => void;
  initialData?: BlueprintImageModelSettings & {
    blueprint_image_model_setting_options?: {
      label: string;
      value: string;
      description: string | null;
    }[];
  };
}

export function SettingForm({ onSubmit, onCancel, initialData }: SettingFormProps) {
  const form = useForm<SettingFormValues>({
    defaultValues: {
      setting_name: initialData?.setting_name || "",
      setting_key: initialData?.setting_key || "",
      setting_type: initialData?.setting_type || "input",
      description: initialData?.description || "",
      default_value: initialData?.default_value,
      min_value: initialData?.min_value,
      max_value: initialData?.max_value,
      step_value: initialData?.step_value,
      unit: initialData?.unit,
      placeholder: initialData?.placeholder,
      max_length: initialData?.max_length,
      options: initialData?.blueprint_image_model_setting_options || [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "options",
  });

  const settingType = form.watch("setting_type");

  const handleSubmit = async (data: SettingFormValues) => {
    // Only include options if the setting type is "select"
    const formattedData = {
      ...data,
      options: settingType === "select" ? data.options : [],
    };
    onSubmit(formattedData);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
        {/* Existing form fields */}
        <div className="grid grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="setting_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Setting Name</FormLabel>
                <FormControl>
                  <Input placeholder="Enter setting name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="setting_key"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Setting Key</FormLabel>
                <FormControl>
                  <Input placeholder="Enter setting key" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name="setting_type"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Setting Type</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select type" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="number">Number</SelectItem>
                  <SelectItem value="select">Select</SelectItem>
                  <SelectItem value="slider">Slider</SelectItem>
                  <SelectItem value="color">Color</SelectItem>
                  <SelectItem value="switch">Switch</SelectItem>
                  <SelectItem value="input">Input</SelectItem>
                  <SelectItem value="textarea">Textarea</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Enter setting description"
                  {...field}
                  value={field.value || ""}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* Options section - only shown for select type */}
        {settingType === "select" && (
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <FormLabel>Options</FormLabel>
              <Button
                type="button"
                variant="outline"
                size="sm"
                onClick={() => append({ label: "", value: "", description: "" })}
              >
                Add Option
              </Button>
            </div>

            {fields.map((field, index) => (
              <div key={field.id} className="grid grid-cols-12 gap-4 items-start">
                <div className="col-span-6">
                  <FormField
                    control={form.control}
                    name={`options.${index}.label`}
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input placeholder="Option Label" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="col-span-5">
                  <FormField
                    control={form.control}
                    name={`options.${index}.value`}
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input placeholder="Value" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="col-span-1">
                  <Button type="button" variant="ghost" size="icon" onClick={() => remove(index)}>
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="flex justify-end gap-2 pt-4">
          <SpokableButton type="button" color="light" onClick={onCancel}>
            Cancel
          </SpokableButton>
          <SpokableButton type="submit">
            {initialData ? "Update Setting" : "Add Setting"}
          </SpokableButton>
        </div>
      </form>
    </Form>
  );
}
