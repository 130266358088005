import { useState } from "react";
import { useRecoilState } from "recoil";
import { useGenerateMoments } from "./useGenerateMomentsHooks.ts";
import { modalMomentsGenerateState } from "../../../states/ModalState.ts";

export const useGenerateSteps = () => {
  const [isOpen, setIsOpen] = useRecoilState(modalMomentsGenerateState);
  const [stepIndex, setStepIndex] = useState<number>(0);

  const {
    data: moments,
    status: momentStatus,
    callApi: generateMoments,
    setData: setMoments,
  } = useGenerateMoments();

  const handleClose = () => {
    setIsOpen(false);
    setStepIndex(0);
    setMoments(null);
  };

  return {
    isOpen,
    setIsOpen,
    stepIndex,
    setStepIndex,
    moments,
    momentStatus,
    generateMoments,
    setMoments,

    handleClose,
  };
};
