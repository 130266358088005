import React from "react";
import TransitionDetail from "../../../pages/admin/TransitionDetail.tsx";
import MomentDetail from "../../../pages/admin/MomentDetail.tsx";
import { Tables } from "../../../types/database.ts";
import MomentList from "../../../pages/admin/MomentList.tsx";
import CreateMomentButton from "../CRUD/CreateMomentButton.tsx";

interface TransitionSidePanelProps {
  selectedMomentId: string | null | undefined;
  selectedMomentTransitionId: string | null | undefined;
  onUpdateMoment?: (updatedMoment: Tables<"blueprint_moments">) => void;
  onUpdateMomentTransition?: (updatedTransition: Tables<"blueprint_moment_transitions">) => void;
  story: Tables<"blueprint_stories">;
  moments: Tables<"blueprint_moments">[];
  onRefreshMoments: () => void;
}

const TransitionSidePanel: React.FC<TransitionSidePanelProps> = ({
  selectedMomentId,
  selectedMomentTransitionId,
  onUpdateMoment,
  onUpdateMomentTransition,
  story,
  moments,
  onRefreshMoments,
}) => {
  if (selectedMomentId) {
    return (
      <div className="w-full h-full bg-gray-100">
        <div className="h-full overflow-y-auto">
          <MomentDetail momentId={selectedMomentId} onMomentUpdate={onUpdateMoment} />
        </div>
      </div>
    );
  } else if (selectedMomentTransitionId) {
    return (
      <div className="w-full h-full bg-gray-100 p-4">
        <TransitionDetail
          transitionId={selectedMomentTransitionId}
          onTransitionUpdate={onUpdateMomentTransition}
        />
      </div>
    );
  } else {
    return (
      <div className="w-full h-full bg-gray-100 p-4">
        <div className="h-full overflow-y-auto">
          <div className="m-6">No moment or transition selected.</div>
          <div className="ml-6 ">
            <CreateMomentButton
              story={story}
              moments={moments}
              onMomentCreated={onRefreshMoments}
            />
          </div>
          <MomentList onMomentUpdateOrDeleted={onRefreshMoments} story={story} moments={moments} />
        </div>
      </div>
    );
  }
};

export default TransitionSidePanel;
