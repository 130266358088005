import React from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

interface OutputSchemaField {
  field_name: string;
  description: string;
}

interface OutputSchema {
  schema_name: string;
  fields: OutputSchemaField[];
}

interface SchemaResponseViewerProps {
  schema: OutputSchema;
  response: string;
}

type GenericObject = Record<string, unknown>;

const ResponseViewer: React.FC<SchemaResponseViewerProps> = ({ schema, response }) => {
  const responseData = JSON.parse(response) as GenericObject;

  const findFirstArray = (obj: GenericObject): GenericObject[] | null => {
    for (const key in obj) {
      const value = obj[key];
      if (Array.isArray(value) && value.length > 0) {
        return value as GenericObject[];
      }
    }
    return null;
  };

  const getDisplayData = (): GenericObject | GenericObject[] => {
    // First try to use the response data directly
    const hasDirectFields = schema.fields.some(
      (field) => responseData[field.field_name] !== undefined,
    );

    if (hasDirectFields) {
      return responseData;
    }

    // If direct approach fails, look for first array
    const firstArray = findFirstArray(responseData);
    if (firstArray && firstArray.length > 0) {
      return firstArray; // Return the whole array instead of just first item
    }

    return {};
  };

  const displayData = getDisplayData();

  const renderArrayItem = (item: unknown): React.ReactNode => {
    if (item !== null && typeof item === "object") {
      return (
        <div className="space-y-1">
          {Object.entries(item as GenericObject).map(([key, value]) => (
            <div key={key}>
              <strong>{key}: </strong>
              {String(value)}
            </div>
          ))}
        </div>
      );
    }
    return String(item);
  };

  const renderValue = (value: unknown): React.ReactNode => {
    if (Array.isArray(value)) {
      return (
        <ol className="list-decimal ml-6">
          {value.map((item, index) => (
            <li key={index} className="mb-2">
              {renderArrayItem(item)}
            </li>
          ))}
        </ol>
      );
    }

    if (typeof value === "boolean") {
      return <span>{value ? "Yes" : "No"}</span>;
    }

    if (value !== null && typeof value === "object") {
      return (
        <div className="space-y-1">
          {Object.entries(value as GenericObject).map(([key, val]) => (
            <div key={key}>
              <strong>{key}: </strong>
              {String(val)}
            </div>
          ))}
        </div>
      );
    }

    return <span>{String(value)}</span>;
  };

  const renderSingleObject = (data: GenericObject) => (
    <div className="space-y-4">
      {schema.fields.map((field) => (
        <div key={field.field_name} className="mb-4 text-base/6 sm:text-sm/6">
          <h3 className="text-sm text-gray-500 font-medium mb-2">
            {field.field_name.charAt(0).toUpperCase() + field.field_name.slice(1)}
          </h3>
          {data[field.field_name] !== undefined ? (
            renderValue(data[field.field_name])
          ) : (
            <span className="text-gray-500 italic">No data</span>
          )}
        </div>
      ))}
    </div>
  );

  const renderArrayOfObjects = (data: GenericObject[]) => {
    const firstField = schema.fields[0].field_name;

    return (
      <Accordion type="single" collapsible className="w-full">
        {data.map((item, index) => (
          <AccordionItem key={index} value={`item-${index}`}>
            <AccordionTrigger className="hover:no-underline">
              <div className="flex items-center gap-2">
                <span className="font-medium">
                  {firstField}: {String(item[firstField])}
                </span>
              </div>
            </AccordionTrigger>
            <AccordionContent>{renderSingleObject(item)}</AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    );
  };

  return (
    <div className="w-full">
      {Array.isArray(displayData)
        ? renderArrayOfObjects(displayData)
        : renderSingleObject(displayData as GenericObject)}
    </div>
  );
};

export default ResponseViewer;
