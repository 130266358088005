import { useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/catalyst/table.tsx";
import { LOCALE_ENGLISH } from "@/constants/constant.ts";
import { formatDateString } from "@/utils/date.ts";
import { Card, CardContent } from "@/components/admin/Card.tsx";
import { PromptManagementSideNavLayout } from "@/components/admin/PromptManagementSideNavLayout.tsx";
import { SpokableButton } from "@/components/admin/SpokableButton.tsx";
import { useEvalsListDatabase } from "@/components/admin/promptManagement/database/useEvalsListDatabase.ts";

function PromptEvalsList() {
  const { promptId } = useParams();
  const navigate = useNavigate();
  const { evals, prompt, errorMessage, createEval } = useEvalsListDatabase(promptId);

  const handleCreateEval = async () => {
    if (!promptId || !prompt) return;

    const newEval = await createEval();
    if (newEval) {
      navigate(`/admin/prompts/${promptId}/evals/${newEval.id}`);
    }
  };

  return (
    <PromptManagementSideNavLayout>
      <div className="top-section sticky top-0 backdrop-blur-xl z-50 p-6">
        {errorMessage && (
          <div className="rounded bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 mb-4 cursor-pointer">
            {errorMessage}
          </div>
        )}
        <h1 className="text-5xl font-bold mb-12">
          Evals {prompt ? "for " + prompt.prompt_name : ""}
        </h1>
        <SpokableButton onClick={handleCreateEval}>New</SpokableButton>
      </div>
      <Card isFullWidth={true}>
        <CardContent>
          <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
            <TableHead>
              <TableRow>
                <TableHeader>Name</TableHeader>
                <TableHeader>last updated at</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {evals.map((promptEval) => (
                <TableRow
                  key={promptEval.id}
                  onClick={() => navigate(`/admin/prompts/${promptId}/evals/${promptEval.id}`)}
                  className="cursor-pointer hover:bg-zinc-950/5"
                >
                  <TableCell>{promptEval.eval_name}</TableCell>
                  <TableCell>{formatDateString(promptEval.updated_at, LOCALE_ENGLISH)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </PromptManagementSideNavLayout>
  );
}

export default PromptEvalsList;
