import React, { useEffect, useState } from "react";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { Info } from "lucide-react";
import ImageGenerationStyleSelector from "../ImageGenerationStyleSelector.tsx";
import AutoResizeTextArea from "../AutoResizeTextArea.tsx";
import { downloadImageFromSupabase } from "@/utils/mediaUtil.ts";
import AnimatedStatus from "../AnimatedStatus.tsx";
import FormField from "@/components/admin/FormField.tsx";
import ImageModelSettingsUI from "@/components/admin/generateImagesModal/ImageModelUISettings.tsx";
import useMediaGenerationWebsocket, {
  MediaMessageType,
} from "@/components/admin/generateImagesModal/hooks/useMediaGenerationWebsocket.ts";
import { DTOErrorMessage } from "@/types/fastApiPromptManagerTypes.ts";
import { WebSocketMessage } from "@/hooks/useBaseWebSocket.ts";
import {
  DTOImageGenerationCall,
  DTOImageInpaintCall,
  DTOImageResult,
  DTOImageUpscaleCall,
} from "@/types/fastApiMediaGenerationTypes.ts";
import { BlueprintImageModels } from "@/components/admin/generateImagesModal/hooks/useImageGenModelsDatabase.tsx";
import ImageSelector from "@/components/admin/generateImagesModal/ImageSelector.tsx";
import { fileToBase64 } from "@/utils/imageUtil.ts";

interface GenerateBaseImageProps {
  imageModelId: string | null;
  initialPrompt: string | null;
  initialPromptModifier: string | null;
  onImageSelected: (
    imageUrl: string,
    selectedModelId: string | null,
    prompt: string,
    style: string,
  ) => void;
  imageReferenceFileName?: string | null;
  storyId: string;
}

const GenerateBaseImage: React.FC<GenerateBaseImageProps> = ({
  initialPrompt,
  initialPromptModifier,
  onImageSelected,
  storyId,
  imageReferenceFileName = null,
  imageModelId,
}) => {
  const [prompt, setPrompt] = useState(initialPrompt || "");
  const [storyStyle, setStoryStyle] = useState(initialPromptModifier || "");
  const [imageStatus, setImageStatus] = useState<string | null>(null);
  const [images, setImages] = useState<string[] | null | undefined>([]);
  const [selectedModelId, setSelectedModelId] = useState<string | null>(imageModelId);
  const [referenceImageBlob, setReferenceImageBlob] = useState<Blob | null>(null);
  const [isLoadingReference, setIsLoadingReference] = useState(false);

  const ws = useMediaGenerationWebsocket();

  useEffect(() => {
    const loadReferenceImage = async () => {
      if (imageReferenceFileName) {
        try {
          setIsLoadingReference(true);
          const imageUrl = `${storyId}/${imageReferenceFileName}`;
          const image = await downloadImageFromSupabase(imageUrl);
          if (image) {
            setReferenceImageBlob(image);
          }
        } catch (error) {
          console.error("Error loading reference image:", error);
        } finally {
          setIsLoadingReference(false);
        }
      }
    };

    loadReferenceImage();
  }, [imageReferenceFileName, storyId]);

  useEffect(() => {
    ws.onReceive(MediaMessageType.GENERATE_IMAGE, (message: WebSocketMessage) => {
      const imageResult: DTOImageResult = message.messageObject;
      if (message.messageObject.status === "succeeded") {
        setImages(imageResult.output);
        setImageStatus(null);
      } else {
        setImageStatus(imageResult.status + " " + Math.round(imageResult.progress * 100) + "%");
      }
    });

    ws.onReceive(MediaMessageType.IMAGE_ERROR, (message: WebSocketMessage) => {
      const error: DTOErrorMessage = message.messageObject;
      setImageStatus(error.message);
    });
  }, [ws]);

  const handleGenerate = async (
    model: BlueprintImageModels,
    modelSettings: Record<string, any>,
  ) => {
    try {
      setSelectedModelId(model.id);
      setImageStatus("Generation starting...");
      const baseParams: DTOImageGenerationCall = {
        prompt: storyStyle + " / " + model.prompt_modifier.replace("{prompt}", prompt),
        model: model.model_id,
      };

      if (imageReferenceFileName && referenceImageBlob) {
        const file = new File([referenceImageBlob], imageReferenceFileName, {
          type: "image/jpeg",
        });
        const base64Content = await fileToBase64(file);

        Object.assign(baseParams, {
          image_ref_file_name: imageReferenceFileName,
          image_ref_base_64: base64Content,
        });
      }

      ws.send(MediaMessageType.GENERATE_IMAGE, {
        ...baseParams,
        ...modelSettings,
      });
    } catch (error) {
      console.error("Error in handleGenerate:", error);
      throw error;
    }
  };

  const handleInpaint = async (
    imageBase64: string,
    maskBase64: string,
    prompt: string,
    selectedModel: BlueprintImageModels,
  ) => {
    const baseParams: DTOImageInpaintCall = {
      model: selectedModel.model_id,
      prompt: prompt,
      image_base_64: imageBase64,
      image_mask_base_64: maskBase64,
    };
    setImageStatus("Inpainting starting...");
    ws.send(MediaMessageType.INPAINT_IMAGE, baseParams);
  };

  const handleUpscale = async (imageBase64: string, selectedModel: BlueprintImageModels) => {
    const baseParams: DTOImageUpscaleCall = {
      model: selectedModel.model_id,
      image_base_64: imageBase64,
    };
    setImageStatus("Upscaling starting...");
    ws.send(MediaMessageType.UPSCALE_IMAGE, baseParams);
  };

  const handleImageSave = (image: string) => {
    onImageSelected(image, selectedModelId, prompt, storyStyle);
  };

  return (
    <div className="flex-grow overflow-y-auto w-full">
      <div className="flex flex-row space-x-8 space-y-0">
        <div className="w-1/3">
          <FormField label="Generate...">
            <AutoResizeTextArea
              className="mb-4"
              value={prompt}
              onChange={(e) => setPrompt(e)}
              minNumberOfRows={1}
            />
          </FormField>
          <FormField label="...in this style">
            <ImageGenerationStyleSelector
              promptStyle={storyStyle}
              onPromptStyleSelected={(promptStyle) => {
                setStoryStyle(promptStyle);
              }}
            />
          </FormField>
        </div>
        <div className="w-64 flex flex-col space-y-2">
          <div className="flex items-center justify-between">
            <label className="block text-zinc-500">Settings</label>
            {imageReferenceFileName && (
              <HoverCard>
                <HoverCardTrigger>
                  <div className="flex items-center text-zinc-500 hover:text-zinc-400 cursor-help">
                    <Info className="w-4 h-4 mr-1" />
                  </div>
                </HoverCardTrigger>
                <HoverCardContent className="w-80">
                  {isLoadingReference ? (
                    <div className="text-center py-4">Loading reference image...</div>
                  ) : referenceImageBlob ? (
                    <img
                      src={URL.createObjectURL(referenceImageBlob)}
                      alt="Reference"
                      className="w-full h-auto rounded-md"
                    />
                  ) : (
                    <div className="text-center py-4 text-zinc-500">
                      No reference image available
                    </div>
                  )}
                </HoverCardContent>
              </HoverCard>
            )}
          </div>

          <ImageModelSettingsUI
            onSubmit={(model, settings) => handleGenerate(model, settings)}
            imageModelId={imageModelId}
          />

          <AnimatedStatus status={imageStatus} />
        </div>
        <div className="flex-1 min-h-0">
          {images && (
            <div className="grid grid-cols-1 gap-4">
              {images.map((image, index) => (
                <ImageSelector
                  key={index}
                  src={image}
                  onImageSelected={() => handleImageSave(image)}
                  onInpaint={(imageBase64, maskBase64, prompt, selectedModel) =>
                    handleInpaint(imageBase64, maskBase64, prompt, selectedModel)
                  }
                  onUpscale={(imageBase64, selectedModel) =>
                    handleUpscale(imageBase64, selectedModel)
                  }
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GenerateBaseImage;
