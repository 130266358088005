import {
  IMAGE_TYPE_EMOTION_VIDEO,
  IMAGE_TYPE_ORIGINAL,
  VISUAL_TYPE_IMAGE,
} from "@/constants/constant.ts";
import {
  downloadImageFromSupabase,
  generateMediaFileName,
  getCustomEmotionVideoFileName,
  getImageNameWithoutStoryIdAndFileExtension,
  uploadToSupabaseFileFromUrl,
} from "@/utils/mediaUtil.ts";
import { useState } from "react";
import { supabase } from "@/vendor/supabaseClient.ts";
import { Tables } from "@/types/database.ts";
import { BluePrintImageToBeSaved } from "@/components/admin/generateImagesModal/GenerateCharacterEmotionVideos.tsx";
import { DTOVideoResult } from "@/types/fastApiMediaGenerationTypes.ts";

export async function downloadFile(url: string, type = VISUAL_TYPE_IMAGE) {
  const blob = await downloadImageFromSupabase(url);
  if (!blob) {
    console.log("image not found ", url);
    return;
  }
  return new File([blob], url, {
    type: `${type === VISUAL_TYPE_IMAGE ? "image/jpeg" : "video/webm"}`,
  });
}

export const useUploadVideos = (storyId: string) => {
  const [status, setStatus] = useState<string>("");
  const uploadVideos = async (
    baseImageUrlWithStory: string | null,
    videos: DTOVideoResult[],
    videoType: string = "",
  ): Promise<BluePrintImageToBeSaved | undefined> => {
    if (!baseImageUrlWithStory || !videos.length) return;
    const baseObjectUrl = getImageNameWithoutStoryIdAndFileExtension(baseImageUrlWithStory);
    if (!baseObjectUrl) return;

    try {
      setStatus("Uploading...");

      // base image that will be saved in the DB
      const returnedImageObject: BluePrintImageToBeSaved = {
        image_url: generateMediaFileName(baseObjectUrl, IMAGE_TYPE_ORIGINAL),
      };

      await Promise.all([
        videos.map(({ video_url, key }) =>
          uploadToSupabaseFileFromUrl(
            storyId,
            video_url,
            videoType == IMAGE_TYPE_EMOTION_VIDEO // ugly as fuck
              ? generateMediaFileName(baseObjectUrl, videoType, key, true)
              : generateMediaFileName(baseObjectUrl, key, "", true),
          ),
        ),
      ]);

      return returnedImageObject;
    } catch (error) {
      console.error("Error uploading variations:", error);
    } finally {
      setStatus("");
    }
  };

  return {
    uploadVideos,
    status,
  };
};

export async function getCustomEmotionDrivingVideos(
  selectedDrivingCollection: Tables<"blueprint_driving_video_collections"> | null,
  checkedEmotions: string[],
  storyId: string,
) {
  if (!selectedDrivingCollection) return [];
  const { data, error } = await supabase
    .from("blueprint_driving_videos")
    .select("*")
    .eq("blueprint_driving_video_collection_id", selectedDrivingCollection.id);

  if (error) {
    console.log("Error fetching videos: " + error.message);
    return;
  }

  const filteredDrivingVideos = data?.filter(
    (video) => video.video_key && checkedEmotions.includes(video.video_key),
  );

  if (!filteredDrivingVideos?.length) return [];

  const files: File[] = [];

  try {
    const downloadPromises = filteredDrivingVideos.map(async (video) => {
      const fileName = getCustomEmotionVideoFileName(
        selectedDrivingCollection.collection_name,
        video.video_key,
      );

      const file = await downloadFile(storyId + "/" + fileName);
      if (file) {
        files.push(file);
      }
    });

    // Wait for all downloads to complete
    await Promise.all(downloadPromises);

    return files;
  } catch (err) {
    console.error("Error downloading files:", err);
    return files; // Return any successfully downloaded files
  }
}
