import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import {
  BlueprintImageModels,
  BlueprintImageModelSettings,
  useImageGenModelsDatabase,
} from "@/components/admin/generateImagesModal/hooks/useImageGenModelsDatabase.tsx";
import { SpokableButton } from "@/components/admin/SpokableButton";
import { PencilIcon, SparklesIcon } from "@heroicons/react/16/solid";
import ModalButton from "@/components/admin/ModalButton";
import { ImageModelCRUD } from "@/components/admin/generateImagesModal/ImageModelCRUD/ImageModelCRUD";
import { getDefaultValueForType } from "@/components/admin/generateImagesModal/ImageModelCRUD/imageModelUtil.ts";

interface Props {
  imageModelId: string | null;
  onSubmit: (model: BlueprintImageModels, settings: Record<string, any>) => void;
}

export default function ImageModelSettingsUI({ imageModelId, onSubmit }: Props) {
  const {
    models,
    selectedModel,
    setSelectedModel,
    getSettingsForModel,
    getOptionsForSetting,
    updateSetting,
    getCurrentValues,
    loading,
    error,
  } = useImageGenModelsDatabase(imageModelId);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const settings = selectedModel ? getSettingsForModel(selectedModel.id) : [];

  const handleSubmit = () => {
    if (selectedModel) {
      onSubmit(selectedModel, getCurrentValues());
    }
  };

  const renderSetting = (setting: BlueprintImageModelSettings) => {
    const defaultValue = getDefaultValueForType(setting);
    const options = getOptionsForSetting(setting.id);
    const currentValues = getCurrentValues();
    const currentValue = currentValues[setting.setting_key];

    switch (setting.setting_type) {
      case "number":
        return (
          <div className="space-y-2">
            <Label htmlFor={setting.setting_key}>
              {setting.setting_name}
              {defaultValue.unit && (
                <span className="ml-1 text-sm text-muted-foreground">({defaultValue.unit})</span>
              )}
            </Label>
            <Input
              id={setting.setting_key}
              type="number"
              min={defaultValue.min}
              max={defaultValue.max}
              step={defaultValue.step}
              value={currentValue ?? defaultValue.value}
              onChange={(e) => updateSetting(setting.setting_key, parseFloat(e.target.value))}
            />
          </div>
        );

      case "select":
        return (
          <div className="space-y-2">
            <Label htmlFor={setting.setting_key}>{setting.setting_name}</Label>
            <Select
              value={String(currentValue ?? defaultValue.value)}
              onValueChange={(value) => updateSetting(setting.setting_key, value)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select option" />
              </SelectTrigger>
              <SelectContent>
                {options.map((option) => (
                  <SelectItem key={option.id} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        );

      case "switch":
        return (
          <div className="flex items-center justify-between">
            <Label htmlFor={setting.setting_key}>{setting.setting_name}</Label>
            <Switch
              id={setting.setting_key}
              checked={Boolean(currentValue ?? defaultValue.value)}
              onCheckedChange={(checked) => updateSetting(setting.setting_key, checked)}
            />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <Card className="w-full">
      <CardContent className="space-y-6">
        <div className="space-y-2">
          <div className="flex mt-4 justify-between">
            <Label htmlFor="model-select" className="pt-4">
              Model{" "}
            </Label>
            <ModalButton isIconButton={true} buttonStyleLight={true} icon={<PencilIcon />}>
              <ImageModelCRUD />
            </ModalButton>
          </div>
          <Select
            value={selectedModel?.id ?? ""}
            onValueChange={(value) => {
              const model = models.find((m) => m.id === value);
              if (model) setSelectedModel(model);
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select a model" />
            </SelectTrigger>
            <SelectContent>
              {models.map((model) => (
                <SelectItem key={model.id} value={model.id}>
                  {model.model_name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        {settings.length > 0 && (
          <div className="space-y-4">
            {settings.map((setting) => (
              <div key={setting.id}>{renderSetting(setting)}</div>
            ))}
          </div>
        )}

        <SpokableButton className="w-full" onClick={handleSubmit} disabled={!selectedModel}>
          <SparklesIcon className="mr-2" />
          Generate
        </SpokableButton>
      </CardContent>
    </Card>
  );
}
