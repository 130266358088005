import React, { useState } from "react";
import { Tables, TablesInsert } from "../../../types/database.ts";
import { supabase } from "../../../vendor/supabaseClient.ts";
import { SpokableButton } from "../SpokableButton.tsx";
import { getEnvironment } from "../../../utils/envUtil.ts";

interface CreateMomentProps {
  story: Tables<"blueprint_stories">;
  moments: Tables<"blueprint_moments">[];
  onMomentCreated?: (newMoment: Tables<"blueprint_moments">) => void;
  transitionFromMomentId?: string;
}

const CreateMoment: React.FC<CreateMomentProps> = ({
  story,
  moments,
  onMomentCreated,
  transitionFromMomentId,
}) => {
  const [error, setErrorMessage] = useState<string | null>(null);

  async function createCharacter(): Promise<Tables<"blueprint_characters"> | null> {
    const { data: voice, error: voiceError } = await supabase
      .from("blueprint_voices")
      .select("*")
      .limit(1)
      .eq("environment", getEnvironment())
      .single();

    if (!voice) return null;

    const characterData: TablesInsert<"blueprint_characters"> = {
      name: "Default character",
      voice_id: voice.id,
      blueprint_story_id: story.id,
    };

    const { data, error } = await supabase
      .from("blueprint_characters")
      .insert(characterData)
      .select()
      .limit(1)
      .single();

    if (error || voiceError) {
      setErrorMessage(`Error creating character: ${error?.message || voiceError}`);
      return null;
    }

    return data as Tables<"blueprint_characters">;
  }

  const createTransition = async (fromMomentId: string, toMomentId: string) => {
    const transitionData: TablesInsert<"blueprint_moment_transitions"> = {
      current_moment_id: fromMomentId,
      next_moment_id: toMomentId,
      blueprint_story_id: story.id,
      condition: "",
    };

    const { error } = await supabase.from("blueprint_moment_transitions").insert(transitionData);

    if (error) {
      setErrorMessage(`Error creating transition: ${error.message}`);
      return false;
    }
    return true;
  };

  const createMoment = async (
    isStarting: boolean = false,
  ): Promise<Tables<"blueprint_moments"> | null> => {
    let character = await supabase
      .from("blueprint_characters")
      .select("*")
      .eq("blueprint_story_id", story.id)
      .limit(1)
      .single()
      .then(({ data }) => data);

    if (!character) {
      character = await createCharacter();
    }

    if (!character) return null;

    const momentData = {
      blueprint_story_id: story.id,
      moment_name: `New Moment`,
      is_starting_moment: isStarting,
      text_rgba_color: story?.text_rgba_color,
      background_rgba_color: story?.background_rgba_color,
      control_rgba_color: story?.control_rgba_color,
      blueprint_character_id: character.id,
    };

    const { data, error } = await supabase
      .from("blueprint_moments")
      .insert(momentData)
      .select()
      .single();

    if (error) {
      setErrorMessage(`Error creating moment: ${error.message}`);
      return null;
    }

    return data as Tables<"blueprint_moments">;
  };

  const handleCreateMoment = async () => {
    let newMoment: Tables<"blueprint_moments"> | null = null;
    let secondMoment: Tables<"blueprint_moments"> | null = null;

    if (moments.length === 0) {
      // Create two moments and a transition between them
      newMoment = await createMoment(true);
      if (newMoment) {
        secondMoment = await createMoment();
        if (secondMoment) {
          await createTransition(newMoment.id, secondMoment.id);
        }
      }
    } else if (moments.length === 1) {
      // Create one moment and link it to the existing one
      newMoment = await createMoment();
      if (newMoment) {
        await createTransition(moments[0].id, newMoment.id);
      }
    } else {
      // Create a single moment
      newMoment = await createMoment();

      // If transitionFromMomentId is provided, create a transition
      if (transitionFromMomentId && newMoment) {
        await createTransition(transitionFromMomentId, newMoment.id);
      }
    }

    if (newMoment && onMomentCreated) {
      onMomentCreated(newMoment);
    }
    if (secondMoment && onMomentCreated) {
      onMomentCreated(secondMoment);
    }
  };

  return (
    <div className="space-y-4">
      {error && <p className="text-red-500">{error}</p>}
      <SpokableButton onClick={handleCreateMoment}>
        {moments.length === 0 ? "Create Initial Moments" : "Create Moment"}
      </SpokableButton>
    </div>
  );
};

export default CreateMoment;
