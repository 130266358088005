import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { supabase } from "../../vendor/supabaseClient.ts";
import { Tables } from "../../types/database.ts";
import AutoResizeTextArea from "../../components/admin/AutoResizeTextArea.tsx";
import FormField from "../../components/admin/FormField.tsx";
import { getSupabaseImageUrl } from "../../utils/mediaUtil.ts";
import { Card, CardContent, CardH1 } from "../../components/admin/Card.tsx";
import { Input } from "../../components/catalyst/input.tsx";
import { capitalize } from "../../utils/stringUtil.ts";
import { Avatar } from "../../components/catalyst/avatar.tsx";
import SpokableListBox from "../../components/admin/SpokableListBox.tsx";
import { PAGE_CHARACTER } from "../../constants/constant.ts";
import { GlobalSideNavLayout } from "../../components/admin/GlobalSideNavLayout.tsx";
import MediaGeneratorCharacterModalButton from "../../components/admin/MediaGeneratorCharacterModalButton.tsx";
import { SpokableButton } from "../../components/admin/SpokableButton.tsx";
import CharacterImageCard from "../../components/admin/CharacterImageCard.tsx";
import { getEnvironment } from "../../utils/envUtil.ts";
import VoicePreviewButton from "../../components/admin/VoicePreviewButton.tsx";
import AutoCompleteGenerateButton from "../../components/admin/AutoCompleteGenerateButton.tsx";
import { IMAGE_GENERATION_PROMPT_DOC } from "virtual:doc-comments";
import { prepareObjectForPrompt } from "../../utils/objectUtil.ts";

export default function CharacterDetail() {
  const { characterId } = useParams();
  const [story, setStory] = useState<Tables<"blueprint_stories"> | null>(null);

  const navigate = useNavigate();
  const [character, setCharacter] = useState<Tables<"blueprint_characters"> | null>(null);
  const [characterImages, setCharacterImages] = useState<Tables<"blueprint_character_medias">[]>(
    [],
  );
  const [allCharacterImages, setAllCharacterImages] = useState<
    Tables<"blueprint_character_medias">[]
  >([]);
  const [voices, setVoices] = useState<Tables<"blueprint_voices">[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    fetchCharacter();
    fetchCharacterImages();
    fetchVoices();
  }, [characterId]);

  useEffect(() => {
    fetchStory();
  }, [character]);

  useEffect(() => {
    fetchAllCharacterImages();
  }, [story]);

  const fetchCharacter = async () => {
    if (!characterId) return;

    const { data, error } = await supabase
      .from("blueprint_characters")
      .select("*")
      .eq("id", characterId)
      .single();

    if (error) {
      console.error("Error fetching character:", error);
    } else {
      setCharacter(data);
    }
  };

  async function fetchStory() {
    if (!character) return;
    const { data, error } = await supabase
      .from("blueprint_stories")
      .select("*")
      .eq("id", character?.blueprint_story_id)
      .returns<Tables<"blueprint_stories">[]>()
      .single();

    if (error) {
      setErrorMessage("Error fetching story: " + error.message);
    } else {
      setStory(data);
    }
  }

  const fetchVoices = async () => {
    const { data, error } = await supabase
      .from("blueprint_voices")
      .select("*")
      .eq("environment", getEnvironment())
      .order("created_at", { ascending: true });

    if (error) {
      setErrorMessage("Error fetching voices: " + error.message);
    } else {
      setVoices(data);
    }
  };

  const fetchCharacterImages = async () => {
    if (!characterId) return;

    const { data, error } = await supabase
      .from("blueprint_character_medias")
      .select("*")
      .eq("blueprint_character_id", characterId);

    if (error) {
      console.error("Error fetching character:", error);
    } else {
      setCharacterImages(data);
    }
  };

  const fetchAllCharacterImages = async () => {
    if (!story) return;

    const { data, error } = await supabase
      .from("blueprint_character_medias")
      .select("*")
      .eq("blueprint_story_id", story.id);

    if (error) {
      console.error("Error fetching character:", error);
    } else {
      setAllCharacterImages(data);
    }
  };

  async function updateCharacter() {
    if (!character) return;
    if (characterId == undefined) return;

    const { error } = await supabase
      .from("blueprint_characters")
      .update(character)
      .eq("id", characterId);

    if (error) {
      setErrorMessage("Error updating story: " + error.message);
    } else {
      navigate(-1);
    }
  }

  const handleCancel = () => {
    navigate(-1);
  };

  const getRefImage = (): string | null => {
    if (allCharacterImages.length === 0) {
      if (story?.background_image) {
        return story?.background_image;
      } else {
        return null;
      }
    }
    characterImages.map((image) => {
      if (image.blueprint_character_id === character?.id) {
        return image.media_url;
      }
    });
    return allCharacterImages[Math.floor(Math.random() * characterImages.length)]?.media_url;
  };

  if (!character) {
    return <div>Loading...</div>;
  }

  if (!story) return;
  return (
    <GlobalSideNavLayout activePageId={PAGE_CHARACTER} storyId={story.id}>
      <div className="max-w-2xl mb-20">
        <div className="top-section sticky top-0 backdrop-blur-xl z-50 p-6">
          {errorMessage && (
            <div
              className="rounded bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 mb-4 cursor-pointer"
              onClick={() => setErrorMessage(null)}
            >
              {errorMessage}
            </div>
          )}

          <div className="flex justify-between items-end w-full">
            <div className="flex">
              {characterImages.map((image) => (
                <Avatar
                  key={image.id}
                  className="size-28 ml-6"
                  src={getSupabaseImageUrl(character.blueprint_story_id, image.media_url)}
                />
              ))}
            </div>
            <h1 className="text-5xl font-bold">{capitalize(character.name)}</h1>
            <div className="flex gap-2">
              <SpokableButton onClick={updateCharacter}>Save</SpokableButton>
              <SpokableButton onClick={handleCancel}>Back</SpokableButton>
            </div>
          </div>
        </div>

        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Basic Information" id="basic" />
            <FormField label="Name">
              <Input
                type="text"
                id="name"
                value={character.name || ""}
                onChange={(e) => setCharacter({ ...character, name: e.target.value })}
              />
            </FormField>
            <FormField label="Age">
              <Input
                type="text"
                id="age"
                value={character.age || ""}
                onChange={(e) => setCharacter({ ...character, age: e.target.value })}
              />
            </FormField>
            <FormField label="Role">
              <Input
                type="text"
                id="role"
                value={character.role || ""}
                onChange={(e) => setCharacter({ ...character, role: e.target.value })}
              />
            </FormField>
            <FormField label="Genre">
              <Input
                type="text"
                id="genre"
                value={character.genre || ""}
                onChange={(e) => setCharacter({ ...character, genre: e.target.value })}
              />
            </FormField>
          </CardContent>
        </Card>

        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Visual" id="appearance-voice" />
            <FormField label="Appearance">
              <AutoResizeTextArea
                value={character.appearance || ""}
                onChange={(e) => setCharacter({ ...character, appearance: e })}
              />
            </FormField>
            <FormField label="Image Generation Prompt">
              <div className="flex">
                <AutoResizeTextArea
                  className="flex-grow"
                  value={character.image_generation_prompt || ""}
                  onChange={(e) => setCharacter({ ...character, image_generation_prompt: e })}
                />
                <div className="ml-4">
                  <AutoCompleteGenerateButton
                    onAutoComplete={(e) =>
                      setCharacter({ ...character, image_generation_prompt: e })
                    }
                    basePrompt={IMAGE_GENERATION_PROMPT_DOC}
                    generateFrom={prepareObjectForPrompt(character)}
                  />
                </div>
              </div>
            </FormField>
            {story && (
              <FormField>
                <MediaGeneratorCharacterModalButton
                  story={story}
                  onUploadComplete={() => fetchCharacterImages()}
                  basePrompt={character.image_generation_prompt}
                  promptModifier={story.image_prompt_style}
                  baseFileName={character.name}
                  characterId={character.id}
                  imageReferenceFileName={getRefImage()}
                />
              </FormField>
            )}
            {characterImages && characterImages.length !== 0 && (
              <FormField>
                <ul>
                  {characterImages.map((image) => (
                    <li key={image.id}>
                      <CharacterImageCard
                        storyId={story.id}
                        image={image}
                        onRefreshNeeded={fetchCharacterImages}
                      />
                    </li>
                  ))}
                </ul>
              </FormField>
            )}
          </CardContent>
        </Card>
        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Voice" id="voice" />

            <FormField label="Example Dialogues">
              <AutoResizeTextArea
                value={character.example_dialogues || ""}
                onChange={(e) => setCharacter({ ...character, example_dialogues: e })}
              />
            </FormField>
            <FormField>
              <SpokableListBox
                options={voices.map((voice) => ({
                  key: voice.id,
                  value: `${voice.name} | ${voice.description} | ${voice.tts_system}`,
                }))}
                value={{ key: character.voice_id, value: "" }}
                onChange={(value) =>
                  setCharacter({ ...character, voice_id: value ? value.key : "" })
                }
              />
            </FormField>

            <VoicePreviewButton
              voiceId={character.voice_id}
              dialogue={character.example_dialogues || ""}
              storyId={story.id}
              onSoundSelected={(voiceId) => setCharacter({ ...character, voice_id: voiceId })}
            />
          </CardContent>
        </Card>

        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Character Traits" id="character-traits" />
            <FormField label="Personality Traits">
              <AutoResizeTextArea
                value={character.personality_traits || ""}
                onChange={(e) => setCharacter({ ...character, personality_traits: e })}
              />
            </FormField>
            <FormField label="Abilities">
              <AutoResizeTextArea
                value={character.abilities || ""}
                onChange={(e) => setCharacter({ ...character, abilities: e })}
              />
            </FormField>
            <FormField label="Goals">
              <AutoResizeTextArea
                value={character.goals || ""}
                onChange={(e) => setCharacter({ ...character, goals: e })}
              />
            </FormField>
            <FormField label="Values and Beliefs">
              <AutoResizeTextArea
                value={character.values_and_beliefs || ""}
                onChange={(e) => setCharacter({ ...character, values_and_beliefs: e })}
              />
            </FormField>
          </CardContent>
        </Card>

        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Background and Relationships" id="background-relationships" />
            <FormField label="Background">
              <AutoResizeTextArea
                value={character.background || ""}
                onChange={(e) => setCharacter({ ...character, background: e })}
              />
            </FormField>
            <FormField label="Relationships">
              <AutoResizeTextArea
                value={character.relationships || ""}
                onChange={(e) => setCharacter({ ...character, relationships: e })}
              />
            </FormField>
            <FormField label="Secrets">
              <AutoResizeTextArea
                value={character.secrets || ""}
                onChange={(e) => setCharacter({ ...character, secrets: e })}
              />
            </FormField>
          </CardContent>
        </Card>

        <Card isFullWidth={true}>
          <CardContent>
            <CardH1 label="Character Development" id="character-development" />
            <FormField label="Character Arc">
              <AutoResizeTextArea
                value={character.character_arc || ""}
                onChange={(e) => setCharacter({ ...character, character_arc: e })}
              />
            </FormField>
            <FormField label="Internal Conflict">
              <AutoResizeTextArea
                value={character.internal_conflict || ""}
                onChange={(e) => setCharacter({ ...character, internal_conflict: e })}
              />
            </FormField>
            <FormField label="External Conflict">
              <AutoResizeTextArea
                value={character.external_conflict || ""}
                onChange={(e) => setCharacter({ ...character, external_conflict: e })}
              />
            </FormField>
            <FormField label="Symbolism">
              <AutoResizeTextArea
                value={character.symbolism || ""}
                onChange={(e) => setCharacter({ ...character, symbolism: e })}
              />
            </FormField>
          </CardContent>
        </Card>
      </div>
    </GlobalSideNavLayout>
  );
}
