import React, { useCallback, useMemo, useState } from "react";
import {
  closestCenter,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import {
  ConceptItem,
  LearningPathElement,
  SyllabusOutput,
} from "@/types/learning_concept_prompts_generated_types";

// Hook for managing subject editing
export const useSyllabusSubject = (
  initialSubject: string,
  onUpdate: (newSubject: string) => void,
) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempSubject, setTempSubject] = useState(initialSubject);

  const handleStartEdit = useCallback(() => {
    setIsEditing(true);
    setTempSubject(initialSubject);
  }, [initialSubject]);

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    setTempSubject(initialSubject);
  }, [initialSubject]);

  const handleSave = useCallback(() => {
    onUpdate(tempSubject);
    setIsEditing(false);
  }, [tempSubject, onUpdate]);

  return {
    isEditing,
    tempSubject,
    setTempSubject,
    handleStartEdit,
    handleCancel,
    handleSave,
  };
};

// Hook for managing sortable items with DnD
export const useSortableItems = () => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return {
    sensors,
    collisionDetection: closestCenter,
  };
};

// Hook for managing grouped items by concept
export const useGroupedItems = (items: ConceptItem[]) => {
  return useMemo(() => {
    return items.reduce(
      (acc, item) => {
        if (!acc[item.concept_id]) {
          acc[item.concept_id] = [];
        }
        acc[item.concept_id].push(item);
        return acc;
      },
      {} as Record<string, ConceptItem[]>,
    );
  }, [items]);
};

// Hook for managing edit states
export const useEditState = () => {
  const [editingConceptId, setEditingConceptId] = useState<string | null>(null);
  const [editingItemId, setEditingItemId] = useState<string | null>(null);

  return {
    editingConceptId,
    setEditingConceptId,
    editingItemId,
    setEditingItemId,
  };
};

// Hook for managing syllabus updates
export const useSyllabusUpdates = (
  syllabus: SyllabusOutput,
  setSyllabus: React.Dispatch<React.SetStateAction<SyllabusOutput | null>>,
) => {
  const handleUpdateConcept = useCallback(
    (conceptId: string, updates: Partial<LearningPathElement>) => {
      setSyllabus({
        ...syllabus,
        learning_path: syllabus.learning_path.map((c) =>
          c.concept_id === conceptId ? { ...c, ...updates } : c,
        ),
      });
    },
    [syllabus, setSyllabus],
  );

  const handleReorderItem = useCallback(
    (conceptId: string, currentOrder: number, direction: "up" | "down") => {
      setSyllabus((prev: SyllabusOutput | null): SyllabusOutput | null => {
        if (!prev) return null;

        // Get items for this concept and sort them by current order
        const conceptItems = prev.items
          .filter((item: ConceptItem) => item.concept_id === conceptId)
          .sort((a, b) => a.order - b.order);

        const otherItems = prev.items.filter((item: ConceptItem) => item.concept_id !== conceptId);

        const currentIndex = conceptItems.findIndex((item) => item.order === currentOrder);
        const newIndex = direction === "up" ? currentIndex - 1 : currentIndex + 1;

        // Check bounds
        if (newIndex < 0 || newIndex >= conceptItems.length) {
          return prev;
        }

        // Reorder the array
        const reorderedItems = arrayMove(conceptItems, currentIndex, newIndex);

        // Reassign all orders based on new positions
        const updatedItems = reorderedItems.map((item, idx) => ({
          ...item,
          order: idx + 1,
        }));

        return {
          ...prev,
          items: [...otherItems, ...updatedItems],
        };
      });
    },
    [setSyllabus],
  );

  const handleAddConcept = useCallback(() => {
    const newConcept: LearningPathElement = {
      concept_id: `concept_${Date.now()}`,
      concept_name: "New Concept",
      learning_summary: "New summary",
      prerequisites: [],
      learning_outcomes: [],
      practical_exercises: [],
    };
    setSyllabus({
      ...syllabus,
      learning_path: [...syllabus.learning_path, newConcept],
    });
  }, [syllabus, setSyllabus]);

  const handleDeleteConcept = useCallback(
    (conceptId: string) => {
      setSyllabus({
        ...syllabus,
        learning_path: syllabus.learning_path.filter((c) => c.concept_id !== conceptId),
        items: syllabus.items.filter((item) => item.concept_id !== conceptId),
      });
    },
    [syllabus, setSyllabus],
  );

  const handleAddItem = useCallback(
    (conceptId: string) => {
      const conceptItems = syllabus.items.filter((item) => item.concept_id === conceptId);
      const newItem: ConceptItem = {
        concept_id: conceptId,
        sub_concept_id: `${Date.now()}`,
        name: "New Item",
        order: (conceptItems.length || 0) + 1,
        noun: "New",
        verb: "Create",
        adjective: "Basic",
        prerequisites: [],
      };
      setSyllabus({
        ...syllabus,
        items: [...syllabus.items, newItem],
      });
    },
    [syllabus, setSyllabus],
  );

  const handleUpdateItem = useCallback(
    (itemId: string, updates: Partial<ConceptItem>) => {
      setSyllabus({
        ...syllabus,
        items: syllabus.items.map((item) =>
          `${item.concept_id}-${item.sub_concept_id}` === itemId ? { ...item, ...updates } : item,
        ),
      });
    },
    [syllabus, setSyllabus],
  );

  const handleDeleteItem = useCallback(
    (itemId: string) => {
      setSyllabus({
        ...syllabus,
        items: syllabus.items.filter(
          (item) => `${item.concept_id}-${item.sub_concept_id}` !== itemId,
        ),
      });
    },
    [syllabus, setSyllabus],
  );

  const handleConceptDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (!over || active.id === over.id) return;

      setSyllabus((prev: SyllabusOutput | null): SyllabusOutput | null => {
        if (!prev) return null;

        const oldIndex = prev.learning_path.findIndex((item) => item.concept_id === active.id);
        const newIndex = prev.learning_path.findIndex((item) => item.concept_id === over.id);

        return {
          ...prev,
          learning_path: arrayMove(prev.learning_path, oldIndex, newIndex),
        };
      });
    },
    [setSyllabus],
  );

  const handleItemDragEnd = useCallback(
    (conceptId: string, event: DragEndEvent) => {
      const { active, over } = event;
      if (!over || active.id === over.id) return;

      setSyllabus((prev: SyllabusOutput | null): SyllabusOutput | null => {
        if (!prev) return null;

        const conceptItems = prev.items.filter(
          (item: ConceptItem) => item.concept_id === conceptId,
        );
        const otherItems = prev.items.filter((item: ConceptItem) => item.concept_id !== conceptId);

        const oldIndex = conceptItems.findIndex(
          (item: ConceptItem) => `${item.concept_id}-${item.sub_concept_id}` === active.id,
        );
        const newIndex = conceptItems.findIndex(
          (item: ConceptItem) => `${item.concept_id}-${item.sub_concept_id}` === over.id,
        );

        const reorderedItems = arrayMove(conceptItems, oldIndex, newIndex).map(
          (item: ConceptItem, idx: number): ConceptItem => ({
            ...item,
            order: idx + 1,
          }),
        );

        return {
          ...prev,
          items: [...otherItems, ...reorderedItems],
        };
      });
    },
    [setSyllabus],
  );

  return {
    handleUpdateConcept,
    handleDeleteConcept,
    handleAddItem,
    handleUpdateItem,
    handleDeleteItem,
    handleConceptDragEnd,
    handleItemDragEnd,
    handleAddConcept,
    handleReorderItem,
  };
};
