import React, { useEffect, useMemo, useState } from "react";
import { IdeaItem } from "./IdeaItem";
import AutoResizeTextArea from "../AutoResizeTextArea.tsx";
import { SpokableButton } from "../SpokableButton.tsx";
import {
  LearningConceptInput,
  LearningConceptOutput,
} from "@/types/learning_concept_prompts_generated_types.ts";
import FormField from "@/components/admin/FormField.tsx";
import SpokableListBox from "@/components/admin/SpokableListBox.tsx";
import { LoadingSpinner } from "@/components/admin/LoadingSpinner.tsx";

const LEARNING_DOMAINS: LearningDomainOption[] = [
  { key: "MATHEMATICS", value: "Mathematics" },
  { key: "LOGIC_AND_REASONING", value: "Logic and Reasoning" },
  { key: "LANGUAGE_LEARNING", value: "Language Learning" },
  { key: "PHILOSOPHY", value: "Philosophy" },
  { key: "HISTORY", value: "History" },
  { key: "ECONOMICS", value: "Economics" },
  { key: "PSYCHOLOGY", value: "Psychology" },
  {
    key: "PROGRAMMING_AND_COMPUTATIONAL_THINKING",
    value: "Programming and Computational Thinking",
  },
  { key: "LITERATURE", value: "Literature" },
  { key: "LAW", value: "Law" },
  { key: "ETHICS", value: "Ethics" },
  { key: "SOCIOLOGY", value: "Sociology" },
  { key: "POLITICAL_SCIENCE", value: "Political Science" },
  { key: "BUSINESS_AND_MANAGEMENT", value: "Business and Management" },
  { key: "ASTRONOMY", value: "Astronomy" },
  { key: "BIOLOGY", value: "Biology" },
  { key: "MUSIC_THEORY", value: "Music Theory" },
  { key: "ENVIRONMENTAL_SCIENCE", value: "Environmental Science" },
  { key: "ANTHROPOLOGY", value: "Anthropology" },
  { key: "COGNITIVE_SCIENCE", value: "Cognitive Science" },
];

type LearningDomainOption = {
  key: string;
  value: string;
};

export interface GenerateLearningConceptProps {
  learningConcepts: LearningConceptOutput | null;
  status: string;
  generateLearningConcepts: (payload: any) => void;
  selectedLearningConcept: string | null;
  setSelectedLearningConcept: React.Dispatch<React.SetStateAction<string | null>>;
  setLearningConcepts: React.Dispatch<React.SetStateAction<LearningConceptOutput | null>>;
  title: string;
}

const GenerateLearningIdeas: React.FC<GenerateLearningConceptProps> = ({
  learningConcepts,
  status,
  generateLearningConcepts,
  selectedLearningConcept,
  setSelectedLearningConcept,
  setLearningConcepts,
  title,
}) => {
  const memoizedLearningConcepts = useMemo(() => learningConcepts, [learningConcepts]);
  const [userInput, setUserInput] = useState("");
  const [learningDomain, setLearningDomain] = useState<LearningDomainOption | null>(null);

  const handleGenerateLearningConcept = () => {
    setLearningConcepts(null);
    const learningConceptInput: LearningConceptInput = {
      user_input: userInput,
      audience: "",
      learning_domain: learningDomain?.value || "",
    };
    generateLearningConcepts(learningConceptInput);
  };

  useEffect(() => {
    if (
      memoizedLearningConcepts &&
      memoizedLearningConcepts.ideas?.length > 0 &&
      !selectedLearningConcept
    ) {
      setSelectedLearningConcept(memoizedLearningConcepts.ideas[0]);
    }
  }, [memoizedLearningConcepts, learningConcepts, setSelectedLearningConcept]);

  return (
    <div>
      <div>
        <h1 className="text-2xl font-bold mb-2">{title}</h1>
        <span className="text-sm  font-sans"> {status}</span>
      </div>
      <div className="mt-2">
        <div className=" w-full">
          <FormField label="Domain">
            <SpokableListBox
              options={LEARNING_DOMAINS}
              value={
                LEARNING_DOMAINS.find((option) => option.value === learningDomain?.value) || {
                  key: "",
                  value: "Select a domain",
                }
              }
              onChange={(selected) => setLearningDomain(selected)}
            />
          </FormField>
          <FormField label="User input">
            <AutoResizeTextArea
              value={userInput}
              onChange={(value) => setUserInput(value)}
              minNumberOfRows={1}
            />
          </FormField>
          <div>
            <SpokableButton onClick={handleGenerateLearningConcept}>Generate</SpokableButton>
            {status !== "" && <LoadingSpinner />}
          </div>
        </div>
        <div className="rounded-lg mt-4 max-h-[calc(90vh-550px)] overflow-y-auto">
          {memoizedLearningConcepts &&
            memoizedLearningConcepts.ideas.map((idea, index) => (
              <IdeaItem
                key={index}
                idea={{ idea: idea }}
                setSelectedIdea={setSelectedLearningConcept}
                selectedIdea={selectedLearningConcept}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default GenerateLearningIdeas;
