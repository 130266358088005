import React from "react";
import { DatabaseTypes } from "@/components/admin/promptManagement/database/databaseOperations";
import { DTOEvalResult } from "@/types/fastApiPromptManagerTypes";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/catalyst/table";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";

interface EvalResultsTableProps {
  evalResults: DTOEvalResult[];
  criterias: DatabaseTypes["Criteria"][];
  promptExecutionLogs: DatabaseTypes["PromptExecutionDetails"][];
  isPromptOverridden: boolean;
}

const EvalResultsTable: React.FC<EvalResultsTableProps> = ({
  evalResults,
  criterias,
  promptExecutionLogs,
  isPromptOverridden,
}) => {
  const resultMatrix = new Map<string, Map<string, DTOEvalResult>>();

  promptExecutionLogs.forEach((log) => {
    if (log.id) {
      resultMatrix.set(log.id, new Map());
    }
  });

  evalResults.forEach((result) => {
    const promptMap = resultMatrix.get(result.prompt_execution_id);
    if (promptMap) {
      promptMap.set(result.criteria_id, result);
    }
  });

  const findResultByLogId = (logId: string | null): DTOEvalResult | undefined => {
    if (!logId) return undefined;
    return evalResults.find((result) => result.prompt_execution_id == logId);
  };

  return (
    <div className="w-full overflow-x-auto">
      <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
        <TableHead>
          <TableRow>
            <TableHeader className="min-w-[200px]">
              <HoverCard>
                <HoverCardTrigger asChild>
                  <span className="block truncate cursor-help">Dataset Input</span>
                </HoverCardTrigger>
                <HoverCardContent className="w-80">
                  <div className="flex justify-between space-x-4">
                    <div className="space-y-1">
                      <h4 className="text-sm font-semibold">Dataset Input</h4>
                      <p className="text-sm text-muted-foreground">
                        Input variables provided to the prompt
                      </p>
                    </div>
                  </div>
                </HoverCardContent>
              </HoverCard>
            </TableHeader>
            {criterias.map((criteria) => (
              <TableHeader key={criteria.id} className="min-w-[150px]">
                <HoverCard>
                  <HoverCardTrigger asChild>
                    <span className="block truncate cursor-help">
                      {criteria.eval_criteria_name}
                    </span>
                  </HoverCardTrigger>
                  <HoverCardContent className="w-80">
                    <div className="flex justify-between space-x-4">
                      <div className="space-y-1">
                        <h4 className="text-sm font-semibold">{criteria.eval_criteria_name}</h4>
                        <p className="text-sm text-muted-foreground whitespace-pre-wrap">
                          {criteria.criteria}
                        </p>
                      </div>
                    </div>
                  </HoverCardContent>
                </HoverCard>
              </TableHeader>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {promptExecutionLogs.map((log) => (
            <TableRow key={log.id}>
              <TableCell className="font-medium">
                <HoverCard>
                  <HoverCardTrigger asChild>
                    <span className="block truncate cursor-help">
                      {log.response ? JSON.stringify(log.prompt_variable_inputs) : "No input data"}
                    </span>
                  </HoverCardTrigger>
                  <HoverCardContent className="w-1/2 min-w-[1200px]">
                    <div className="space-y-1">
                      <h4 className="">Result</h4>
                      {isPromptOverridden ? (
                        <pre className="font-sans bg-zinc-50 p-2 min-w-[1000px] rounded-md overflow-auto max-h-[300px] whitespace-pre-wrap">
                          {log.id && findResultByLogId(log.id)
                            ? JSON.stringify(
                                JSON.parse(findResultByLogId(log.id)?.llm_answer || ""),
                                null,
                                2,
                              )
                            : "No input data"}
                        </pre>
                      ) : (
                        <pre className="font-sans bg-zinc-50 p-2 min-w-[1000px] rounded-md overflow-auto max-h-[300px] whitespace-pre-wrap">
                          {log.response
                            ? JSON.stringify(JSON.parse(log.response), null, 2)
                            : "No input data"}
                        </pre>
                      )}
                    </div>
                  </HoverCardContent>
                </HoverCard>
              </TableCell>
              {criterias.map((criteria) => {
                const result = log.id ? resultMatrix.get(log.id)?.get(criteria.id) : undefined;
                return (
                  <TableCell key={criteria.id}>
                    {result ? (
                      <HoverCard>
                        <HoverCardTrigger asChild>
                          <div className="space-y-1 cursor-help">
                            <div className="flex items-center justify-between">
                              <span className="font-medium truncate">
                                Score: {result.eval_result.score}
                              </span>
                              <span
                                className={`
                                  px-2 py-1 rounded-full text-xs
                                  ${
                                    result.eval_result.score > 3
                                      ? "bg-green-100 text-green-800"
                                      : "bg-red-100 text-red-800"
                                  }
                                `}
                              >
                                {result.eval_result.score > 3 ? "Pass" : "Fail"}
                              </span>
                            </div>
                          </div>
                        </HoverCardTrigger>
                        <HoverCardContent className="w-80">
                          <div className="space-y-1">
                            <h4 className="text-sm font-semibold">Score Rationale</h4>
                            <p className="text-sm text-muted-foreground whitespace-pre-wrap">
                              {result.eval_result.score_rational}
                            </p>
                          </div>
                        </HoverCardContent>
                      </HoverCard>
                    ) : (
                      <span className="text-gray-400">...</span>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default EvalResultsTable;
