import React from "react";
import { Tables } from "../../types/database";
import { supabase } from "../../vendor/supabaseClient.ts";
import VisualCard from "./VisualCard.tsx";
import { CHARACTER_VISUALS_LIST, IMAGE_BUCKET_NAME } from "../../constants/constant.ts";

interface CharacterImageCardProps {
  storyId: string;
  image: Tables<"blueprint_character_medias">;
  onRefreshNeeded?: () => void;
  hideDelete?: boolean;
  whiteBackground?: boolean;
}

const CharacterImageCard: React.FC<CharacterImageCardProps> = ({
  storyId,
  image,
  onRefreshNeeded,
  hideDelete = false,
  whiteBackground = false,
}) => {
  async function deleteCharacterImage() {
    if (window.confirm("Are you sure you want to delete this image?")) {
      const { error: deleteError } = await supabase.storage
        .from(IMAGE_BUCKET_NAME)
        .remove([storyId + "/" + image.media_url]);
      console.log(deleteError);

      const { error } = await supabase
        .from("blueprint_character_medias")
        .delete()
        .eq("id", image.id);

      if (error) {
        console.error("Error deleting context:", error);
      } else {
        if (onRefreshNeeded) onRefreshNeeded();
      }
    } else {
      console.log("image deletion cancelled by user.");
    }
  }

  return (
    <VisualCard
      imageUrl={image.media_url}
      storyId={storyId}
      listItems={CHARACTER_VISUALS_LIST}
      onDelete={deleteCharacterImage}
      hideDelete={hideDelete}
      whiteBackground={whiteBackground}
    />
  );
};

export default CharacterImageCard;
