import React, { useEffect, useState } from "react";
import AutoResizeTextArea from "./AutoResizeTextArea.tsx";

interface ImageGenerationStyleSelectorProps {
  promptStyle: string;
  onPromptStyleSelected: (promptStyle: string, model?: string) => void;
}

const ImageGenerationStyleSelector: React.FC<ImageGenerationStyleSelectorProps> = ({
  promptStyle,
  onPromptStyleSelected,
}) => {
  const [selectedPromptStyle, setSelectedPromptStyle] = useState<string>(promptStyle);

  useEffect(() => {
    setSelectedPromptStyle(promptStyle);
  }, [promptStyle]);

  return (
    <div className="w-full">
      <AutoResizeTextArea
        minNumberOfRows={1}
        value={selectedPromptStyle || promptStyle}
        onChange={(e) => {
          setSelectedPromptStyle(e);
          onPromptStyleSelected(e);
        }}
      ></AutoResizeTextArea>
    </div>
  );
};

export default ImageGenerationStyleSelector;
