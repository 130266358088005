import { useParams } from "react-router-dom";
import { PlayIcon } from "@heroicons/react/16/solid";

import PromptManagementSideNavLayout from "../../../components/admin/PromptManagementSideNavLayout.tsx";
import { Card, CardContent } from "@/components/admin/Card";
import EditableTitle from "@/components/admin/promptManagement/EditableTitle.tsx";
import { SpokableButton } from "@/components/admin/SpokableButton";
import { useEvalRunDatabase } from "@/components/admin/promptManagement/database/useEvalRunDatabase";
import PromptDefinitionEditor from "@/components/admin/promptManagement/PromptDefinitionEditor.tsx";
import DatasetModalButton from "@/components/admin/promptManagement/DatasetModalButton.tsx";
import usePromptManagementWebSocket, {
  PromptManagementMessageType,
} from "@/components/admin/promptManagement/service/usePromptManagementWebSocket.ts";
import { useEffect, useState } from "react";
import {
  DTOCriteria,
  DTOErrorMessage,
  DTOEvalResult,
  DTOEvalRunDefinition,
  DTOInputOrOutputSchema,
  DTOPromptDef,
  DTOPromptExecutionLog,
} from "@/types/fastApiPromptManagerTypes.ts";
import { DatabaseTypes } from "@/components/admin/promptManagement/database/databaseOperations.ts";
import EvalResultsTable from "@/components/admin/promptManagement/EvalResultsTable.tsx";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { EvalStatsSummary } from "@/components/admin/promptManagement/EvalStatsSummary.tsx";
import { WebSocketMessage } from "@/hooks/useBaseWebSocket.ts";

export default function EvalRun() {
  const { evalId, promptId } = useParams();
  const {
    promptEval,
    prompt,
    criterias,
    errorMessage,
    handleSaveEval,
    setErrorMessage,
    fetchPromptExecutionLogs,
    promptExecutionLogs,
    latestPromptForRun,
  } = useEvalRunDatabase(evalId, promptId);

  const [isPromptModified, setIsPromptModified] = useState<boolean>(false);
  const [evalResults, setEvalResults] = useState<DTOEvalResult[]>([]);
  const [modifiedPrompt, setModifiedPrompt] = useState<
    DatabaseTypes["LatestPromptVersionForRun"] | null
  >(null);
  const [isStartingEval, setIsStartingEval] = useState<boolean>(false);
  const ws = usePromptManagementWebSocket();

  useEffect(() => {
    ws.onReceive(PromptManagementMessageType.RUN_EVAL, (message: WebSocketMessage) => {
      setIsStartingEval(false);
      const result: DTOEvalResult = message.messageObject;
      setEvalResults((prevResults) => {
        const isDuplicate = prevResults.some(
          (existingResult) =>
            existingResult.criteria_id === result.criteria_id &&
            existingResult.prompt_execution_id === result.prompt_execution_id,
        );

        return isDuplicate ? prevResults : [...prevResults, result];
      });
    });

    ws.onReceive(PromptManagementMessageType.EVAL_ERROR, (message: WebSocketMessage) => {
      setIsStartingEval(false);
      const error: DTOErrorMessage = message.messageObject;
      setErrorMessage(error.message);
    });
  }, [ws]);

  const runEval = async () => {
    if (!latestPromptForRun) return;

    setEvalResults([]);
    setIsStartingEval(true);

    const prompt = isPromptModified && modifiedPrompt ? modifiedPrompt : latestPromptForRun;

    const dtoCriteria: DTOCriteria[] = criterias.map(
      (criteria): DTOCriteria => ({
        criteria_name: criteria.eval_criteria_name,
        criteria_prompt: criteria.criteria,
        criteria_id: criteria.id,
      }),
    );

    const dtoPromptDef: DTOPromptDef = {
      prompt_key: prompt.prompt_name || "",
      input_schema: prompt.input_schema as unknown as DTOInputOrOutputSchema,
      general_instruction: prompt.general_instructions || "",
      output_schema: prompt.output_schema as unknown as DTOInputOrOutputSchema,
    };

    const dtoPromptExecutionLogs: DTOPromptExecutionLog[] = promptExecutionLogs.map(
      (log): DTOPromptExecutionLog => ({
        variable_input: JSON.stringify(log.prompt_variable_inputs),
        llm_answer: log.response || "",
        prompt_execution_id: log.id || "",
      }),
    );

    const runDefinition: DTOEvalRunDefinition = {
      criterias: dtoCriteria,
      prompt_def: dtoPromptDef,
      promptExecutionLogs: dtoPromptExecutionLogs,
      is_prompt_overridden: isPromptModified,
    };

    ws.send(PromptManagementMessageType.RUN_EVAL, runDefinition);
  };

  if (!promptEval || !latestPromptForRun || !promptId || !evalId)
    return (
      <div className="flex items-center justify-center h-32">
        <div className="animate-pulse text-gray-500">Loading...</div>
      </div>
    );

  return (
    <PromptManagementSideNavLayout>
      <div className="mb-20">
        <div className="top-sectio sticky top-0 backdrop-blur-xl z-50 p-6">
          {errorMessage && (
            <div
              className={`rounded px-4 py-3 mb-4 cursor-pointer 
                  bg-gray-100 border border-gray-400 text-gray-700
              `}
              onClick={() => setErrorMessage(null)}
            >
              {errorMessage}
            </div>
          )}
          <EditableTitle
            initialTitle={promptEval.eval_name}
            titleClassName={"text-5xl font-bold font-serif"}
            onSave={(newTitle) => {
              const newPromptEval = {
                ...promptEval,
                eval_name: newTitle,
              };
              handleSaveEval(newPromptEval);
            }}
          />
          <h3 className="text-xl font-bold mb-4 text-zinc-500">{`${prompt?.prompt_name}`}</h3>
          <div className="flex gap-4">
            <SpokableButton onClick={() => runEval()} disabled={isStartingEval}>
              <PlayIcon />
              {isStartingEval ? "Starting..." : "Run"}
            </SpokableButton>
            <DatasetModalButton
              promptId={promptId}
              evalId={evalId}
              onClose={() => fetchPromptExecutionLogs()}
            />
          </div>
        </div>
        <Card isFullWidth={true}>
          <CardContent>
            <div className="flex gap-4 h-[calc(100vh-230px)] w-full">
              <div className="w-2/5 overflow-hidden">
                <div className="h-full overflow-y-auto">
                  <div className="mr-2">
                    <PromptDefinitionEditor
                      latestPromptForRun={latestPromptForRun}
                      onPromptChange={(modifiedPrompt) => setModifiedPrompt(modifiedPrompt)}
                      onModificationStateChange={(isModified) => setIsPromptModified(isModified)}
                    />
                    <SpokableButton onClick={() => runEval()} disabled={isStartingEval}>
                      <PlayIcon />
                      {isStartingEval ? "Starting..." : "Run"}
                    </SpokableButton>
                  </div>
                </div>
              </div>
              <div className="w-3/5 overflow-hidden">
                <div className="h-full overflow-y-auto">
                  <CardContent className="mt-1">
                    <div className="text-gray-500 text-center py-8">
                      {evalResults.length === 0 ? (
                        <p className="text-gray-500">No result available</p>
                      ) : (
                        <>
                          <div className="w-full space-y-4">
                            <Tabs defaultValue="statistics" className="w-full">
                              <TabsList>
                                <TabsTrigger value="statistics">Statistics</TabsTrigger>
                                <TabsTrigger value="details">Detailed Results</TabsTrigger>
                              </TabsList>
                              <TabsContent value="statistics">
                                <div className={"mt-5"}></div>
                                <EvalStatsSummary evalResults={evalResults} criterias={criterias} />
                              </TabsContent>
                              <TabsContent value="details">
                                <EvalResultsTable
                                  evalResults={evalResults}
                                  criterias={criterias}
                                  promptExecutionLogs={promptExecutionLogs}
                                  isPromptOverridden={isPromptModified}
                                />
                              </TabsContent>
                            </Tabs>
                          </div>
                        </>
                      )}
                    </div>
                  </CardContent>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </PromptManagementSideNavLayout>
  );
}
