import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { supabase } from "../../vendor/supabaseClient.ts";
import { Tables } from "../../types/database.ts";
import AutoResizeTextArea from "../../components/admin/AutoResizeTextArea.tsx";
import FormField from "../../components/admin/FormField.tsx";
import { Input } from "../../components/catalyst/input.tsx";
import { SpokableButton } from "../../components/admin/SpokableButton.tsx";
import { Card, CardContent, CardH1 } from "../../components/admin/Card.tsx";
import { GlobalSideNavLayout } from "../../components/admin/GlobalSideNavLayout.tsx";
import { PAGE_CONTEXT } from "../../constants/constant.ts";

export default function ContextDetail() {
  const { storyId, contextId } = useParams();
  const navigate = useNavigate();
  const [context, setContext] = useState<Tables<"blueprint_context_blocks"> | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    fetchContext();
  }, [contextId]);

  const fetchContext = async () => {
    if (!contextId) return;

    const { data, error } = await supabase
      .from("blueprint_context_blocks")
      .select("*")
      .eq("id", contextId)
      .single();

    if (error) {
      console.error("Error fetching context:", error);
    } else {
      setContext(data);
    }
  };

  async function updateContextBlock() {
    if (!context) return;
    if (contextId == undefined) return;

    const { error } = await supabase
      .from("blueprint_context_blocks")
      .update(context)
      .eq("id", contextId);

    if (error) {
      setErrorMessage("Error updating context: " + error.message);
    } else {
      navigate(-1);
    }
  }

  const handleCancel = () => {
    navigate(-1);
  };

  if (!context) {
    return <div>Loading...</div>;
  }

  return (
    <GlobalSideNavLayout activePageId={PAGE_CONTEXT} storyId={storyId}>
      <Card isFullWidth={true}>
        {errorMessage && (
          <div
            className="rounded bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 mb-4 cursor-pointer"
            onClick={() => setErrorMessage(null)}
          >
            {errorMessage}
          </div>
        )}
        <CardContent>
          <CardH1 label="Characters" id="character" />

          <FormField label="Moment Name">
            <Input
              type="text"
              id="name"
              value={context.context_block_name || ""}
              onChange={(e) => setContext({ ...context, context_block_name: e.target.value })}
            />
          </FormField>
          <FormField label="Context">
            <AutoResizeTextArea
              value={context.context_block_description || ""}
              onChange={(e) => setContext({ ...context, context_block_description: e })}
            ></AutoResizeTextArea>
          </FormField>

          <div className="flex justify-end">
            <SpokableButton onClick={updateContextBlock}>Save</SpokableButton>
            <SpokableButton onClick={handleCancel} className="ml-6" color="light">
              Back
            </SpokableButton>
          </div>
        </CardContent>
      </Card>
    </GlobalSideNavLayout>
  );
}
