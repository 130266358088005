import { useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/catalyst/table.tsx";
import { LOCALE_ENGLISH, PAGE_CHARACTER } from "../../constants/constant.ts";
import { formatDateString } from "../../utils/date.ts";
import { Card, CardContent, CardH1 } from "../../components/admin/Card.tsx";
import { capitalize, truncateText } from "../../utils/stringUtil.ts";
import { supabase } from "../../vendor/supabaseClient.ts";
import { useEffect, useState } from "react";
import { Tables, TablesInsert } from "../../types/database.ts";
import { GlobalSideNavLayout } from "../../components/admin/GlobalSideNavLayout.tsx";
import { SpokableButton } from "../../components/admin/SpokableButton.tsx";
import { getEnvironment } from "../../utils/envUtil.ts";
import { useFetchCharacterImageUrl } from "../../hooks/database/useMoment.ts";
import { Avatar } from "../../components/catalyst/avatar.tsx";

function CharacterList() {
  const { storyId } = useParams<{
    storyId: string;
  }>();
  const navigate = useNavigate();
  const [characters, setCharacters] = useState<Tables<"blueprint_characters">[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [voices, setVoices] = useState<Tables<"blueprint_voices">[]>([]);

  useEffect(() => {
    fetchVoices();
    fetchCharacters();
  }, []);

  const fetchVoices = async () => {
    const { data, error } = await supabase
      .from("blueprint_voices")
      .select("*")
      .eq("environment", getEnvironment())
      .order("created_at", { ascending: true });

    if (error) {
      setErrorMessage("Error fetching voices: " + error.message);
    } else {
      setVoices(data);
    }
  };

  async function fetchCharacters() {
    if (storyId == undefined) return;
    const { data, error } = await supabase
      .from("blueprint_characters")
      .select("*")
      .eq("blueprint_story_id", storyId)
      .order("created_at", { ascending: true });

    if (error) {
      setErrorMessage("Error fetching characters: " + error.message);
    } else {
      setCharacters(data);
    }
  }

  async function createCharacter() {
    if (storyId == undefined) return;

    const characterData: TablesInsert<"blueprint_characters"> = {
      blueprint_story_id: storyId,
      name: "New character",
      voice_id: voices[0].id,
    };

    const { data, error } = await supabase
      .from("blueprint_characters")
      .insert(characterData)
      .select()
      .single();

    if (error) {
      setErrorMessage("Error creating moment: " + error.message);
    } else {
      const createdCharacter = data as Tables<"blueprint_characters">;
      navigate(`/admin/characters/${createdCharacter.id}`);
    }
  }

  async function deleteCharacter(characterId: string) {
    if (window.confirm("Are you sure you want to delete this character?")) {
      const { error } = await supabase.from("blueprint_characters").delete().eq("id", characterId);

      if (error) {
        console.error("Error deleting character:", error);
      } else {
        fetchCharacters();
      }
    } else {
      // User clicked 'Cancel' or closed the confirmation dialog
      console.log("character deletion cancelled by user.");
    }
  }

  const CharacterAvatar = ({ storyId, characterId }: { storyId: string; characterId: string }) => {
    const { imageUrl, error } = useFetchCharacterImageUrl(storyId, characterId);

    return (
      <Avatar
        className={"w-10"}
        src={imageUrl || undefined}
        alt={error ? "Failed to load image" : "Character avatar"}
      />
    );
  };

  return (
    <GlobalSideNavLayout activePageId={PAGE_CHARACTER} storyId={storyId}>
      <Card isFullWidth={true}>
        {errorMessage && (
          <div
            className="rounded bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 mb-4 cursor-pointer"
            onClick={() => setErrorMessage(null)}
          >
            {errorMessage}
          </div>
        )}
        <CardContent>
          <CardH1 label="Characters" id="character" />
          <div className="flex justify-between items-center mt-8 mb-4">
            <SpokableButton onClick={createCharacter}>Create New character</SpokableButton>
          </div>
          <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
            <TableHead>
              <TableRow>
                <TableHeader></TableHeader>
                <TableHeader>Name</TableHeader>
                <TableHeader>Description</TableHeader>
                <TableHeader>Updated at</TableHeader>
                <TableHeader></TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {characters.map((character) => (
                <TableRow key={character.id} href={`/admin/characters/${character.id}`}>
                  <TableCell>
                    <CharacterAvatar storyId={storyId || ""} characterId={character.id} />
                  </TableCell>
                  <TableCell>{capitalize(character.name)}</TableCell>

                  <TableCell>{truncateText(character.role, 100)}</TableCell>
                  <TableCell>
                    {character.updated_at
                      ? formatDateString(character.updated_at, LOCALE_ENGLISH)
                      : ""}
                  </TableCell>
                  <TableCell>
                    <SpokableButton color={"light"} onClick={() => deleteCharacter(character.id)}>
                      Delete
                    </SpokableButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </GlobalSideNavLayout>
  );
}

export default CharacterList;
