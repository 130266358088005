import React, { useEffect, useMemo, useState } from "react";
import { IdeaItem } from "./IdeaItem";
import { LoadingSpinner } from "../LoadingSpinner";
import AutoResizeTextArea from "../AutoResizeTextArea.tsx";
import { SpokableButton } from "../SpokableButton.tsx";
import { RankedIdea, RankedIdeas } from "@/types/ideas_prompts_generated_types.ts";

export interface GenerateIdeasProps {
  ideas: { ranked_ideas: RankedIdea[] } | null;
  status: string;
  generateIdeas: (payload: any) => void;
  selectedIdea: string | null;
  setSelectedIdea: React.Dispatch<React.SetStateAction<string | null>>;
  setIdeas: React.Dispatch<React.SetStateAction<RankedIdeas | null>>;
  audience: string;
  learningConcept: string | null | undefined;
  title: string;
}

const GenerateStoryIdeas: React.FC<GenerateIdeasProps> = ({
  ideas,
  status,
  generateIdeas,
  selectedIdea,
  setSelectedIdea,
  setIdeas,
  audience,
  learningConcept,
  title,
}) => {
  const memoizedIdeas = useMemo(() => ideas, [ideas]);
  const [ideaSeed, setIdeaSeed] = useState(audience + " including elements of " + learningConcept);

  useEffect(() => {
    if (ideas === null && status === "") {
      generateIdeas({ idea: ideaSeed || "" });
    }
  }, [ideas, status]);

  useEffect(() => {
    if (memoizedIdeas && memoizedIdeas?.ranked_ideas?.length > 0 && !selectedIdea) {
      setSelectedIdea(memoizedIdeas.ranked_ideas[0].idea);
    }
  }, [memoizedIdeas, selectedIdea, setSelectedIdea]);

  const handleRegenerate = () => {
    setIdeas(null);
  };

  return (
    <div>
      <div>
        <h1 className="text-2xl font-bold mb-2">{title}</h1>
        <span className="text-sm  font-sans"> {status}</span>
      </div>
      <div className="mt-2">
        {status !== "" && <LoadingSpinner />}
        {memoizedIdeas && (
          <>
            <div className="flex w-full space-x-2">
              <div className="flex-grow">
                <AutoResizeTextArea
                  value={ideaSeed}
                  onChange={(value) => setIdeaSeed(value)}
                  minNumberOfRows={1}
                />
              </div>
              <div>
                <SpokableButton onClick={handleRegenerate}>Generate</SpokableButton>
              </div>
            </div>
            <div className="rounded-lg mt-4 max-h-[calc(90vh-400px)] overflow-y-auto">
              {memoizedIdeas.ranked_ideas.map((idea, index) => (
                <IdeaItem
                  key={index}
                  idea={idea}
                  setSelectedIdea={setSelectedIdea}
                  selectedIdea={selectedIdea}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GenerateStoryIdeas;
