import { DEV_FAST_API_WS_MEDIA } from "@/vendor/config";
import { createWebSocketHook } from "@/hooks/useBaseWebSocket.ts";

export enum MediaMessageType {
  GENERATE_IMAGE = "GENERATE_IMAGE",
  INPAINT_IMAGE = "INPAINT_IMAGE",
  UPSCALE_IMAGE = "UPSCALE_IMAGE",
  IMAGE_ERROR = "IMAGE_ERROR",
  GENERATE_CHARACTER_EMOTION_VIDEO = "GENERATE_CHARACTER_EMOTION_VIDEO",
  GENERATE_MOMENT_VIDEO = "GENERATE_MOMENT_VIDEO",
}

const wsUrl = import.meta.env.VITE_FAST_API_WS_MEDIA || DEV_FAST_API_WS_MEDIA;
const useMediaWebSocket = createWebSocketHook(wsUrl);

export interface MediaWebSocketHook {
  send: <T>(messageType: MediaMessageType, messageObject: T) => void;
  onReceive: (messageType: MediaMessageType, handler: (message: any) => void) => void;
  isConnected: boolean;
}

export default useMediaWebSocket as () => MediaWebSocketHook;
