import ReactSlider from "react-slider";
import {
  CameraIcon,
  ChevronRightIcon,
  FilmIcon,
  ScissorsIcon,
  StopCircleIcon,
} from "@heroicons/react/16/solid";
import { SpokableButton } from "../SpokableButton";
import { useCamera } from "./useCamera";
import { useVideoTrimming } from "./useVideoTrimming";
import { useCustomDrivingVideoUpload } from "./useCustomDrivingVideoUpload.ts";
import { useVideoConversion } from "./useVideoConversion.ts";
import { getCustomEmotionVideoFileName } from "../../../utils/mediaUtil.ts";
import { downloadFile } from "../generateImagesModal/videoUtils.ts";
import { useEffect, useMemo, useState } from "react";
import useMediaGenerationWebsocket, {
  MediaMessageType,
} from "@/components/admin/generateImagesModal/hooks/useMediaGenerationWebsocket.ts";
import {
  DTOCharacterEmotionVideosCall,
  DTOCustomEmotionVideo,
  DTOImageVideoResult,
} from "@/types/fastApiMediaGenerationTypes.ts";
import { WebSocketMessage } from "@/hooks/useBaseWebSocket.ts";
import { DTOErrorMessage } from "@/types/fastApiPromptManagerTypes.ts";
import { fileToBase64 } from "@/utils/imageUtil.ts";

interface VideoCaptureProps {
  storyId: string;
  collectionName: string;
  emotionKey: string;
  onVideoDone: () => void;
  baseImageFile: File;
}

const VideoCapture = ({
  storyId,
  collectionName,
  emotionKey,
  onVideoDone,
  baseImageFile,
}: VideoCaptureProps) => {
  const {
    recording,
    isCameraStarted,
    rawRecordedVideoUrl,
    rawHTMLVideoElementRef,
    startCamera,
    startRecording,
    stopRecording,
    getRecordedBlob,
  } = useCamera();

  const { uploadVideo, state: uploadState } = useCustomDrivingVideoUpload({
    storyId,
    collectionName,
    emotionKey,
  });

  const { convertVideo, state: conversionState } = useVideoConversion();

  const {
    state: trimmingState,
    previewRef,
    handleRangeChange,
  } = useVideoTrimming(rawRecordedVideoUrl);

  const checkedEmotions = [emotionKey];
  const [testVideoUrl, setTestVideoUrl] = useState<string | null>(null);
  const [isCrunchingVideo, setIsCrunchingVideo] = useState<boolean>(false);
  const [isGeneratingEmotionVideo, setIsGeneratingEmotionVideo] = useState<boolean>(false);

  const ws = useMediaGenerationWebsocket();

  useEffect(() => {
    ws.onReceive(MediaMessageType.GENERATE_CHARACTER_EMOTION_VIDEO, (message) => {
      const imageResult: DTOImageVideoResult = message.messageObject;
      console.log(
        "-------",
        imageResult.status,
        imageResult.are_all_videos_completed,
        imageResult.output,
        isGeneratingEmotionVideo,
      );
      if (
        imageResult.status === "succeeded" &&
        imageResult.are_all_videos_completed &&
        imageResult.output
      ) {
        setTestVideoUrl(imageResult.output.video_url);
        setIsGeneratingEmotionVideo(false);
      } else {
        console.log("Progress...");
      }
    });

    ws.onReceive(MediaMessageType.IMAGE_ERROR, (message: WebSocketMessage) => {
      const error: DTOErrorMessage = message.messageObject;
      console.log(error.message);
      setIsGeneratingEmotionVideo(false);
    });
  }, [ws]);

  const handleProcessAndUpload = async () => {
    if (!previewRef.current || !trimmingState.isVideoReady || !trimmingState.isValidDuration)
      return;

    setIsCrunchingVideo(true);
    const startFrame = trimmingState.startFrame;
    let endFrame = trimmingState.endFrame;
    if (endFrame <= startFrame) {
      endFrame = previewRef.current.duration;
    }

    try {
      const trimmedBlob = getRecordedBlob();
      if (!trimmedBlob || trimmedBlob.size === 0) {
        console.error("Failed to get trimmed blob");
        setIsCrunchingVideo(false);
        return;
      }
      const convertedUrl = await convertVideo(trimmedBlob, storyId, startFrame, endFrame);

      if (!convertedUrl) {
        console.error("Failed to convert video");
        setIsCrunchingVideo(false);
        return;
      }

      console.log("convertedUrl", convertedUrl);
      const response = await fetch(convertedUrl);
      if (!response.ok) {
        setIsCrunchingVideo(false);
        console.error("Failed to fetch converted video", response);
      }

      const convertedBlob = await response.blob();
      console.log(convertedBlob.size);
      const videoUrl = await uploadVideo(convertedBlob);
      console.log(videoUrl);
      await handleTestDrivingVideo();
      setIsCrunchingVideo(false);
    } catch (error) {
      setIsCrunchingVideo(false);
      console.error("Failed to process and upload video:", error);
    }
  };

  const handleTestDrivingVideo = async () => {
    if (!baseImageFile) return;
    setTestVideoUrl(null);
    setIsGeneratingEmotionVideo(true);
    const fileName = getCustomEmotionVideoFileName(collectionName, emotionKey);

    const customEmotionVideoFile = await downloadFile(storyId + "/" + fileName);
    if (!customEmotionVideoFile) return;

    const customEmotionVideos: DTOCustomEmotionVideo[] = [
      {
        emotion_key: emotionKey,
        custom_emotion_video_base_64: await fileToBase64(customEmotionVideoFile),
      },
    ];
    const baseParams: DTOCharacterEmotionVideosCall = {
      character_image_base_64: await fileToBase64(baseImageFile),
      emotions_to_compute: checkedEmotions,
      custom_emotion_videos: customEmotionVideos,
    };

    ws.send(MediaMessageType.GENERATE_CHARACTER_EMOTION_VIDEO, baseParams);
  };

  const { currentStatus, isProcessing } = useMemo(() => {
    if (conversionState.isConverting) return { isProcessing: true, currentStatus: "Converting..." };
    if (uploadState.isUploading) return { isProcessing: true, currentStatus: "Uploading..." };
    if (isCrunchingVideo) return { isProcessing: true, currentStatus: "Crunching..." };
    if (isGeneratingEmotionVideo) return { isProcessing: true, currentStatus: "Testing..." };
    return { isProcessing: false, currentStatus: null };
  }, [
    conversionState.isConverting,
    uploadState.isUploading,
    isCrunchingVideo,
    isGeneratingEmotionVideo,
  ]);

  return (
    <div className="max-w-6xl mx-auto p-4 overflow-auto">
      <div className="flex items-start justify-between gap-8">
        {/* Step 1: Camera and Recording */}
        <div className="flex-1 space-y-4">
          <h3 className="text-lg font-semibold text-center">1. Record</h3>

          <div className="relative w-48 h-48 rounded-lg">
            <video
              ref={rawHTMLVideoElementRef}
              autoPlay
              muted
              className="w-48 h-48 bg-gray-900 rounded-lg"
            />
          </div>

          <div className="h-1" />
          <div className="flex justify-center gap-4 ">
            {!isCameraStarted ? (
              <SpokableButton
                onClick={() => startCamera()}
                disabled={isProcessing}
                className="flex items-center gap-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-900"
              >
                <CameraIcon className="w-5 h-5" />
                Start Camera
              </SpokableButton>
            ) : (
              <SpokableButton
                onClick={!recording ? startRecording : stopRecording}
                className="flex items-center gap-2 px-4 py-2 bg-black text-white rounded-lg"
              >
                {!recording ? (
                  <>
                    <FilmIcon className="w-5 h-5" />
                    Record
                  </>
                ) : (
                  <>
                    <StopCircleIcon className="w-5 h-5" />
                    Stop
                  </>
                )}
              </SpokableButton>
            )}
          </div>
        </div>

        <ChevronRightIcon className="h-16 text-gray-400 mt-32" />

        {/* Step 2: Trimming */}
        <div className="flex-1 space-y-4">
          <h3 className="text-lg font-semibold text-center">2. Adjust loop</h3>
          {rawRecordedVideoUrl ? (
            <div className="w-48 h-48">
              <video ref={previewRef} controls autoPlay loop className={`w-48 h-48 rounded-lg`} />
              {trimmingState.isVideoReady && (
                <div className="space-y-2">
                  <ReactSlider
                    className="h-6 flex items-center"
                    thumbClassName="w-4 h-4 bg-gray-500 rounded-full cursor-grab focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                    trackClassName="h-2 bg-gray-200 rounded-full"
                    min={0}
                    max={trimmingState.duration}
                    step={0.1}
                    value={[trimmingState.startFrame, trimmingState.endFrame]}
                    onChange={handleRangeChange}
                    minDistance={0.1}
                    pearling
                  />
                  {!trimmingState.isValidDuration ? (
                    <div className="text-sm text-center">
                      <span>Loop should be under 5 seconds</span>
                    </div>
                  ) : (
                    <div className="flex justify-center">
                      <SpokableButton
                        onClick={handleProcessAndUpload}
                        disabled={isCrunchingVideo}
                        className="flex items-center gap-2 px-4 py-2 bg-black text-white rounded-lg disabled:bg-gray-400"
                      >
                        {isProcessing ? (
                          <>
                            <ScissorsIcon className="w-5 h-5 animate-spin" />
                            {currentStatus}
                          </>
                        ) : (
                          <>
                            <ScissorsIcon className="w-5 h-5" />
                            Trim
                          </>
                        )}
                      </SpokableButton>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="w-48 h-48 flex items-center justify-center border-2 border-dashed rounded-lg">
              <p className="text-gray-500">Record a video first</p>
            </div>
          )}
        </div>

        <ChevronRightIcon className="h-16 text-gray-400 mt-32" />

        {/* Step 3: Test and Save */}
        <div className="flex-1 space-y-4">
          <h3 className="text-lg font-semibold text-center">3. Test & Save</h3>
          <div className="space-y-4">
            {testVideoUrl ? (
              <>
                <video
                  controls
                  autoPlay
                  loop
                  className={`w-48 h-48 ${testVideoUrl ? "" : "bg-gray-900"} rounded-lg`}
                  src={testVideoUrl || ""}
                />
                <div className="h-1" />
                <div className="flex justify-center gap-4 ">
                  <SpokableButton onClick={onVideoDone}>Save</SpokableButton>
                </div>
              </>
            ) : (
              <div className="w-48 h-48 flex items-center justify-center border-2 border-dashed rounded-lg">
                <p className="text-gray-500">Result preview</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCapture;
