import { useEffect, useState } from "react";
import { supabase } from "@/vendor/supabaseClient";
import { toast } from "@/hooks/use-toast";
import {
  BlueprintImageModels,
  BlueprintImageModelSettings,
} from "@/components/admin/generateImagesModal/hooks/useImageGenModelsDatabase.tsx";
import { ModelFormValues } from "@/components/admin/generateImagesModal/ImageModelCRUD/ImageModelForm";
import { SettingFormValues } from "@/components/admin/generateImagesModal/ImageModelCRUD/ImageModelSettingsForm";

export function useImageModelDatabase() {
  const [models, setModels] = useState<BlueprintImageModels[]>([]);
  const [settings, setSettings] = useState<BlueprintImageModelSettings[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      // Fetch models
      const { data: modelsData, error: modelsError } = await supabase
        .from("blueprint_image_models")
        .select("*")
        .order("created_at", { ascending: false });

      if (modelsError) throw modelsError;

      // Fetch settings with their options
      const { data: settingsData, error: settingsError } = await supabase
        .from("blueprint_image_model_settings")
        .select(
          `
          *,
          blueprint_image_model_setting_options (
            id,
            label,
            value,
            description
          )
        `,
        )
        .order("created_at", { ascending: false });

      if (settingsError) throw settingsError;

      setModels(modelsData);
      setSettings(settingsData);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch data. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const createOrUpdateModel = async (
    formData: ModelFormValues,
    selectedModel: BlueprintImageModels | null,
  ) => {
    try {
      const now = new Date().toISOString();

      if (selectedModel) {
        // Update existing model
        const { error } = await supabase
          .from("blueprint_image_models")
          .update({
            ...formData,
            updated_at: now,
          })
          .eq("id", selectedModel.id);

        if (error) throw error;

        setModels(
          models.map((model) =>
            model.id === selectedModel.id ? { ...model, ...formData, updated_at: now } : model,
          ),
        );
      } else {
        // Prepare data for new model
        const newModelData = {
          model_name: formData.model_name,
          model_id: formData.model_id,
          version: formData.version,
          prompt_modifier: formData.prompt_modifier || "{prompt}",
          category: formData.category,
        };

        // Insert new model
        const { data: newModel, error } = await supabase
          .from("blueprint_image_models")
          .insert(newModelData)
          .select()
          .single();

        if (error) throw error;
        if (newModel) {
          setModels([newModel, ...models]);
        }
      }

      toast({
        title: "Success",
        description: `Model ${selectedModel ? "updated" : "created"} successfully`,
      });

      return true;
    } catch (error) {
      toast({
        title: "Error",
        description: `Failed to ${selectedModel ? "update" : "create"} model`,
        variant: "destructive",
      });
      return false;
    }
  };

  const createOrUpdateSetting = async (
    formData: SettingFormValues,
    selectedSetting: BlueprintImageModelSettings | null,
    selectedModelIdForSetting: string | null,
  ) => {
    try {
      const now = new Date().toISOString();
      let settingId: string;

      // Extract options from form data to handle separately
      const { options, ...settingData } = formData;

      if (selectedSetting) {
        // Update existing setting
        const updateData = {
          setting_name: settingData.setting_name,
          setting_key: settingData.setting_key,
          setting_type: settingData.setting_type,
          description: settingData.description,
          default_value: settingData.default_value,
          min_value: settingData.min_value,
          max_value: settingData.max_value,
          step_value: settingData.step_value,
          unit: settingData.unit,
          placeholder: settingData.placeholder,
          max_length: settingData.max_length,
          updated_at: now,
        };

        const { error } = await supabase
          .from("blueprint_image_model_settings")
          .update(updateData)
          .eq("id", selectedSetting.id);

        if (error) throw error;
        settingId = selectedSetting.id;

        // Delete existing options if setting type is select
        if (settingData.setting_type === "select") {
          const { error: deleteError } = await supabase
            .from("blueprint_image_model_setting_options")
            .delete()
            .eq("setting_id", settingId);

          if (deleteError) throw deleteError;
        }
      } else {
        // Create new setting
        const newSettingData = {
          image_model_id: selectedModelIdForSetting!,
          setting_name: settingData.setting_name,
          setting_key: settingData.setting_key,
          setting_type: settingData.setting_type,
          description: settingData.description,
          default_value: settingData.default_value,
          min_value: settingData.min_value,
          max_value: settingData.max_value,
          step_value: settingData.step_value,
          unit: settingData.unit,
          placeholder: settingData.placeholder,
          max_length: settingData.max_length,
          created_at: now,
          updated_at: now,
        };

        const { data: newSetting, error } = await supabase
          .from("blueprint_image_model_settings")
          .insert(newSettingData)
          .select()
          .single();

        if (error) throw error;
        if (!newSetting) throw new Error("Failed to create setting");

        settingId = newSetting.id;
      }

      // Handle options for select type settings
      if (settingData.setting_type === "select" && options && options.length > 0) {
        const optionsData = options.map((option) => ({
          setting_id: settingId,
          label: option.label,
          value: option.value,
          description: option.description,
          created_at: now,
        }));

        const { error: optionsError } = await supabase
          .from("blueprint_image_model_setting_options")
          .insert(optionsData);

        if (optionsError) throw optionsError;
      }

      // Fetch updated setting with options
      const { data: updatedSetting, error: fetchError } = await supabase
        .from("blueprint_image_model_settings")
        .select(
          `
        *,
        blueprint_image_model_setting_options (
          id,
          label,
          value,
          description
        )
      `,
        )
        .eq("id", settingId)
        .single();

      if (fetchError) throw fetchError;

      if (updatedSetting) {
        setSettings((prevSettings) =>
          selectedSetting
            ? prevSettings.map((setting) => (setting.id === settingId ? updatedSetting : setting))
            : [updatedSetting, ...prevSettings],
        );
      }

      toast({
        title: "Success",
        description: `Setting ${selectedSetting ? "updated" : "created"} successfully`,
      });
      return true;
    } catch (error) {
      console.error("Error creating/updating setting:", error);
      toast({
        title: "Error",
        description: `Failed to ${selectedSetting ? "update" : "create"} setting`,
        variant: "destructive",
      });
      return false;
    }
  };

  const deleteModel = async (id: string) => {
    try {
      // Delete associated settings first (due to foreign key constraint)
      const { error: settingsError } = await supabase
        .from("blueprint_image_model_settings")
        .delete()
        .eq("image_model_id", id);

      if (settingsError) throw settingsError;

      // Delete the model
      const { error: modelError } = await supabase
        .from("blueprint_image_models")
        .delete()
        .eq("id", id);

      if (modelError) throw modelError;

      setModels(models.filter((model) => model.id !== id));
      setSettings(settings.filter((setting) => setting.image_model_id !== id));

      toast({
        title: "Success",
        description: "Model deleted successfully",
      });

      return true;
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete model",
        variant: "destructive",
      });
      return false;
    }
  };

  const deleteSetting = async (settingId: string) => {
    try {
      const { error } = await supabase
        .from("blueprint_image_model_settings")
        .delete()
        .eq("id", settingId);

      if (error) throw error;

      setSettings(settings.filter((setting) => setting.id !== settingId));

      toast({
        title: "Success",
        description: "Setting deleted successfully",
      });

      return true;
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete setting",
        variant: "destructive",
      });
      return false;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    models,
    settings,
    isLoading,
    fetchData,
    createOrUpdateModel,
    createOrUpdateSetting,
    deleteModel,
    deleteSetting,
  };
}
