import { atom } from "recoil";
import { GameAudioOutput } from "../types/GameAudioOutput.ts";

export const audioDataQueueState = atom<GameAudioOutput[]>({
  key: "audioDataQueueState",
  default: [],
});

export interface BackgroundMusicSettings {
  volume: number;
  loop: boolean;
}

export interface BackgroundMusicData {
  audio_data: ArrayBuffer;
  settings: BackgroundMusicSettings;
}

export const backgroundMusicState = atom<BackgroundMusicData | null>({
  key: "backgroundMusicState",
  default: null,
});

export const isPlayingAudioState = atom<Boolean>({
  key: "isPlayingAudioState",
  default: false,
});

export const isThinkingState = atom<Boolean>({
  key: "isThinkingState",
  default: false,
});

export const audioContextState = atom<AudioContext | null>({
  key: "audioContextState",
  default: null,
});

export const playedMomentsState = atom<string[]>({
  key: "playedMomentsState",
  default: [],
});

export const gainNodeState = atom<GainNode | null>({
  key: "gainNodeState",
  default: null,
});
export const analyserBackgroundMusicNodeState = atom<AnalyserNode | null>({
  key: "analyserBackgroundMusicNodeState",
  default: null,
});
export const analyserDialogueNodeState = atom<AnalyserNode | null>({
  key: "analyserDialogueNodeState",
  default: null,
});

export const sourceNodeState = atom<AudioBufferSourceNode | null>({
  key: "sourceNodeState",
  default: null,
});

export const volumeState = atom({
  key: "volumeState",
  default: 1,
});
