import React, { useEffect, useState } from "react";
import FormField from "../FormField";
import SpokableListBox from "../SpokableListBox.tsx";
import SpokableTagSelector from "../SpokableTagSelector.tsx";
import AutoResizeTextArea from "../AutoResizeTextArea.tsx";

const GENDER = {
  MALE: "Male",
  FEMALE: "Female",
  BOTH: "Both",
} as const;

const AGE_GROUP = {
  CHILD: "Child",
  TEEN: "Teen",
  ADULT: "Adult",
  Mature: "Mature",
} as const;

const REALISM = {
  REALISTIC: "Realistic",
  SURNATURAL: "Surnatural",
} as const;

const ETHNICITY = {
  AFRICAN: "African",
  BLACK: "Black",
  LATINO: "Latino",
  SOUTH_EAST_ASIAN: "South East Asian",
  CHINESE: "Chinese",
  INDIAN: "Indian",
  MIDDLE_EASTERN: "Middle Eastern",
  AMERICAN: "American",
  LGBT: "LGBT",
  EUROPEAN: "European",
  WORLD: "World",
} as const;

const GENRE = {
  SCIENCE_FICTION: "Science Fiction",
  FANTASY: "Fantasy",
  HORROR: "Horror",
  MYSTERY: "Mystery",
  ROMANCE: "Romance",
  HISTORICAL: "Historical",
  ADVENTURE: "Adventure",
  ESPIONAGE: "Espionage",
  POLITICAL: "Political",
  WESTERN: "Western",
  SUPERNATURAL: "Supernatural",
  MILITARY: "Military",
  SPORTS: "Sports",
  HEIST: "Heist",
  DISASTER: "Disaster",
  SURVIVAL: "Survival",
  EDUCATIONAL: "Educational",
  REAL_LIFE: "Real Life",
} as const;

type Gender = (typeof GENDER)[keyof typeof GENDER];
type AgeGroup = (typeof AGE_GROUP)[keyof typeof AGE_GROUP];
type Realism = (typeof REALISM)[keyof typeof REALISM];
type Ethnicity = (typeof ETHNICITY)[keyof typeof ETHNICITY];

const GENDER_OPTIONS = Object.entries(GENDER).map(([key, value]) => ({ key, value }));
const AGE_GROUP_OPTIONS = Object.entries(AGE_GROUP).map(([key, value]) => ({ key, value }));
const REALISM_OPTIONS = Object.entries(REALISM).map(([key, value]) => ({ key, value }));
const ETHNICITY_OPTIONS = Object.entries(ETHNICITY).map(([key, value]) => ({ key, value }));
const GENRE_OPTIONS = Object.entries(GENRE).map(([key, value]) => ({ key, value }));

interface AudienceState {
  gender: Gender | "";
  ageGroup: AgeGroup | "";
  realism: Realism | "";
  ethnicity: Ethnicity | "";
  genres: string[];
  additionalInfo: string;
}

export interface AudienceBuilderProps {
  setAudience: React.Dispatch<React.SetStateAction<string>>;
  title: string;
}

const AudienceBuilder: React.FC<AudienceBuilderProps> = ({ setAudience, title }) => {
  const [audienceObject, setAudienceObject] = useState<AudienceState>({
    gender: "",
    ageGroup: "",
    realism: "",
    ethnicity: "",
    genres: [],
    additionalInfo: "",
  });

  useEffect(() => {
    const sentence = buildSentence();
    setAudience(sentence);
  }, [audienceObject]);

  const handleChange = (name: string, value: string | string[]) => {
    setAudienceObject((prev) => ({ ...prev, [name]: value }));
  };

  const buildSentence = (): string => {
    const elements: string[] = ["A story targeting"];

    const demographics: string[] = [];
    if (audienceObject.ageGroup) {
      demographics.push(audienceObject.ageGroup.toLowerCase());
    }
    if (audienceObject.gender && audienceObject.gender !== GENDER.BOTH) {
      demographics.push(audienceObject.gender.toLowerCase());
    }
    if (audienceObject.ethnicity && audienceObject.ethnicity !== ETHNICITY.WORLD) {
      demographics.push(audienceObject.ethnicity);
    }

    if (demographics.length > 0) {
      elements.push(demographics.join(" ") + " audiences");
    } else {
      elements.push("general audiences");
    }

    if (audienceObject.realism) {
      elements.push(
        `interested in ${audienceObject.realism === REALISM.REALISTIC ? "strictly realistic" : "supernatural"} settings`,
      );
    }

    if (audienceObject.genres.length > 0) {
      const genreDescription =
        audienceObject.genres.length === 1
          ? `in the ${audienceObject.genres[0].toLowerCase()} genre`
          : `with a mix of ${audienceObject.genres.slice(0, -1).join(", ").toLowerCase()} and ${audienceObject.genres.slice(-1)[0].toLowerCase()} genres`;
      elements.push(genreDescription);
    }

    let sentence = elements.join(" ");

    if (audienceObject.additionalInfo) {
      sentence += `. ${audienceObject.additionalInfo}`;
    }

    sentence = sentence.trim() + ".";

    return sentence;
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">{title}</h1>
      <div className="mb-6">
        <p className="p-4 bg-gray-100 rounded">{buildSentence()}</p>
      </div>
      <div>
        <FormField label="Genres">
          <SpokableTagSelector
            tags={GENRE_OPTIONS}
            value={audienceObject.genres}
            onChangeStringArray={(selectedTags) => handleChange("genres", selectedTags)}
          />
        </FormField>
        <div className="grid grid-cols-4 gap-4">
          <FormField label="Age Group">
            <SpokableListBox
              options={AGE_GROUP_OPTIONS}
              value={
                AGE_GROUP_OPTIONS.find((option) => option.value === audienceObject.ageGroup) || {
                  key: "",
                  value: "Select Age Group",
                }
              }
              onChange={(selected) => handleChange("ageGroup", selected.value)}
            />
          </FormField>

          <FormField label="Realism">
            <SpokableListBox
              options={REALISM_OPTIONS}
              value={
                REALISM_OPTIONS.find((option) => option.value === audienceObject.realism) || {
                  key: "",
                  value: "Select Realism",
                }
              }
              onChange={(selected) => handleChange("realism", selected.value)}
            />
          </FormField>
          <FormField label="Gender">
            <SpokableListBox
              options={GENDER_OPTIONS}
              value={
                GENDER_OPTIONS.find((option) => option.value === audienceObject.gender) || {
                  key: "",
                  value: "Select Gender",
                }
              }
              onChange={(selected) => handleChange("gender", selected.value)}
            />
          </FormField>
          <FormField label="Ethnicity">
            <SpokableListBox
              options={ETHNICITY_OPTIONS}
              value={
                ETHNICITY_OPTIONS.find((option) => option.value === audienceObject.ethnicity) || {
                  key: "",
                  value: "Select Ethnicity",
                }
              }
              onChange={(selected) => handleChange("ethnicity", selected.value)}
            />
          </FormField>
        </div>

        <FormField label="Additional Information">
          <AutoResizeTextArea
            value={audienceObject.additionalInfo}
            onChange={(e) => handleChange("additionalInfo", e)}
          />
        </FormField>
      </div>
    </div>
  );
};

export default AudienceBuilder;
