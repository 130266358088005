import React from "react";
import { DatabaseTypes } from "@/components/admin/promptManagement/database/databaseOperations";
import { DTOEvalResult } from "@/types/fastApiPromptManagerTypes";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/catalyst/table";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";

// Types for both components
interface EvalResultsProps {
  evalResults: DTOEvalResult[];
  criterias: DatabaseTypes["Criteria"][];
  promptExecutionLogs: DatabaseTypes["PromptExecutionDetails"][];
}

interface Statistics {
  avg: string;
  median: string;
  min: string;
  max: string;
  passRate: string;
}

export const EvalStatsSummary: React.FC<Omit<EvalResultsProps, "promptExecutionLogs">> = ({
  evalResults,
  criterias,
}) => {
  const getStatsByCriteria = (criteriaId: string): Statistics | null => {
    const scores = evalResults
      .filter((result) => result.criteria_id === criteriaId)
      .map((result) => result.eval_result.score);

    if (scores.length === 0) return null;

    const avg = scores.reduce((a, b) => a + b, 0) / scores.length;
    const sorted = [...scores].sort((a, b) => a - b);
    const median =
      sorted.length % 2 === 0
        ? (sorted[sorted.length / 2 - 1] + sorted[sorted.length / 2]) / 2
        : sorted[Math.floor(sorted.length / 2)];

    return {
      avg: avg.toFixed(2),
      median: median.toFixed(2),
      min: Math.min(...scores).toFixed(2),
      max: Math.max(...scores).toFixed(2),
      passRate: `${((scores.filter((score) => score > 3).length / scores.length) * 100).toFixed(1)}%`,
    };
  };

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Evaluation Statistics by Criteria</CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader>Criteria</TableHeader>
              <TableHeader>Avg Score</TableHeader>
              <TableHeader>Median</TableHeader>
              <TableHeader>Min</TableHeader>
              <TableHeader>Max</TableHeader>
              <TableHeader>Pass Rate</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {criterias.map((criteria) => {
              const stats = getStatsByCriteria(criteria.id);
              if (!stats) return null;

              return (
                <TableRow key={criteria.id}>
                  <TableCell>
                    <HoverCard>
                      <HoverCardTrigger asChild>
                        <span className="block truncate cursor-help">
                          {criteria.eval_criteria_name}
                        </span>
                      </HoverCardTrigger>
                      <HoverCardContent className="w-80">
                        <div className="space-y-1">
                          <h4 className="text-sm font-semibold">{criteria.eval_criteria_name}</h4>
                          <p className="text-sm text-muted-foreground">{criteria.criteria}</p>
                        </div>
                      </HoverCardContent>
                    </HoverCard>
                  </TableCell>
                  <TableCell>
                    <span
                      className={`font-medium ${parseFloat(stats.avg) > 3 ? "text-green-600" : "text-red-600"}`}
                    >
                      {stats.avg}
                    </span>
                  </TableCell>
                  <TableCell>{stats.median}</TableCell>
                  <TableCell>{stats.min}</TableCell>
                  <TableCell>{stats.max}</TableCell>
                  <TableCell>
                    <span
                      className={`px-2 py-1 rounded-full text-xs ${
                        parseFloat(stats.passRate) >= 70
                          ? "bg-green-100 text-green-800"
                          : "bg-red-100 text-red-800"
                      }`}
                    >
                      {stats.passRate}
                    </span>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
