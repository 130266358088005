export const getImageAsBase64 = async (imageUrl: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const base64 = canvas.toDataURL("image/png").split(",")[1];
        resolve(base64);
      } else {
        reject(new Error("Failed to get canvas context"));
      }
    };
    img.onerror = () => reject(new Error("Failed to load image"));
    img.src = imageUrl;
  });
};

export const convertCanvasToBase64 = (
  offscreenCanvas: HTMLCanvasElement | null,
  width: number,
  height: number,
): Promise<string> => {
  return new Promise((resolve) => {
    if (!offscreenCanvas) {
      resolve("");
      return;
    }

    const tempCanvas = document.createElement("canvas");
    tempCanvas.width = width;
    tempCanvas.height = height;
    const tempCtx = tempCanvas.getContext("2d");

    if (tempCtx) {
      tempCtx.fillStyle = "black";
      tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
      tempCtx.drawImage(offscreenCanvas, 0, 0);

      const imageData = tempCtx.getImageData(0, 0, tempCanvas.width, tempCanvas.height);
      const data = imageData.data;

      for (let i = 0; i < data.length; i += 4) {
        const brightness = (data[i] + data[i + 1] + data[i + 2]) / 3;
        const color = brightness > 128 ? 255 : 0;
        data[i] = data[i + 1] = data[i + 2] = color;
        data[i + 3] = 255;
      }

      tempCtx.putImageData(imageData, 0, 0);
      const base64 = tempCanvas.toDataURL("image/png").split(",")[1];
      resolve(base64);
    } else {
      resolve("");
    }
  });
};

export async function fileToBase64(file: File): Promise<string> {
  return await new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64 = reader.result?.toString().split(",")[1];
      if (base64) resolve(base64);
      else reject(new Error("Failed to convert file to base64"));
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}
