import { supabasePromptManagement } from "@/vendor/supabaseClient.ts";
import { Tables, TablesInsert } from "@/types/promptManagementDatabase.ts";

type PromptSchema = {
  schema: "prompt_management";
};

export interface DatabaseTypes {
  Prompt: Tables<PromptSchema, "prompts">;
  PromptWithExecutions: Tables<PromptSchema, "prompts_with_executions_view">;
  Eval: Tables<PromptSchema, "evals">;
  InsertEval: TablesInsert<PromptSchema, "evals">;
  Criteria: Tables<PromptSchema, "eval_criterias">;
  InsertCriteria: TablesInsert<PromptSchema, "eval_criterias">;
  PromptExecutionDetails: Tables<PromptSchema, "prompt_execution_details_view">;
  LatestPromptVersionForRun: Tables<PromptSchema, "latest_prompt_version_for_run_view">;
  EvalDataset: Tables<PromptSchema, "eval_datasets">;
  InsertEvalDataset: TablesInsert<PromptSchema, "eval_datasets">;
}

export interface SearchFilters {
  searchTerm?: string | null;
  dateRange?: {
    startDate: string | null;
    endDate: string | null;
  } | null;
  version?: string | null;
  evalStatus?: "all" | "true" | "false" | null;
  ids?: string[] | null;
}

export const databaseOperations = {
  async fetchPromptWithExecutions() {
    return supabasePromptManagement
      .from("prompts_with_executions_view")
      .select("*")
      .order("created_at", { ascending: true });
  },

  async fetchPrompt(promptId: string) {
    return supabasePromptManagement
      .from("prompts")
      .select("*")
      .eq("id", promptId)
      .limit(1)
      .single();
  },

  async fetchEvals(promptId: string) {
    return supabasePromptManagement
      .from("evals")
      .select("*")
      .eq("prompt_id", promptId)
      .order("updated_at", { ascending: true });
  },

  async fetchEvalDatasets(promptId: string, evalId: string) {
    return supabasePromptManagement
      .from("eval_datasets")
      .select("*")
      .eq("eval_id", evalId)
      .eq("prompt_id", promptId);
  },

  async createEval(promptId: string) {
    return supabasePromptManagement
      .from("evals")
      .insert({ eval_name: "New eval", prompt_id: promptId })
      .select()
      .single();
  },

  async fetchEvalCriterias(evalId: string) {
    return supabasePromptManagement.from("eval_criterias").select("*").eq("eval_id", evalId);
  },

  async fetchLatestPromptVersionForRun(promptId: string) {
    return supabasePromptManagement
      .from("latest_prompt_version_for_run_view")
      .select("*")
      .eq("prompt_id", promptId)
      .limit(1)
      .single();
  },

  async updateEval(evalId: string, evalName: string) {
    return supabasePromptManagement.from("evals").update({ eval_name: evalName }).eq("id", evalId);
  },

  async updateCriteria(
    criteriaId: string,
    updates: Pick<DatabaseTypes["Criteria"], "eval_criteria_name" | "criteria">,
  ) {
    return supabasePromptManagement.from("eval_criterias").update(updates).eq("id", criteriaId);
  },

  async createCriteria(evalId: string) {
    return supabasePromptManagement.from("eval_criterias").insert({
      eval_id: evalId,
      eval_criteria_name: "New criteria",
      criteria: "Score the response",
    });
  },

  async fetchEvalById(evalId: string) {
    return supabasePromptManagement.from("evals").select("*").eq("id", evalId).single();
  },

  async fetchPromptExecutions(promptId: string, filters?: SearchFilters) {
    let query = supabasePromptManagement
      .from("prompt_execution_details_view")
      .select("*")
      .eq("prompt_id", promptId)
      .order("created_at", { ascending: false });

    if (filters) {
      if (filters.searchTerm) {
        query = query.ilike("response", `%${filters.searchTerm}%`);
      }

      if (filters.version) {
        query = query.eq("version", filters.version);
      }

      if (filters.dateRange?.startDate) {
        query = query.gte("created_at", filters.dateRange.startDate);
      }

      if (filters.evalStatus && filters.evalStatus != "all") {
        query.eq("used_for_eval", filters.evalStatus == "true");
      }

      if (filters.dateRange?.endDate) {
        const endDate = new Date(filters.dateRange.endDate);
        endDate.setHours(23, 59, 59, 999);
        query = query.lte("created_at", endDate.toISOString());
      }

      if (filters.ids) {
        query.in("id", filters.ids);
      }
    }

    return query.limit(50);
  },

  async deleteEvalDataset(executionId: string, promptId: string, evalId: string) {
    return supabasePromptManagement
      .from("eval_datasets")
      .delete()
      .eq("prompt_execution_id", executionId)
      .eq("prompt_id", promptId)
      .eq("eval_id", evalId);
  },

  async deleteEvalCriteria(evalCriteriaId: string) {
    return supabasePromptManagement.from("eval_criterias").delete().eq("id", evalCriteriaId);
  },

  async deleteEval(evalId: string) {
    return supabasePromptManagement.from("evals").delete().eq("id", evalId);
  },

  async createEvalDataset(executionId: string, promptId: string, evalId: string) {
    return supabasePromptManagement
      .from("eval_datasets")
      .insert({ prompt_execution_id: executionId, prompt_id: promptId, eval_id: evalId });
  },

  async updateUsedForEvalInPromptExecution(executionId: string, usedForEval: boolean) {
    // on purpose narrow update as we want logs to be mostly readonly
    return supabasePromptManagement
      .from("prompt_executions")
      .update({ used_for_eval: usedForEval })
      .eq("id", executionId);
  },
};

export const handleDatabaseError = (
  error: Error,
  setErrorMessage: (msg: string | null) => void,
) => {
  setErrorMessage("Error: " + error.message);
  console.error("Database error:", error);
};

export const showTemporaryMessage = (
  message: string,
  setErrorMessage: (msg: string | null) => void,
  duration = 3000,
) => {
  setErrorMessage(message);
  setTimeout(() => setErrorMessage(null), duration);
};
