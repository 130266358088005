import React, { useState } from "react";
import { Moment, Moments } from "../../../types/fastApiTypes";
import { supabase } from "../../../vendor/supabaseClient.ts";
import { Tables, TablesInsert } from "../../../types/database.ts";
import { DialogTitle } from "../../catalyst/dialog.tsx";
import { SpokableButton } from "../SpokableButton.tsx";

export interface SaveAllProps {
  story: Tables<"blueprint_stories"> | null;
  moments: Moments | null;
  scene: Tables<"blueprint_scenes"> | null;
  handleClose: () => void;
}

const SaveAll: React.FC<SaveAllProps> = ({ story, moments, scene, handleClose }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const saveAll = async () => {
    if (!moments || !story) {
      setErrorMessage("Missing moments or story data");
      return;
    }

    setIsProcessing(true);
    setErrorMessage(null);

    try {
      const momentMap = new Map<string, Moment>();
      const savedMoments = await createMomentsSequentially(moments.moments, momentMap);

      if (savedMoments.length > 1) {
        await createTransitions(savedMoments, momentMap);
      }

      handleClose();
    } catch (error) {
      setErrorMessage(
        `Error saving moments: ${error instanceof Error ? error.message : String(error)}`,
      );
    } finally {
      setIsProcessing(false);
    }
  };

  async function createMomentsSequentially(
    momentsList: Moment[],
    momentMap: Map<string, Moment>,
  ): Promise<Tables<"blueprint_moments">[]> {
    let parentMomentId: string | null = null;
    const savedMoments: Tables<"blueprint_moments">[] = [];

    for (const [index, moment] of momentsList.entries()) {
      const savedMoment = await createMoment(moment, parentMomentId, momentMap);
      if (savedMoment) {
        savedMoments.push(savedMoment);
        if (index === 0) {
          parentMomentId = savedMoment.id;
        }
      }
    }

    return savedMoments;
  }

  async function createMoment(
    moment: Moment,
    parentMomentId: string | null,
    momentMap: Map<string, Moment>,
  ): Promise<Tables<"blueprint_moments"> | null> {
    if (!story) return null;
    if (!scene) return null;

    const momentsData: TablesInsert<"blueprint_moments"> = {
      blueprint_character_id: moment.blueprint_character_id,
      blueprint_story_id: story.id,
      moment_name: moment.moment_name,
      moment_objectives: moment.moment_objectives,
      moment_setting: scene.scene_location + " " + scene.scene_summary,
      moment_traps: moment.moment_traps,
      moment_type: moment.moment_type,
      parent_moment_id: parentMomentId,
      scene_id: scene.id,
      starting_dialogue: moment.starting_dialogue,
      image_generation_prompt: moment.image_generation_prompt,
      video_generation_prompt: moment.video_generation_prompt,
      video_generation_event_prompt: moment.video_generation_event_prompt,
      background_sound_prompt: moment.background_sound_prompt,
      opening_sound_prompt: moment.opening_sound_prompt,
      end_sound_prompt: moment.end_sound_prompt,
    };

    const { data, error } = await supabase
      .from("blueprint_moments")
      .insert(momentsData)
      .select()
      .single();

    if (error) {
      throw new Error(`Error inserting moment: ${error.message}`);
    } else {
      setErrorMessage((prev) => `${prev ? prev + "\n" : ""}Saved moment: ${data.moment_name}`);
      momentMap.set(data.id, moment);
      return data;
    }
  }

  async function createTransitions(
    savedMoments: Tables<"blueprint_moments">[],
    momentMap: Map<string, Moment>,
  ): Promise<void> {
    for (let i = 0; i < savedMoments.length - 1; i++) {
      const currentMoment = savedMoments[i];
      const nextMoment = savedMoments[i + 1];
      await createTransition(currentMoment, nextMoment, momentMap);
    }
  }

  async function createTransition(
    currentMoment: Tables<"blueprint_moments">,
    nextMoment: Tables<"blueprint_moments">,
    momentMap: Map<string, Moment>,
  ): Promise<void> {
    if (!story) return;
    const originalCurrentMoment = momentMap.get(currentMoment.id);

    const transitionData: TablesInsert<"blueprint_moment_transitions"> = {
      blueprint_story_id: story.id,
      condition: originalCurrentMoment?.moment_objectives || "",
      current_moment_id: currentMoment.id,
      next_moment_id: nextMoment.id,
    };

    const { error } = await supabase.from("blueprint_moment_transitions").insert(transitionData);

    if (error) {
      throw new Error(`Error creating transition: ${error.message}`);
    } else {
      setErrorMessage(
        (prev) =>
          `${prev ? prev + "\n" : ""}Created transition from ${currentMoment.moment_name} to ${nextMoment.moment_name}`,
      );
    }
  }

  return (
    <>
      <DialogTitle as="h2" className="font-serif py-6 text-4xl font-bold mb-4">
        Complete process
      </DialogTitle>
      <div className="flex flex-col">
        <div className="flex-grow">
          {errorMessage && <div className="mb-4 whitespace-pre-wrap">{errorMessage}</div>}
          <SpokableButton onClick={saveAll} className="mt-6 mb-6" disabled={isProcessing}>
            {isProcessing ? "Saving..." : "Save all"}
          </SpokableButton>
        </div>
      </div>
    </>
  );
};

export default SaveAll;
