import React from "react";
import { Tables } from "../../types/database";
import { supabase } from "../../vendor/supabaseClient.ts";
import VisualCard from "./VisualCard.tsx";
import { MOMENT_VISUALS_LIST } from "../../constants/constant.ts";

interface MomentImageCardProps {
  storyId: string;
  image: Tables<"blueprint_moment_medias">;
  onRefreshNeeded?: () => void;
  hideDelete?: boolean;
  whiteBackground?: boolean;
}

const MomentImageCard: React.FC<MomentImageCardProps> = ({
  storyId,
  image,
  onRefreshNeeded,
  hideDelete = false,
  whiteBackground = false,
}) => {
  async function deleteCharacterImage() {
    if (window.confirm("Are you sure you want to delete this image?")) {
      const { error } = await supabase.from("blueprint_moment_medias").delete().eq("id", image.id);

      if (error) {
        console.error("Error deleting context:", error);
      } else {
        if (onRefreshNeeded) onRefreshNeeded();
      }
    } else {
      console.log("image deletion cancelled by user.");
    }
  }

  return (
    <VisualCard
      imageUrl={image.media_url}
      storyId={storyId}
      listItems={MOMENT_VISUALS_LIST}
      onDelete={deleteCharacterImage}
      hideDelete={hideDelete}
      whiteBackground={whiteBackground}
    />
  );
};

export default MomentImageCard;
