import { useParams } from "react-router-dom";
import { PromptManagementSideNavLayout } from "@/components/admin/PromptManagementSideNavLayout.tsx";
import LogsViewer from "@/components/admin/promptManagement/LogsViewer.tsx";
import React from "react";

export interface PromptLogsProps {}

const PromptLogs: React.FC<PromptLogsProps> = () => {
  const { promptId } = useParams<{ promptId: string }>();

  if (!promptId) return;

  return (
    <PromptManagementSideNavLayout>
      <LogsViewer promptId={promptId} />
    </PromptManagementSideNavLayout>
  );
};

export default PromptLogs;
